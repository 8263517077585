import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { BrowserRouter } from 'react-router-dom';
import jwt from 'jwt-decode';

import './locale/i18n';
import moment from 'moment';
import AuthService from './services/AuthService';

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_BACKEND_API_KEY;

interface Token {
  roles: string[];
  orgShortName: string;
  email: string;
  id: string;
  exp: number;
  iat: number;
}

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = sessionStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  if (token && jwt<Token>(token).exp < moment().unix()) {
    const newToken = await AuthService.refreshToken();
    if (newToken === null) {
      sessionStorage.clear();
      window.location.href = '/login/expired';
      return;
    }
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${newToken}`,
        apiKey: API_KEY,
      },
    };
  }
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      apiKey: API_KEY,
    },
  };
});

const link = createHttpLink({
  uri: API_URL + '/graphql',
  credentials: 'include',
});

export const client = new ApolloClient({
  link: authLink.concat(link),
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ApolloProvider>
);
