import styled from 'styled-components';
import { COLORS } from '../../styles/colors';
import { FONTS } from '../../styles/fonts';
import { WEIGHTS } from '../../styles/weights';

export const StyledInfoCard = styled.div`
  margin: 8px;
  padding: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  h1 {
    color: ${COLORS.brightGreen};
    font-family: ${FONTS.Lora};
    font-weight: ${WEIGHTS.medium};
    font-size: 18px;
    letter-spacing: 0.51px;
  }
  p {
    color: ${COLORS.brightGreen};
    font-family: ${FONTS.Lora};
    font-weight: ${WEIGHTS.medium};
    font-size: 14px;
    letter-spacing: 0.51px;
  }
`;

export const StyledInfoCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
