import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Area = {
  __typename?: 'Area';
  freeTimes: Scalars['Float'];
  name: Scalars['String'];
};

export enum ChristmasStatus {
  Archived = 'Archived',
  Closed = 'Closed',
  Open = 'Open',
  Wait = 'Wait'
}

export type CreateOrderChildInput = {
  /** age of the child */
  age?: InputMaybe<Scalars['Int']>;
  /** description of the child */
  description?: InputMaybe<Scalars['String']>;
  /** name of the child */
  name?: InputMaybe<Scalars['String']>;
  /** a number to help return multiple children always in the same order */
  position?: InputMaybe<Scalars['Int']>;
};

export type CreateOrgAdminInput = {
  /** email address of the user */
  email?: InputMaybe<Scalars['String']>;
  /** Family name of the user */
  family_name?: InputMaybe<Scalars['String']>;
  /** Given (first) name of the user */
  given_name?: InputMaybe<Scalars['String']>;
  /** Phone number of the user */
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type CreateOrganisationInput = {
  /** address of the organisation */
  address?: InputMaybe<Scalars['String']>;
  /** email of the organisation */
  email?: InputMaybe<Scalars['String']>;
  /** description of the organisations frontpage */
  frontpageDescription?: InputMaybe<Scalars['String']>;
  /** subtitle of the organisations frontpage */
  frontpageSubtitle?: InputMaybe<Scalars['String']>;
  /** title of the organisations frontpage */
  frontpageTitle?: InputMaybe<Scalars['String']>;
  /** logo of the organisation */
  logoFile?: InputMaybe<Scalars['String']>;
  /** name of the organisation */
  name?: InputMaybe<Scalars['String']>;
  /** phone number of the organisation */
  phone?: InputMaybe<Scalars['String']>;
  /** short name of the organisation (for URLS) */
  shortName?: InputMaybe<Scalars['String']>;
  /** VAT-number of the organisation */
  vatId?: InputMaybe<Scalars['String']>;
  /** website url of the organisation */
  websiteURL?: InputMaybe<Scalars['String']>;
};

export type CreateSantaInput = {
  /** name of the santas area for internal use */
  areaNameInternal?: InputMaybe<Scalars['String']>;
  /** name of the santas area for public use */
  areaNamePublic?: InputMaybe<Scalars['String']>;
  /** email of the driver */
  driverEmail?: InputMaybe<Scalars['String']>;
  /** name of the driver */
  driverName?: InputMaybe<Scalars['String']>;
  /** phone number of the driver */
  driverPhone?: InputMaybe<Scalars['String']>;
  /** time of santas last orders start time */
  endTime?: InputMaybe<Scalars['String']>;
  /** name of the santa */
  name?: InputMaybe<Scalars['String']>;
  /** value to keep santas organised */
  position?: InputMaybe<Scalars['Float']>;
  /** email of the santa */
  santaEmail?: InputMaybe<Scalars['String']>;
  /** phone number of the santa */
  santaPhone?: InputMaybe<Scalars['String']>;
  /** id of the season the santa belongs to */
  seasonId?: InputMaybe<Scalars['String']>;
  /** will santa be shown on the frontpage */
  showOnFrontpage?: InputMaybe<Scalars['Boolean']>;
  /** start time of santas shift, when the first order can be accepted */
  startTime?: InputMaybe<Scalars['String']>;
  /** id of the santa's user object */
  userId?: InputMaybe<Scalars['String']>;
};

export type CreateSeasonInput = {
  /** christmas status */
  christmasStatus?: InputMaybe<ChristmasStatus>;
  /** duration of a single visit in minutes */
  duration?: InputMaybe<Scalars['Int']>;
  /** time between visits in minutes */
  interval?: InputMaybe<Scalars['Int']>;
  /** time when the last orders can be accepted */
  maxStartTime?: InputMaybe<Scalars['String']>;
  /** time when the first orders can be accepted */
  minStartTime?: InputMaybe<Scalars['String']>;
  /** id of the organisation this season belongs to */
  organisationId?: InputMaybe<Scalars['String']>;
  /** default price for an order */
  price?: InputMaybe<Scalars['Float']>;
  /** year of the season */
  year?: InputMaybe<Scalars['Int']>;
};

export type CreateUserInput = {
  /** email address of the user */
  email?: InputMaybe<Scalars['String']>;
  /** Family name of the user */
  family_name?: InputMaybe<Scalars['String']>;
  /** Given (first) name of the user */
  given_name?: InputMaybe<Scalars['String']>;
  /** id of the organisation the user belongs to */
  organisationId?: InputMaybe<Scalars['String']>;
  /** Phone number of the user */
  phoneNumber?: InputMaybe<Scalars['String']>;
  /** User roles of the user */
  roles?: InputMaybe<Array<UserRole>>;
};

export type EmailEntity = {
  __typename?: 'EmailEntity';
  /** number of times this email has been tried to send */
  attempts?: Maybe<Scalars['Float']>;
  createdAt: Scalars['String'];
  /** additional data to be used in the email in jsonb format */
  extraData?: Maybe<Scalars['String']>;
  /** id of the email */
  id: Scalars['String'];
  /** id of the order */
  orderId?: Maybe<Scalars['String']>;
  /** id of the sender Organisation */
  organisationId: Scalars['String'];
  /** email address of the recipient */
  recipient: Scalars['String'];
  /** id of the season */
  seasonId?: Maybe<Scalars['String']>;
  /** status of the email */
  status: EmailStatus;
  /** type of email, used for finding proper template */
  type?: Maybe<EmailType>;
};

export type EmailInput = {
  /** additional data to be used in the email in jsonb format */
  extraData?: InputMaybe<Scalars['String']>;
  /** id of the order */
  orderId?: InputMaybe<Scalars['String']>;
  /** id of the sender Organisation */
  organisationId?: InputMaybe<Scalars['String']>;
  /** email address of the recipient */
  recipient?: InputMaybe<Scalars['String']>;
  /** id of the season */
  seasonId?: InputMaybe<Scalars['String']>;
  /** type of email, used for finding proper template */
  type?: InputMaybe<EmailType>;
};

export enum EmailStatus {
  Canceled = 'Canceled',
  Processing = 'Processing',
  Sent = 'Sent',
  Unsent = 'Unsent'
}

export enum EmailType {
  Feedback = 'Feedback',
  OrderConfirmed = 'OrderConfirmed',
  OrderReceived = 'OrderReceived',
  OrderRejected = 'OrderRejected',
  OrderRescheduled = 'OrderRescheduled'
}

export type Mutation = {
  __typename?: 'Mutation';
  createBreak: Order;
  createEmail: EmailEntity;
  createOrder: Order;
  createOrderChild: OrderChild;
  createOrganisation: Organisation;
  createSanta: Santa;
  createSeason: Season;
  createUser: UserEntity;
  removeOrder: Scalars['String'];
  removeOrderChild: Scalars['String'];
  removeOrderChildren: Array<Scalars['String']>;
  removeOrganisation: Scalars['String'];
  removeSanta: Scalars['String'];
  removeSeason: Scalars['String'];
  removeUser: Scalars['String'];
  updateOrder: Order;
  updateOrderChild: OrderChild;
  updateOrganisation: Organisation;
  updateSanta: Santa;
  updateSeason: Season;
  updateUser: UserEntity;
};


export type MutationCreateBreakArgs = {
  orderInput: OrderInput;
};


export type MutationCreateEmailArgs = {
  emailInput: EmailInput;
};


export type MutationCreateOrderArgs = {
  orderInput: OrderInput;
};


export type MutationCreateOrderChildArgs = {
  createOrderChildInput: CreateOrderChildInput;
  orderId: Scalars['String'];
};


export type MutationCreateOrganisationArgs = {
  createOrganisationAdminInput: CreateOrgAdminInput;
  createOrganisationInput: CreateOrganisationInput;
};


export type MutationCreateSantaArgs = {
  createSantaInput: CreateSantaInput;
};


export type MutationCreateSeasonArgs = {
  createSeasonInput: CreateSeasonInput;
};


export type MutationCreateUserArgs = {
  createUserInput: CreateUserInput;
};


export type MutationRemoveOrderArgs = {
  id: Scalars['String'];
};


export type MutationRemoveOrderChildArgs = {
  id: Scalars['String'];
};


export type MutationRemoveOrderChildrenArgs = {
  orderId: Scalars['String'];
};


export type MutationRemoveOrganisationArgs = {
  id: Scalars['String'];
};


export type MutationRemoveSantaArgs = {
  id: Scalars['String'];
};


export type MutationRemoveSeasonArgs = {
  id: Scalars['String'];
};


export type MutationRemoveUserArgs = {
  id: Scalars['String'];
};


export type MutationUpdateOrderArgs = {
  id: Scalars['String'];
  updateInput: UpdateOrderInput;
};


export type MutationUpdateOrderChildArgs = {
  id: Scalars['String'];
  updateOrderChildInput: UpdateOrderChildInput;
};


export type MutationUpdateOrganisationArgs = {
  id: Scalars['String'];
  updateOrgAdminInput: UpdateOrgAdminInput;
  updateOrganisationInput: UpdateOrganisationInput;
};


export type MutationUpdateSantaArgs = {
  id: Scalars['String'];
  updateSantaInput: UpdateSantaInput;
};


export type MutationUpdateSeasonArgs = {
  id: Scalars['String'];
  updateSeasonInput: UpdateSeasonInput;
};


export type MutationUpdateUserArgs = {
  id: Scalars['String'];
  updateUserInput: UpdateUserInput;
};

export type Order = {
  __typename?: 'Order';
  /** city */
  city: Scalars['String'];
  /** confirmed start time for visit */
  confirmedStartAt?: Maybe<Scalars['String']>;
  createOrderChildren: OrderChild;
  createdAt: Scalars['String'];
  /** name of the customer */
  customerName: Scalars['String'];
  /** Doorcode */
  doorCode?: Maybe<Scalars['String']>;
  /** duration of the Santas visit in minutes */
  duration: Scalars['Float'];
  /** email of the customer */
  email: Scalars['String'];
  /** instructions for Santa where to find gifts */
  giftInfo?: Maybe<Scalars['String']>;
  /** id of the order */
  id: Scalars['String'];
  /** preferred language of the customer */
  language: Scalars['String'];
  /** lon/lat position of the entrance of the order destination */
  lonlat: Scalars['String'];
  /** will orderChild be greeting Santa outside */
  meetOutside: Scalars['Boolean'];
  /** Is the customer subscribed to newsletter */
  newsletterIsSubscribed?: Maybe<Scalars['Boolean']>;
  orderChildren: Array<OrderChild>;
  /** order status */
  orderStatus?: Maybe<Scalars['String']>;
  /** additional details Santa should be aware of */
  otherInfo?: Maybe<Scalars['String']>;
  /** phone number of the customer */
  phone: Scalars['String'];
  /** postal code of the customer */
  postalCode: Scalars['String'];
  /** price of the order */
  price?: Maybe<Scalars['Float']>;
  /** directions for Santa */
  routeInfo?: Maybe<Scalars['String']>;
  /** id of santa assigned to this order */
  santaId?: Maybe<Scalars['String']>;
  /** id of the season */
  seasonId?: Maybe<Scalars['String']>;
  /** address of the customer */
  streetAddress: Scalars['String'];
  /** name on door/mailbox etc. so Santa can find the right house */
  visitName: Scalars['String'];
  /** earliest wished time for visit */
  wishEarliest: Scalars['String'];
  /** latest wished time for visit */
  wishLatest: Scalars['String'];
};


export type OrderCreateOrderChildrenArgs = {
  childInput: CreateOrderChildInput;
};

export type OrderChild = {
  __typename?: 'OrderChild';
  /** age of the child */
  age?: Maybe<Scalars['Int']>;
  /** time of creation of the order-child */
  createdAt: Scalars['String'];
  /** description of the child */
  description?: Maybe<Scalars['String']>;
  /** id of the child */
  id: Scalars['String'];
  /** name of the child */
  name: Scalars['String'];
  /** id of the order the child belongs to */
  orderId: Scalars['String'];
  /** a number to help return multiple children always in the same order */
  position: Scalars['Int'];
};

export type OrderInput = {
  /** city */
  city?: InputMaybe<Scalars['String']>;
  /** confirmed start time for visit */
  confirmedStartAt?: InputMaybe<Scalars['String']>;
  /** name of the customer */
  customerName?: InputMaybe<Scalars['String']>;
  /** Doorcode */
  doorCode?: InputMaybe<Scalars['String']>;
  /** duration of the Santas visit in minutes */
  duration?: InputMaybe<Scalars['Float']>;
  /** email of the customer */
  email?: InputMaybe<Scalars['String']>;
  /** instructions for Santa where to find gifts */
  giftInfo?: InputMaybe<Scalars['String']>;
  /** preferred language of the customer */
  language?: InputMaybe<Scalars['String']>;
  /** lon/lat position of the entrance of the order destination */
  lonlat?: InputMaybe<Scalars['String']>;
  /** will orderChild be greeting Santa outside */
  meetOutside?: InputMaybe<Scalars['Boolean']>;
  /** Is the customer subscribed to newsletter */
  newsletterIsSubscribed?: InputMaybe<Scalars['Boolean']>;
  /** order status */
  orderStatus?: InputMaybe<Scalars['String']>;
  /** additional details Santa should be aware of */
  otherInfo?: InputMaybe<Scalars['String']>;
  /** phone number of the customer */
  phone?: InputMaybe<Scalars['String']>;
  /** postal code of the customer */
  postalCode?: InputMaybe<Scalars['String']>;
  /** price of the order */
  price?: InputMaybe<Scalars['Float']>;
  /** directions for Santa */
  routeInfo?: InputMaybe<Scalars['String']>;
  /** id of santa assigned to this order */
  santaId?: InputMaybe<Scalars['String']>;
  /** id of the season */
  seasonId?: InputMaybe<Scalars['String']>;
  /** address of the customer */
  streetAddress?: InputMaybe<Scalars['String']>;
  /** name on door/mailbox etc. so Santa can find the right house */
  visitName?: InputMaybe<Scalars['String']>;
  /** earliest wished time for visit */
  wishEarliest?: InputMaybe<Scalars['String']>;
  /** latest wished time for visit */
  wishLatest?: InputMaybe<Scalars['String']>;
};

export type Organisation = {
  __typename?: 'Organisation';
  /** address of the organisation */
  address?: Maybe<Scalars['String']>;
  /** the organisation admin user */
  adminUser: UserEntity;
  /** time of creation of the organisation profile */
  createdAt: Scalars['String'];
  /** email of the organisation */
  email: Scalars['String'];
  /** description of the organisations frontpage */
  frontpageDescription: Scalars['String'];
  /** subtitle of the organisations frontpage */
  frontpageSubtitle: Scalars['String'];
  /** title of the organisations frontpage */
  frontpageTitle: Scalars['String'];
  /** id of the organisation */
  id: Scalars['String'];
  /** logo of the organisation */
  logoFile?: Maybe<Scalars['String']>;
  /** name of the organisation */
  name: Scalars['String'];
  /** phone number of the organisation */
  phone?: Maybe<Scalars['String']>;
  /** short name of the organisation (for URLS) */
  shortName: Scalars['String'];
  /** VAT-number of the organisation */
  vatId?: Maybe<Scalars['String']>;
  /** website url of the organisation */
  websiteURL?: Maybe<Scalars['String']>;
};

export type OrganisationInfo = {
  __typename?: 'OrganisationInfo';
  address: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  shortName: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  openSeason: Season;
  order: Order;
  orderChild: OrderChild;
  orderChildren: Array<OrderChild>;
  orders: Array<Order>;
  organisation: Organisation;
  organisationInfo: Array<OrganisationInfo>;
  organisationSeasons: Array<Season>;
  organisationUsers: Array<UserEntity>;
  organisationWithShortName: Organisation;
  organisations: Array<Organisation>;
  santa: Santa;
  santas: Array<Santa>;
  santasOrders: Array<Order>;
  season: Season;
  seasonOrders: Array<Order>;
  seasonSantas: Array<Santa>;
  seasonSchedule: SeasonSchedule;
  seasons: Array<Season>;
};


export type QueryOpenSeasonArgs = {
  organisationId: Scalars['String'];
};


export type QueryOrderArgs = {
  id: Scalars['String'];
};


export type QueryOrderChildArgs = {
  id: Scalars['String'];
};


export type QueryOrderChildrenArgs = {
  orderId: Scalars['String'];
};


export type QueryOrganisationArgs = {
  id: Scalars['String'];
};


export type QueryOrganisationSeasonsArgs = {
  organisationId: Scalars['String'];
};


export type QueryOrganisationUsersArgs = {
  organisationId: Scalars['String'];
};


export type QueryOrganisationWithShortNameArgs = {
  shortName: Scalars['String'];
};


export type QuerySantaArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QuerySantasOrdersArgs = {
  santaId?: InputMaybe<Scalars['String']>;
};


export type QuerySeasonArgs = {
  id: Scalars['String'];
};


export type QuerySeasonOrdersArgs = {
  seasonId: Scalars['String'];
};


export type QuerySeasonSantasArgs = {
  seasonId: Scalars['String'];
};


export type QuerySeasonScheduleArgs = {
  seasonId: Scalars['String'];
};

export type Santa = {
  __typename?: 'Santa';
  /** name of the santas area for internal use */
  areaNameInternal?: Maybe<Scalars['String']>;
  /** name of the santas area for public use */
  areaNamePublic: Scalars['String'];
  /** time of creation of the santa */
  createdAt: Scalars['String'];
  /** email of the driver */
  driverEmail?: Maybe<Scalars['String']>;
  /** name of the driver */
  driverName?: Maybe<Scalars['String']>;
  /** phone number of the driver */
  driverPhone?: Maybe<Scalars['String']>;
  /** time of santas last orders start time */
  endTime: Scalars['String'];
  /** id of the santa */
  id: Scalars['String'];
  /** name of the santa */
  name: Scalars['String'];
  /** value to keep santas organised */
  position: Scalars['Float'];
  /** email of the santa */
  santaEmail?: Maybe<Scalars['String']>;
  /** phone number of the santa */
  santaPhone: Scalars['String'];
  /** id of the season the santa belongs to */
  seasonId: Scalars['String'];
  /** will santa be shown on the frontpage */
  showOnFrontpage?: Maybe<Scalars['Boolean']>;
  /** start time of santas shift, when the first order can be accepted */
  startTime: Scalars['String'];
  /** id of the santa's user object */
  userId?: Maybe<Scalars['String']>;
};

export type SantaSchedule = {
  __typename?: 'SantaSchedule';
  breaks: Scalars['Float'];
  id: Scalars['String'];
  orders: Scalars['Float'];
  showOnFrontpage: Scalars['Boolean'];
  totalSlots: Scalars['Float'];
};

export type Season = {
  __typename?: 'Season';
  /** christmas status */
  christmasStatus?: Maybe<ChristmasStatus>;
  createdAt: Scalars['String'];
  /** duration of a single visit in minutes */
  duration: Scalars['Int'];
  /** id of the season */
  id: Scalars['String'];
  /** time between visits in minutes */
  interval: Scalars['Int'];
  /** time when the last orders can be accepted */
  maxStartTime: Scalars['String'];
  /** time when the first orders can be accepted */
  minStartTime: Scalars['String'];
  /** id of the organisation this season belongs to */
  organisationId: Scalars['String'];
  /** default price for an order */
  price: Scalars['Float'];
  /** year of the season */
  year: Scalars['Int'];
};

export type SeasonSchedule = {
  __typename?: 'SeasonSchedule';
  areas: Array<Area>;
  santas: Array<SantaSchedule>;
};

export type UpdateOrderChildInput = {
  /** age of the child */
  age?: InputMaybe<Scalars['Int']>;
  /** description of the child */
  description?: InputMaybe<Scalars['String']>;
  /** name of the child */
  name?: InputMaybe<Scalars['String']>;
  /** a number to help return multiple children always in the same order */
  position?: InputMaybe<Scalars['Int']>;
};

export type UpdateOrderInput = {
  /** city */
  city?: InputMaybe<Scalars['String']>;
  /** confirmed start time for visit */
  confirmedStartAt?: InputMaybe<Scalars['String']>;
  /** name of the customer */
  customerName?: InputMaybe<Scalars['String']>;
  /** Doorcode */
  doorCode?: InputMaybe<Scalars['String']>;
  /** duration of the Santas visit in minutes */
  duration?: InputMaybe<Scalars['Float']>;
  /** email of the customer */
  email?: InputMaybe<Scalars['String']>;
  /** instructions for Santa where to find gifts */
  giftInfo?: InputMaybe<Scalars['String']>;
  /** preferred language of the customer */
  language?: InputMaybe<Scalars['String']>;
  /** lon/lat position of the entrance of the order destination */
  lonlat?: InputMaybe<Scalars['String']>;
  /** will orderChild be greeting Santa outside */
  meetOutside?: InputMaybe<Scalars['Boolean']>;
  /** Is the customer subscribed to newsletter */
  newsletterIsSubscribed?: InputMaybe<Scalars['Boolean']>;
  /** order status */
  orderStatus?: InputMaybe<Scalars['String']>;
  /** additional details Santa should be aware of */
  otherInfo?: InputMaybe<Scalars['String']>;
  /** phone number of the customer */
  phone?: InputMaybe<Scalars['String']>;
  /** postal code of the customer */
  postalCode?: InputMaybe<Scalars['String']>;
  /** price of the order */
  price?: InputMaybe<Scalars['Float']>;
  /** directions for Santa */
  routeInfo?: InputMaybe<Scalars['String']>;
  /** id of santa assigned to this order */
  santaId?: InputMaybe<Scalars['String']>;
  /** address of the customer */
  streetAddress?: InputMaybe<Scalars['String']>;
  /** name on door/mailbox etc. so Santa can find the right house */
  visitName?: InputMaybe<Scalars['String']>;
  /** earliest wished time for visit */
  wishEarliest?: InputMaybe<Scalars['String']>;
  /** latest wished time for visit */
  wishLatest?: InputMaybe<Scalars['String']>;
};

export type UpdateOrgAdminInput = {
  /** email address of the user */
  email?: InputMaybe<Scalars['String']>;
  /** Family name of the user */
  family_name?: InputMaybe<Scalars['String']>;
  /** Given (first) name of the user */
  given_name?: InputMaybe<Scalars['String']>;
  /** Phone number of the user */
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type UpdateOrganisationInput = {
  /** address of the organisation */
  address?: InputMaybe<Scalars['String']>;
  /** email of the organisation */
  email?: InputMaybe<Scalars['String']>;
  /** description of the organisations frontpage */
  frontpageDescription?: InputMaybe<Scalars['String']>;
  /** subtitle of the organisations frontpage */
  frontpageSubtitle?: InputMaybe<Scalars['String']>;
  /** title of the organisations frontpage */
  frontpageTitle?: InputMaybe<Scalars['String']>;
  /** logo of the organisation */
  logoFile?: InputMaybe<Scalars['String']>;
  /** name of the organisation */
  name?: InputMaybe<Scalars['String']>;
  /** phone number of the organisation */
  phone?: InputMaybe<Scalars['String']>;
  /** short name of the organisation (for URLS) */
  shortName?: InputMaybe<Scalars['String']>;
  /** VAT-number of the organisation */
  vatId?: InputMaybe<Scalars['String']>;
  /** website url of the organisation */
  websiteURL?: InputMaybe<Scalars['String']>;
};

export type UpdateSantaInput = {
  /** name of the santas area for internal use */
  areaNameInternal?: InputMaybe<Scalars['String']>;
  /** name of the santas area for public use */
  areaNamePublic?: InputMaybe<Scalars['String']>;
  /** email of the driver */
  driverEmail?: InputMaybe<Scalars['String']>;
  /** name of the driver */
  driverName?: InputMaybe<Scalars['String']>;
  /** phone number of the driver */
  driverPhone?: InputMaybe<Scalars['String']>;
  /** time of santas last orders start time */
  endTime?: InputMaybe<Scalars['String']>;
  /** name of the santa */
  name?: InputMaybe<Scalars['String']>;
  /** value to keep santas organised */
  position?: InputMaybe<Scalars['Float']>;
  /** email of the santa */
  santaEmail?: InputMaybe<Scalars['String']>;
  /** phone number of the santa */
  santaPhone?: InputMaybe<Scalars['String']>;
  /** id of the season the santa belongs to */
  seasonId?: InputMaybe<Scalars['String']>;
  /** will santa be shown on the frontpage */
  showOnFrontpage?: InputMaybe<Scalars['Boolean']>;
  /** start time of santas shift, when the first order can be accepted */
  startTime?: InputMaybe<Scalars['String']>;
  /** id of the santa's user object */
  userId?: InputMaybe<Scalars['String']>;
};

export type UpdateSeasonInput = {
  /** christmas status */
  christmasStatus?: InputMaybe<ChristmasStatus>;
  /** duration of a single visit in minutes */
  duration?: InputMaybe<Scalars['Int']>;
  /** time between visits in minutes */
  interval?: InputMaybe<Scalars['Int']>;
  /** time when the last orders can be accepted */
  maxStartTime?: InputMaybe<Scalars['String']>;
  /** time when the first orders can be accepted */
  minStartTime?: InputMaybe<Scalars['String']>;
  /** id of the organisation this season belongs to */
  organisationId?: InputMaybe<Scalars['String']>;
  /** default price for an order */
  price?: InputMaybe<Scalars['Float']>;
  /** year of the season */
  year?: InputMaybe<Scalars['Int']>;
};

export type UpdateUserInput = {
  /** Family name of the user */
  family_name?: InputMaybe<Scalars['String']>;
  /** Given (first) name of the user */
  given_name?: InputMaybe<Scalars['String']>;
  /** Phone number of the user */
  phoneNumber?: InputMaybe<Scalars['String']>;
  /** User roles of the user */
  roles?: InputMaybe<Array<UserRole>>;
};

export type UserEntity = {
  __typename?: 'UserEntity';
  /** time of creation of the santa */
  createdAt: Scalars['String'];
  /** email address of the user */
  email: Scalars['String'];
  /** Family name of the user */
  family_name: Scalars['String'];
  /** Given (first) name of the user */
  given_name: Scalars['String'];
  /** db native user id */
  id: Scalars['String'];
  /** user id in the identity management system (AWS Cognito) */
  idm_user_id: Scalars['String'];
  /** id of the organisation the user belongs to */
  organisationId: Scalars['String'];
  /** Phone number of the user */
  phoneNumber: Scalars['String'];
  /** User roles of the user */
  roles: Array<UserRole>;
};

export enum UserRole {
  ORG_ADMIN = 'ORG_ADMIN',
  ORG_USER = 'ORG_USER',
  SANTA = 'SANTA',
  SUPERADMIN = 'SUPERADMIN'
}

export type CreateEmailMutationVariables = Exact<{
  input: EmailInput;
}>;


export type CreateEmailMutation = { __typename?: 'Mutation', createEmail: { __typename?: 'EmailEntity', id: string } };

export type SeasonScheduleQueryVariables = Exact<{
  seasonId: Scalars['String'];
}>;


export type SeasonScheduleQuery = { __typename?: 'Query', seasonSchedule: { __typename?: 'SeasonSchedule', areas: Array<{ __typename?: 'Area', name: string, freeTimes: number }>, santas: Array<{ __typename?: 'SantaSchedule', id: string, totalSlots: number, breaks: number, orders: number, showOnFrontpage: boolean }> } };

export type OrganisationUsersQueryVariables = Exact<{
  organisationId: Scalars['String'];
}>;


export type OrganisationUsersQuery = { __typename?: 'Query', organisationUsers: Array<{ __typename?: 'UserEntity', id: string, email: string, given_name: string, family_name: string, roles: Array<UserRole>, idm_user_id: string, organisationId: string, phoneNumber: string, createdAt: string }> };

export type CreateUserMutationVariables = Exact<{
  createUserInput: CreateUserInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'UserEntity', id: string } };

export type RemoveUserMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveUserMutation = { __typename?: 'Mutation', removeUser: string };

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['String'];
  updateUserInput: UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'UserEntity', given_name: string, family_name: string, roles: Array<UserRole>, phoneNumber: string } };

export type OrganisationFieldsFragment = { __typename?: 'Organisation', id: string, name: string, shortName: string, address?: string | null, phone?: string | null, email: string, websiteURL?: string | null, frontpageTitle: string, frontpageSubtitle: string, frontpageDescription: string, vatId?: string | null, logoFile?: string | null, adminUser: { __typename?: 'UserEntity', createdAt: string, email: string, given_name: string, family_name: string, phoneNumber: string, id: string, idm_user_id: string, organisationId: string, roles: Array<UserRole> } };

export type OrganisationsQueryVariables = Exact<{ [key: string]: never; }>;


export type OrganisationsQuery = { __typename?: 'Query', organisations: Array<{ __typename?: 'Organisation', createdAt: string, id: string, name: string, shortName: string, address?: string | null, phone?: string | null, email: string, websiteURL?: string | null, frontpageTitle: string, frontpageSubtitle: string, frontpageDescription: string, vatId?: string | null, logoFile?: string | null, adminUser: { __typename?: 'UserEntity', createdAt: string, email: string, given_name: string, family_name: string, phoneNumber: string, id: string, idm_user_id: string, organisationId: string, roles: Array<UserRole> } }> };

export type OrganisationsInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type OrganisationsInfoQuery = { __typename?: 'Query', organisationInfo: Array<{ __typename?: 'OrganisationInfo', id: string, name: string, shortName: string, address: string }> };

export type OrganisationQueryVariables = Exact<{
  organisationId: Scalars['String'];
}>;


export type OrganisationQuery = { __typename?: 'Query', organisation: { __typename?: 'Organisation', createdAt: string, id: string, name: string, shortName: string, address?: string | null, phone?: string | null, email: string, websiteURL?: string | null, frontpageTitle: string, frontpageSubtitle: string, frontpageDescription: string, vatId?: string | null, logoFile?: string | null, adminUser: { __typename?: 'UserEntity', createdAt: string, email: string, given_name: string, family_name: string, phoneNumber: string, id: string, idm_user_id: string, organisationId: string, roles: Array<UserRole> } } };

export type OrganisationWithShortNameQueryVariables = Exact<{
  shortName: Scalars['String'];
}>;


export type OrganisationWithShortNameQuery = { __typename?: 'Query', organisationWithShortName: { __typename?: 'Organisation', createdAt: string, id: string, name: string, shortName: string, address?: string | null, phone?: string | null, email: string, websiteURL?: string | null, frontpageTitle: string, frontpageSubtitle: string, frontpageDescription: string, vatId?: string | null, logoFile?: string | null, adminUser: { __typename?: 'UserEntity', createdAt: string, email: string, given_name: string, family_name: string, phoneNumber: string, id: string, idm_user_id: string, organisationId: string, roles: Array<UserRole> } } };

export type CreateOrganisationMutationVariables = Exact<{
  input: CreateOrganisationInput;
  adminInput: CreateOrgAdminInput;
}>;


export type CreateOrganisationMutation = { __typename?: 'Mutation', createOrganisation: { __typename?: 'Organisation', id: string, name: string, shortName: string, address?: string | null, phone?: string | null, email: string, websiteURL?: string | null, frontpageTitle: string, frontpageSubtitle: string, frontpageDescription: string, vatId?: string | null, logoFile?: string | null, adminUser: { __typename?: 'UserEntity', createdAt: string, email: string, given_name: string, family_name: string, phoneNumber: string, id: string, idm_user_id: string, organisationId: string, roles: Array<UserRole> } } };

export type UpdateOrganisationMutationVariables = Exact<{
  id: Scalars['String'];
  updateOrg: UpdateOrganisationInput;
  updateOrgAdmin: UpdateOrgAdminInput;
}>;


export type UpdateOrganisationMutation = { __typename?: 'Mutation', updateOrganisation: { __typename?: 'Organisation', id: string, name: string, shortName: string, address?: string | null, phone?: string | null, email: string, websiteURL?: string | null, frontpageTitle: string, frontpageSubtitle: string, frontpageDescription: string, vatId?: string | null, logoFile?: string | null, adminUser: { __typename?: 'UserEntity', createdAt: string, email: string, given_name: string, family_name: string, phoneNumber: string, id: string, idm_user_id: string, organisationId: string, roles: Array<UserRole> } } };

export type RemoveOrganisationMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveOrganisationMutation = { __typename?: 'Mutation', removeOrganisation: string };

export type OrderChildFieldsFragment = { __typename?: 'OrderChild', age?: number | null, name: string, description?: string | null, orderId: string, id: string };

export type OrderChildQueryVariables = Exact<{
  orderId: Scalars['String'];
}>;


export type OrderChildQuery = { __typename?: 'Query', orderChildren: Array<{ __typename?: 'OrderChild', age?: number | null, name: string, description?: string | null, orderId: string, id: string }> };

export type CreateOrderChildMutationVariables = Exact<{
  orderId: Scalars['String'];
  input: CreateOrderChildInput;
}>;


export type CreateOrderChildMutation = { __typename?: 'Mutation', createOrderChild: { __typename?: 'OrderChild', name: string, age?: number | null, description?: string | null, orderId: string } };

export type UpdateOrderChildMutationVariables = Exact<{
  id: Scalars['String'];
  input: UpdateOrderChildInput;
}>;


export type UpdateOrderChildMutation = { __typename?: 'Mutation', updateOrderChild: { __typename?: 'OrderChild', name: string, age?: number | null, description?: string | null, orderId: string } };

export type RemoveOrderChildMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveOrderChildMutation = { __typename?: 'Mutation', removeOrderChild: string };

export type RemoveOrderChildrenMutationVariables = Exact<{
  orderId: Scalars['String'];
}>;


export type RemoveOrderChildrenMutation = { __typename?: 'Mutation', removeOrderChildren: Array<string> };

export type OrderFieldsFragment = { __typename?: 'Order', id: string, lonlat: string, customerName: string, city: string, streetAddress: string, postalCode: string, orderStatus?: string | null, wishEarliest: string, wishLatest: string, confirmedStartAt?: string | null, phone: string, email: string, visitName: string, createdAt: string, giftInfo?: string | null, routeInfo?: string | null, otherInfo?: string | null, duration: number, language: string, meetOutside: boolean, santaId?: string | null, doorCode?: string | null, newsletterIsSubscribed?: boolean | null, price?: number | null };

export type OrdersQueryVariables = Exact<{ [key: string]: never; }>;


export type OrdersQuery = { __typename?: 'Query', orders: Array<{ __typename?: 'Order', id: string, lonlat: string, customerName: string, city: string, streetAddress: string, postalCode: string, orderStatus?: string | null, wishEarliest: string, wishLatest: string, confirmedStartAt?: string | null, phone: string, email: string, visitName: string, createdAt: string, giftInfo?: string | null, routeInfo?: string | null, otherInfo?: string | null, duration: number, language: string, meetOutside: boolean, santaId?: string | null, doorCode?: string | null, newsletterIsSubscribed?: boolean | null, price?: number | null, orderChildren: Array<{ __typename?: 'OrderChild', id: string, name: string, age?: number | null, description?: string | null, position: number }> }> };

export type OrderQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type OrderQuery = { __typename?: 'Query', order: { __typename?: 'Order', id: string, lonlat: string, customerName: string, city: string, streetAddress: string, postalCode: string, orderStatus?: string | null, wishEarliest: string, wishLatest: string, confirmedStartAt?: string | null, phone: string, email: string, visitName: string, createdAt: string, giftInfo?: string | null, routeInfo?: string | null, otherInfo?: string | null, duration: number, language: string, meetOutside: boolean, santaId?: string | null, doorCode?: string | null, newsletterIsSubscribed?: boolean | null, price?: number | null, orderChildren: Array<{ __typename?: 'OrderChild', name: string, age?: number | null, description?: string | null, position: number }> } };

export type SantasOrdersQueryVariables = Exact<{
  santaId?: InputMaybe<Scalars['String']>;
}>;


export type SantasOrdersQuery = { __typename?: 'Query', santasOrders: Array<{ __typename?: 'Order', id: string, lonlat: string, customerName: string, city: string, streetAddress: string, postalCode: string, orderStatus?: string | null, wishEarliest: string, wishLatest: string, confirmedStartAt?: string | null, phone: string, email: string, visitName: string, createdAt: string, giftInfo?: string | null, routeInfo?: string | null, otherInfo?: string | null, duration: number, language: string, meetOutside: boolean, santaId?: string | null, doorCode?: string | null, newsletterIsSubscribed?: boolean | null, price?: number | null, orderChildren: Array<{ __typename?: 'OrderChild', name: string, age?: number | null, description?: string | null, position: number }> }> };

export type SeasonOrdersQueryVariables = Exact<{
  seasonId: Scalars['String'];
}>;


export type SeasonOrdersQuery = { __typename?: 'Query', seasonOrders: Array<{ __typename?: 'Order', id: string, lonlat: string, customerName: string, city: string, streetAddress: string, postalCode: string, orderStatus?: string | null, wishEarliest: string, wishLatest: string, confirmedStartAt?: string | null, phone: string, email: string, visitName: string, createdAt: string, giftInfo?: string | null, routeInfo?: string | null, otherInfo?: string | null, duration: number, language: string, meetOutside: boolean, santaId?: string | null, doorCode?: string | null, newsletterIsSubscribed?: boolean | null, price?: number | null, orderChildren: Array<{ __typename?: 'OrderChild', name: string, age?: number | null, description?: string | null, position: number }> }> };

export type CreateOrderMutationVariables = Exact<{
  input: OrderInput;
  childInput: CreateOrderChildInput;
}>;


export type CreateOrderMutation = { __typename?: 'Mutation', createOrder: { __typename?: 'Order', id: string, streetAddress: string, createOrderChildren: { __typename?: 'OrderChild', name: string, age?: number | null, description?: string | null, position: number } } };

export type CreateBreakMutationVariables = Exact<{
  input: OrderInput;
}>;


export type CreateBreakMutation = { __typename?: 'Mutation', createBreak: { __typename?: 'Order', id: string } };

export type UpdateOrderMutationVariables = Exact<{
  id: Scalars['String'];
  updateInput: UpdateOrderInput;
}>;


export type UpdateOrderMutation = { __typename?: 'Mutation', updateOrder: { __typename?: 'Order', id: string, lonlat: string, customerName: string, city: string, streetAddress: string, postalCode: string, orderStatus?: string | null, wishEarliest: string, wishLatest: string, confirmedStartAt?: string | null, phone: string, email: string, visitName: string, createdAt: string, giftInfo?: string | null, routeInfo?: string | null, otherInfo?: string | null, duration: number, language: string, meetOutside: boolean, santaId?: string | null, doorCode?: string | null, newsletterIsSubscribed?: boolean | null, price?: number | null, orderChildren: Array<{ __typename?: 'OrderChild', name: string, age?: number | null, description?: string | null, position: number }> } };

export type RemoveOrderMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveOrderMutation = { __typename?: 'Mutation', removeOrder: string };

export type SantaFieldsFragment = { __typename?: 'Santa', id: string, seasonId: string, name: string, areaNameInternal?: string | null, areaNamePublic: string, showOnFrontpage?: boolean | null, santaPhone: string, santaEmail?: string | null, driverName?: string | null, driverPhone?: string | null, driverEmail?: string | null, startTime: string, endTime: string, position: number, createdAt: string, userId?: string | null };

export type SantaQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
}>;


export type SantaQuery = { __typename?: 'Query', santa: { __typename?: 'Santa', id: string, seasonId: string, name: string, areaNameInternal?: string | null, areaNamePublic: string, showOnFrontpage?: boolean | null, santaPhone: string, santaEmail?: string | null, driverName?: string | null, driverPhone?: string | null, driverEmail?: string | null, startTime: string, endTime: string, position: number, createdAt: string, userId?: string | null } };

export type SantasQueryVariables = Exact<{ [key: string]: never; }>;


export type SantasQuery = { __typename?: 'Query', santas: Array<{ __typename?: 'Santa', id: string, seasonId: string, name: string, areaNameInternal?: string | null, areaNamePublic: string, showOnFrontpage?: boolean | null, santaPhone: string, santaEmail?: string | null, driverName?: string | null, driverPhone?: string | null, driverEmail?: string | null, startTime: string, endTime: string, position: number, createdAt: string, userId?: string | null }> };

export type SeasonSantasQueryVariables = Exact<{
  seasonId: Scalars['String'];
}>;


export type SeasonSantasQuery = { __typename?: 'Query', seasonSantas: Array<{ __typename?: 'Santa', id: string, seasonId: string, name: string, areaNameInternal?: string | null, areaNamePublic: string, showOnFrontpage?: boolean | null, santaPhone: string, santaEmail?: string | null, driverName?: string | null, driverPhone?: string | null, driverEmail?: string | null, startTime: string, endTime: string, position: number, createdAt: string, userId?: string | null }> };

export type CreateSantaMutationVariables = Exact<{
  input: CreateSantaInput;
}>;


export type CreateSantaMutation = { __typename?: 'Mutation', createSanta: { __typename?: 'Santa', seasonId: string, name: string, areaNameInternal?: string | null, areaNamePublic: string, showOnFrontpage?: boolean | null, santaPhone: string, santaEmail?: string | null, driverName?: string | null, driverPhone?: string | null, driverEmail?: string | null, position: number } };

export type RemoveSantaMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemoveSantaMutation = { __typename?: 'Mutation', removeSanta: string };

export type UpdateSantaMutationVariables = Exact<{
  id: Scalars['String'];
  updateSantaInput: UpdateSantaInput;
}>;


export type UpdateSantaMutation = { __typename?: 'Mutation', updateSanta: { __typename?: 'Santa', id: string, seasonId: string, name: string, areaNameInternal?: string | null, areaNamePublic: string, showOnFrontpage?: boolean | null, santaPhone: string, santaEmail?: string | null, driverName?: string | null, driverPhone?: string | null, driverEmail?: string | null, startTime: string, endTime: string, position: number, createdAt: string, userId?: string | null } };

export type SeasonFieldsFragment = { __typename?: 'Season', organisationId: string, year: number, price: number, duration: number, interval: number, id: string, minStartTime: string, maxStartTime: string, createdAt: string, christmasStatus?: ChristmasStatus | null };

export type SeasonQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type SeasonQuery = { __typename?: 'Query', season: { __typename?: 'Season', organisationId: string, year: number, price: number, duration: number, interval: number, id: string, minStartTime: string, maxStartTime: string, createdAt: string, christmasStatus?: ChristmasStatus | null } };

export type SeasonsQueryVariables = Exact<{ [key: string]: never; }>;


export type SeasonsQuery = { __typename?: 'Query', seasons: Array<{ __typename?: 'Season', organisationId: string, year: number, price: number, duration: number, interval: number, id: string, minStartTime: string, maxStartTime: string, createdAt: string, christmasStatus?: ChristmasStatus | null }> };

export type OrganisationSeasonsQueryVariables = Exact<{
  organisationId: Scalars['String'];
}>;


export type OrganisationSeasonsQuery = { __typename?: 'Query', organisationSeasons: Array<{ __typename?: 'Season', organisationId: string, year: number, price: number, duration: number, interval: number, id: string, minStartTime: string, maxStartTime: string, createdAt: string, christmasStatus?: ChristmasStatus | null }> };

export type OpenSeasonQueryVariables = Exact<{
  organisationId: Scalars['String'];
}>;


export type OpenSeasonQuery = { __typename?: 'Query', openSeason: { __typename?: 'Season', organisationId: string, year: number, price: number, duration: number, interval: number, id: string, minStartTime: string, maxStartTime: string, createdAt: string, christmasStatus?: ChristmasStatus | null } };

export type CreateSeasonMutationVariables = Exact<{
  input: CreateSeasonInput;
}>;


export type CreateSeasonMutation = { __typename?: 'Mutation', createSeason: { __typename?: 'Season', organisationId: string, year: number, price: number, duration: number, interval: number, christmasStatus?: ChristmasStatus | null, minStartTime: string, maxStartTime: string } };

export type UpdateSeasonMutationVariables = Exact<{
  id: Scalars['String'];
  updateSeasonInput: UpdateSeasonInput;
}>;


export type UpdateSeasonMutation = { __typename?: 'Mutation', updateSeason: { __typename?: 'Season', organisationId: string, year: number, price: number, duration: number, interval: number, id: string, minStartTime: string, maxStartTime: string, createdAt: string, christmasStatus?: ChristmasStatus | null } };

export const OrganisationFieldsFragmentDoc = gql`
    fragment OrganisationFields on Organisation {
  id
  name
  shortName
  address
  phone
  email
  websiteURL
  frontpageTitle
  frontpageSubtitle
  frontpageDescription
  vatId
  logoFile
  adminUser {
    createdAt
    email
    given_name
    family_name
    phoneNumber
    id
    idm_user_id
    organisationId
    roles
  }
}
    `;
export const OrderChildFieldsFragmentDoc = gql`
    fragment OrderChildFields on OrderChild {
  age
  name
  description
  orderId
  id
}
    `;
export const OrderFieldsFragmentDoc = gql`
    fragment OrderFields on Order {
  id
  lonlat
  customerName
  city
  streetAddress
  postalCode
  orderStatus
  wishEarliest
  wishLatest
  confirmedStartAt
  phone
  email
  visitName
  createdAt
  giftInfo
  routeInfo
  otherInfo
  duration
  language
  meetOutside
  santaId
  doorCode
  newsletterIsSubscribed
  price
}
    `;
export const SantaFieldsFragmentDoc = gql`
    fragment SantaFields on Santa {
  id
  seasonId
  name
  areaNameInternal
  areaNamePublic
  showOnFrontpage
  santaPhone
  santaEmail
  driverName
  driverPhone
  driverEmail
  startTime
  endTime
  position
  createdAt
  userId
}
    `;
export const SeasonFieldsFragmentDoc = gql`
    fragment SeasonFields on Season {
  organisationId
  year
  price
  duration
  interval
  id
  minStartTime
  maxStartTime
  createdAt
  christmasStatus
}
    `;
export const CreateEmailDocument = gql`
    mutation CreateEmail($input: EmailInput!) {
  createEmail(emailInput: $input) {
    id
  }
}
    `;
export type CreateEmailMutationFn = Apollo.MutationFunction<CreateEmailMutation, CreateEmailMutationVariables>;

/**
 * __useCreateEmailMutation__
 *
 * To run a mutation, you first call `useCreateEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmailMutation, { data, loading, error }] = useCreateEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEmailMutation(baseOptions?: Apollo.MutationHookOptions<CreateEmailMutation, CreateEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEmailMutation, CreateEmailMutationVariables>(CreateEmailDocument, options);
      }
export type CreateEmailMutationHookResult = ReturnType<typeof useCreateEmailMutation>;
export type CreateEmailMutationResult = Apollo.MutationResult<CreateEmailMutation>;
export type CreateEmailMutationOptions = Apollo.BaseMutationOptions<CreateEmailMutation, CreateEmailMutationVariables>;
export const SeasonScheduleDocument = gql`
    query SeasonSchedule($seasonId: String!) {
  seasonSchedule(seasonId: $seasonId) {
    areas {
      name
      freeTimes
    }
    santas {
      id
      totalSlots
      breaks
      orders
      showOnFrontpage
    }
  }
}
    `;

/**
 * __useSeasonScheduleQuery__
 *
 * To run a query within a React component, call `useSeasonScheduleQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeasonScheduleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeasonScheduleQuery({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useSeasonScheduleQuery(baseOptions: Apollo.QueryHookOptions<SeasonScheduleQuery, SeasonScheduleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SeasonScheduleQuery, SeasonScheduleQueryVariables>(SeasonScheduleDocument, options);
      }
export function useSeasonScheduleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SeasonScheduleQuery, SeasonScheduleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SeasonScheduleQuery, SeasonScheduleQueryVariables>(SeasonScheduleDocument, options);
        }
export type SeasonScheduleQueryHookResult = ReturnType<typeof useSeasonScheduleQuery>;
export type SeasonScheduleLazyQueryHookResult = ReturnType<typeof useSeasonScheduleLazyQuery>;
export type SeasonScheduleQueryResult = Apollo.QueryResult<SeasonScheduleQuery, SeasonScheduleQueryVariables>;
export const OrganisationUsersDocument = gql`
    query OrganisationUsers($organisationId: String!) {
  organisationUsers(organisationId: $organisationId) {
    id
    email
    given_name
    family_name
    roles
    idm_user_id
    organisationId
    phoneNumber
    createdAt
  }
}
    `;

/**
 * __useOrganisationUsersQuery__
 *
 * To run a query within a React component, call `useOrganisationUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationUsersQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *   },
 * });
 */
export function useOrganisationUsersQuery(baseOptions: Apollo.QueryHookOptions<OrganisationUsersQuery, OrganisationUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganisationUsersQuery, OrganisationUsersQueryVariables>(OrganisationUsersDocument, options);
      }
export function useOrganisationUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganisationUsersQuery, OrganisationUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganisationUsersQuery, OrganisationUsersQueryVariables>(OrganisationUsersDocument, options);
        }
export type OrganisationUsersQueryHookResult = ReturnType<typeof useOrganisationUsersQuery>;
export type OrganisationUsersLazyQueryHookResult = ReturnType<typeof useOrganisationUsersLazyQuery>;
export type OrganisationUsersQueryResult = Apollo.QueryResult<OrganisationUsersQuery, OrganisationUsersQueryVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($createUserInput: CreateUserInput!) {
  createUser(createUserInput: $createUserInput) {
    id
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      createUserInput: // value for 'createUserInput'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const RemoveUserDocument = gql`
    mutation RemoveUser($id: String!) {
  removeUser(id: $id)
}
    `;
export type RemoveUserMutationFn = Apollo.MutationFunction<RemoveUserMutation, RemoveUserMutationVariables>;

/**
 * __useRemoveUserMutation__
 *
 * To run a mutation, you first call `useRemoveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserMutation, { data, loading, error }] = useRemoveUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveUserMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserMutation, RemoveUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUserMutation, RemoveUserMutationVariables>(RemoveUserDocument, options);
      }
export type RemoveUserMutationHookResult = ReturnType<typeof useRemoveUserMutation>;
export type RemoveUserMutationResult = Apollo.MutationResult<RemoveUserMutation>;
export type RemoveUserMutationOptions = Apollo.BaseMutationOptions<RemoveUserMutation, RemoveUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: String!, $updateUserInput: UpdateUserInput!) {
  updateUser(id: $id, updateUserInput: $updateUserInput) {
    given_name
    family_name
    roles
    phoneNumber
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updateUserInput: // value for 'updateUserInput'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const OrganisationsDocument = gql`
    query Organisations {
  organisations {
    ...OrganisationFields
    createdAt
  }
}
    ${OrganisationFieldsFragmentDoc}`;

/**
 * __useOrganisationsQuery__
 *
 * To run a query within a React component, call `useOrganisationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganisationsQuery(baseOptions?: Apollo.QueryHookOptions<OrganisationsQuery, OrganisationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganisationsQuery, OrganisationsQueryVariables>(OrganisationsDocument, options);
      }
export function useOrganisationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganisationsQuery, OrganisationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganisationsQuery, OrganisationsQueryVariables>(OrganisationsDocument, options);
        }
export type OrganisationsQueryHookResult = ReturnType<typeof useOrganisationsQuery>;
export type OrganisationsLazyQueryHookResult = ReturnType<typeof useOrganisationsLazyQuery>;
export type OrganisationsQueryResult = Apollo.QueryResult<OrganisationsQuery, OrganisationsQueryVariables>;
export const OrganisationsInfoDocument = gql`
    query OrganisationsInfo {
  organisationInfo {
    id
    name
    shortName
    address
  }
}
    `;

/**
 * __useOrganisationsInfoQuery__
 *
 * To run a query within a React component, call `useOrganisationsInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationsInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationsInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganisationsInfoQuery(baseOptions?: Apollo.QueryHookOptions<OrganisationsInfoQuery, OrganisationsInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganisationsInfoQuery, OrganisationsInfoQueryVariables>(OrganisationsInfoDocument, options);
      }
export function useOrganisationsInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganisationsInfoQuery, OrganisationsInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganisationsInfoQuery, OrganisationsInfoQueryVariables>(OrganisationsInfoDocument, options);
        }
export type OrganisationsInfoQueryHookResult = ReturnType<typeof useOrganisationsInfoQuery>;
export type OrganisationsInfoLazyQueryHookResult = ReturnType<typeof useOrganisationsInfoLazyQuery>;
export type OrganisationsInfoQueryResult = Apollo.QueryResult<OrganisationsInfoQuery, OrganisationsInfoQueryVariables>;
export const OrganisationDocument = gql`
    query Organisation($organisationId: String!) {
  organisation(id: $organisationId) {
    ...OrganisationFields
    createdAt
  }
}
    ${OrganisationFieldsFragmentDoc}`;

/**
 * __useOrganisationQuery__
 *
 * To run a query within a React component, call `useOrganisationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *   },
 * });
 */
export function useOrganisationQuery(baseOptions: Apollo.QueryHookOptions<OrganisationQuery, OrganisationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganisationQuery, OrganisationQueryVariables>(OrganisationDocument, options);
      }
export function useOrganisationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganisationQuery, OrganisationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganisationQuery, OrganisationQueryVariables>(OrganisationDocument, options);
        }
export type OrganisationQueryHookResult = ReturnType<typeof useOrganisationQuery>;
export type OrganisationLazyQueryHookResult = ReturnType<typeof useOrganisationLazyQuery>;
export type OrganisationQueryResult = Apollo.QueryResult<OrganisationQuery, OrganisationQueryVariables>;
export const OrganisationWithShortNameDocument = gql`
    query OrganisationWithShortName($shortName: String!) {
  organisationWithShortName(shortName: $shortName) {
    ...OrganisationFields
    createdAt
  }
}
    ${OrganisationFieldsFragmentDoc}`;

/**
 * __useOrganisationWithShortNameQuery__
 *
 * To run a query within a React component, call `useOrganisationWithShortNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationWithShortNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationWithShortNameQuery({
 *   variables: {
 *      shortName: // value for 'shortName'
 *   },
 * });
 */
export function useOrganisationWithShortNameQuery(baseOptions: Apollo.QueryHookOptions<OrganisationWithShortNameQuery, OrganisationWithShortNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganisationWithShortNameQuery, OrganisationWithShortNameQueryVariables>(OrganisationWithShortNameDocument, options);
      }
export function useOrganisationWithShortNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganisationWithShortNameQuery, OrganisationWithShortNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganisationWithShortNameQuery, OrganisationWithShortNameQueryVariables>(OrganisationWithShortNameDocument, options);
        }
export type OrganisationWithShortNameQueryHookResult = ReturnType<typeof useOrganisationWithShortNameQuery>;
export type OrganisationWithShortNameLazyQueryHookResult = ReturnType<typeof useOrganisationWithShortNameLazyQuery>;
export type OrganisationWithShortNameQueryResult = Apollo.QueryResult<OrganisationWithShortNameQuery, OrganisationWithShortNameQueryVariables>;
export const CreateOrganisationDocument = gql`
    mutation CreateOrganisation($input: CreateOrganisationInput!, $adminInput: CreateOrgAdminInput!) {
  createOrganisation(
    createOrganisationInput: $input
    createOrganisationAdminInput: $adminInput
  ) {
    ...OrganisationFields
  }
}
    ${OrganisationFieldsFragmentDoc}`;
export type CreateOrganisationMutationFn = Apollo.MutationFunction<CreateOrganisationMutation, CreateOrganisationMutationVariables>;

/**
 * __useCreateOrganisationMutation__
 *
 * To run a mutation, you first call `useCreateOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganisationMutation, { data, loading, error }] = useCreateOrganisationMutation({
 *   variables: {
 *      input: // value for 'input'
 *      adminInput: // value for 'adminInput'
 *   },
 * });
 */
export function useCreateOrganisationMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrganisationMutation, CreateOrganisationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrganisationMutation, CreateOrganisationMutationVariables>(CreateOrganisationDocument, options);
      }
export type CreateOrganisationMutationHookResult = ReturnType<typeof useCreateOrganisationMutation>;
export type CreateOrganisationMutationResult = Apollo.MutationResult<CreateOrganisationMutation>;
export type CreateOrganisationMutationOptions = Apollo.BaseMutationOptions<CreateOrganisationMutation, CreateOrganisationMutationVariables>;
export const UpdateOrganisationDocument = gql`
    mutation UpdateOrganisation($id: String!, $updateOrg: UpdateOrganisationInput!, $updateOrgAdmin: UpdateOrgAdminInput!) {
  updateOrganisation(
    id: $id
    updateOrganisationInput: $updateOrg
    updateOrgAdminInput: $updateOrgAdmin
  ) {
    ...OrganisationFields
  }
}
    ${OrganisationFieldsFragmentDoc}`;
export type UpdateOrganisationMutationFn = Apollo.MutationFunction<UpdateOrganisationMutation, UpdateOrganisationMutationVariables>;

/**
 * __useUpdateOrganisationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganisationMutation, { data, loading, error }] = useUpdateOrganisationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updateOrg: // value for 'updateOrg'
 *      updateOrgAdmin: // value for 'updateOrgAdmin'
 *   },
 * });
 */
export function useUpdateOrganisationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganisationMutation, UpdateOrganisationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganisationMutation, UpdateOrganisationMutationVariables>(UpdateOrganisationDocument, options);
      }
export type UpdateOrganisationMutationHookResult = ReturnType<typeof useUpdateOrganisationMutation>;
export type UpdateOrganisationMutationResult = Apollo.MutationResult<UpdateOrganisationMutation>;
export type UpdateOrganisationMutationOptions = Apollo.BaseMutationOptions<UpdateOrganisationMutation, UpdateOrganisationMutationVariables>;
export const RemoveOrganisationDocument = gql`
    mutation RemoveOrganisation($id: String!) {
  removeOrganisation(id: $id)
}
    `;
export type RemoveOrganisationMutationFn = Apollo.MutationFunction<RemoveOrganisationMutation, RemoveOrganisationMutationVariables>;

/**
 * __useRemoveOrganisationMutation__
 *
 * To run a mutation, you first call `useRemoveOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOrganisationMutation, { data, loading, error }] = useRemoveOrganisationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveOrganisationMutation(baseOptions?: Apollo.MutationHookOptions<RemoveOrganisationMutation, RemoveOrganisationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveOrganisationMutation, RemoveOrganisationMutationVariables>(RemoveOrganisationDocument, options);
      }
export type RemoveOrganisationMutationHookResult = ReturnType<typeof useRemoveOrganisationMutation>;
export type RemoveOrganisationMutationResult = Apollo.MutationResult<RemoveOrganisationMutation>;
export type RemoveOrganisationMutationOptions = Apollo.BaseMutationOptions<RemoveOrganisationMutation, RemoveOrganisationMutationVariables>;
export const OrderChildDocument = gql`
    query OrderChild($orderId: String!) {
  orderChildren(orderId: $orderId) {
    ...OrderChildFields
  }
}
    ${OrderChildFieldsFragmentDoc}`;

/**
 * __useOrderChildQuery__
 *
 * To run a query within a React component, call `useOrderChildQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderChildQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderChildQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useOrderChildQuery(baseOptions: Apollo.QueryHookOptions<OrderChildQuery, OrderChildQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderChildQuery, OrderChildQueryVariables>(OrderChildDocument, options);
      }
export function useOrderChildLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderChildQuery, OrderChildQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderChildQuery, OrderChildQueryVariables>(OrderChildDocument, options);
        }
export type OrderChildQueryHookResult = ReturnType<typeof useOrderChildQuery>;
export type OrderChildLazyQueryHookResult = ReturnType<typeof useOrderChildLazyQuery>;
export type OrderChildQueryResult = Apollo.QueryResult<OrderChildQuery, OrderChildQueryVariables>;
export const CreateOrderChildDocument = gql`
    mutation CreateOrderChild($orderId: String!, $input: CreateOrderChildInput!) {
  createOrderChild(orderId: $orderId, createOrderChildInput: $input) {
    name
    age
    description
    orderId
  }
}
    `;
export type CreateOrderChildMutationFn = Apollo.MutationFunction<CreateOrderChildMutation, CreateOrderChildMutationVariables>;

/**
 * __useCreateOrderChildMutation__
 *
 * To run a mutation, you first call `useCreateOrderChildMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderChildMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderChildMutation, { data, loading, error }] = useCreateOrderChildMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrderChildMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrderChildMutation, CreateOrderChildMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrderChildMutation, CreateOrderChildMutationVariables>(CreateOrderChildDocument, options);
      }
export type CreateOrderChildMutationHookResult = ReturnType<typeof useCreateOrderChildMutation>;
export type CreateOrderChildMutationResult = Apollo.MutationResult<CreateOrderChildMutation>;
export type CreateOrderChildMutationOptions = Apollo.BaseMutationOptions<CreateOrderChildMutation, CreateOrderChildMutationVariables>;
export const UpdateOrderChildDocument = gql`
    mutation UpdateOrderChild($id: String!, $input: UpdateOrderChildInput!) {
  updateOrderChild(id: $id, updateOrderChildInput: $input) {
    name
    age
    description
    orderId
  }
}
    `;
export type UpdateOrderChildMutationFn = Apollo.MutationFunction<UpdateOrderChildMutation, UpdateOrderChildMutationVariables>;

/**
 * __useUpdateOrderChildMutation__
 *
 * To run a mutation, you first call `useUpdateOrderChildMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderChildMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderChildMutation, { data, loading, error }] = useUpdateOrderChildMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrderChildMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderChildMutation, UpdateOrderChildMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrderChildMutation, UpdateOrderChildMutationVariables>(UpdateOrderChildDocument, options);
      }
export type UpdateOrderChildMutationHookResult = ReturnType<typeof useUpdateOrderChildMutation>;
export type UpdateOrderChildMutationResult = Apollo.MutationResult<UpdateOrderChildMutation>;
export type UpdateOrderChildMutationOptions = Apollo.BaseMutationOptions<UpdateOrderChildMutation, UpdateOrderChildMutationVariables>;
export const RemoveOrderChildDocument = gql`
    mutation RemoveOrderChild($id: String!) {
  removeOrderChild(id: $id)
}
    `;
export type RemoveOrderChildMutationFn = Apollo.MutationFunction<RemoveOrderChildMutation, RemoveOrderChildMutationVariables>;

/**
 * __useRemoveOrderChildMutation__
 *
 * To run a mutation, you first call `useRemoveOrderChildMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOrderChildMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOrderChildMutation, { data, loading, error }] = useRemoveOrderChildMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveOrderChildMutation(baseOptions?: Apollo.MutationHookOptions<RemoveOrderChildMutation, RemoveOrderChildMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveOrderChildMutation, RemoveOrderChildMutationVariables>(RemoveOrderChildDocument, options);
      }
export type RemoveOrderChildMutationHookResult = ReturnType<typeof useRemoveOrderChildMutation>;
export type RemoveOrderChildMutationResult = Apollo.MutationResult<RemoveOrderChildMutation>;
export type RemoveOrderChildMutationOptions = Apollo.BaseMutationOptions<RemoveOrderChildMutation, RemoveOrderChildMutationVariables>;
export const RemoveOrderChildrenDocument = gql`
    mutation RemoveOrderChildren($orderId: String!) {
  removeOrderChildren(orderId: $orderId)
}
    `;
export type RemoveOrderChildrenMutationFn = Apollo.MutationFunction<RemoveOrderChildrenMutation, RemoveOrderChildrenMutationVariables>;

/**
 * __useRemoveOrderChildrenMutation__
 *
 * To run a mutation, you first call `useRemoveOrderChildrenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOrderChildrenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOrderChildrenMutation, { data, loading, error }] = useRemoveOrderChildrenMutation({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useRemoveOrderChildrenMutation(baseOptions?: Apollo.MutationHookOptions<RemoveOrderChildrenMutation, RemoveOrderChildrenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveOrderChildrenMutation, RemoveOrderChildrenMutationVariables>(RemoveOrderChildrenDocument, options);
      }
export type RemoveOrderChildrenMutationHookResult = ReturnType<typeof useRemoveOrderChildrenMutation>;
export type RemoveOrderChildrenMutationResult = Apollo.MutationResult<RemoveOrderChildrenMutation>;
export type RemoveOrderChildrenMutationOptions = Apollo.BaseMutationOptions<RemoveOrderChildrenMutation, RemoveOrderChildrenMutationVariables>;
export const OrdersDocument = gql`
    query Orders {
  orders {
    ...OrderFields
    orderChildren {
      id
      name
      age
      description
      position
    }
  }
}
    ${OrderFieldsFragmentDoc}`;

/**
 * __useOrdersQuery__
 *
 * To run a query within a React component, call `useOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrdersQuery(baseOptions?: Apollo.QueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
      }
export function useOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
        }
export type OrdersQueryHookResult = ReturnType<typeof useOrdersQuery>;
export type OrdersLazyQueryHookResult = ReturnType<typeof useOrdersLazyQuery>;
export type OrdersQueryResult = Apollo.QueryResult<OrdersQuery, OrdersQueryVariables>;
export const OrderDocument = gql`
    query Order($id: String!) {
  order(id: $id) {
    ...OrderFields
    orderChildren {
      name
      age
      description
      position
    }
  }
}
    ${OrderFieldsFragmentDoc}`;

/**
 * __useOrderQuery__
 *
 * To run a query within a React component, call `useOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderQuery(baseOptions: Apollo.QueryHookOptions<OrderQuery, OrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
      }
export function useOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderQuery, OrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
        }
export type OrderQueryHookResult = ReturnType<typeof useOrderQuery>;
export type OrderLazyQueryHookResult = ReturnType<typeof useOrderLazyQuery>;
export type OrderQueryResult = Apollo.QueryResult<OrderQuery, OrderQueryVariables>;
export const SantasOrdersDocument = gql`
    query SantasOrders($santaId: String) {
  santasOrders(santaId: $santaId) {
    ...OrderFields
    orderChildren {
      name
      age
      description
      position
    }
  }
}
    ${OrderFieldsFragmentDoc}`;

/**
 * __useSantasOrdersQuery__
 *
 * To run a query within a React component, call `useSantasOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSantasOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSantasOrdersQuery({
 *   variables: {
 *      santaId: // value for 'santaId'
 *   },
 * });
 */
export function useSantasOrdersQuery(baseOptions?: Apollo.QueryHookOptions<SantasOrdersQuery, SantasOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SantasOrdersQuery, SantasOrdersQueryVariables>(SantasOrdersDocument, options);
      }
export function useSantasOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SantasOrdersQuery, SantasOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SantasOrdersQuery, SantasOrdersQueryVariables>(SantasOrdersDocument, options);
        }
export type SantasOrdersQueryHookResult = ReturnType<typeof useSantasOrdersQuery>;
export type SantasOrdersLazyQueryHookResult = ReturnType<typeof useSantasOrdersLazyQuery>;
export type SantasOrdersQueryResult = Apollo.QueryResult<SantasOrdersQuery, SantasOrdersQueryVariables>;
export const SeasonOrdersDocument = gql`
    query SeasonOrders($seasonId: String!) {
  seasonOrders(seasonId: $seasonId) {
    ...OrderFields
    orderChildren {
      name
      age
      description
      position
    }
  }
}
    ${OrderFieldsFragmentDoc}`;

/**
 * __useSeasonOrdersQuery__
 *
 * To run a query within a React component, call `useSeasonOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeasonOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeasonOrdersQuery({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useSeasonOrdersQuery(baseOptions: Apollo.QueryHookOptions<SeasonOrdersQuery, SeasonOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SeasonOrdersQuery, SeasonOrdersQueryVariables>(SeasonOrdersDocument, options);
      }
export function useSeasonOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SeasonOrdersQuery, SeasonOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SeasonOrdersQuery, SeasonOrdersQueryVariables>(SeasonOrdersDocument, options);
        }
export type SeasonOrdersQueryHookResult = ReturnType<typeof useSeasonOrdersQuery>;
export type SeasonOrdersLazyQueryHookResult = ReturnType<typeof useSeasonOrdersLazyQuery>;
export type SeasonOrdersQueryResult = Apollo.QueryResult<SeasonOrdersQuery, SeasonOrdersQueryVariables>;
export const CreateOrderDocument = gql`
    mutation CreateOrder($input: OrderInput!, $childInput: CreateOrderChildInput!) {
  createOrder(orderInput: $input) {
    id
    streetAddress
    createOrderChildren(childInput: $childInput) {
      name
      age
      description
      position
    }
  }
}
    `;
export type CreateOrderMutationFn = Apollo.MutationFunction<CreateOrderMutation, CreateOrderMutationVariables>;

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *      childInput: // value for 'childInput'
 *   },
 * });
 */
export function useCreateOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrderMutation, CreateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(CreateOrderDocument, options);
      }
export type CreateOrderMutationHookResult = ReturnType<typeof useCreateOrderMutation>;
export type CreateOrderMutationResult = Apollo.MutationResult<CreateOrderMutation>;
export type CreateOrderMutationOptions = Apollo.BaseMutationOptions<CreateOrderMutation, CreateOrderMutationVariables>;
export const CreateBreakDocument = gql`
    mutation CreateBreak($input: OrderInput!) {
  createBreak(orderInput: $input) {
    id
  }
}
    `;
export type CreateBreakMutationFn = Apollo.MutationFunction<CreateBreakMutation, CreateBreakMutationVariables>;

/**
 * __useCreateBreakMutation__
 *
 * To run a mutation, you first call `useCreateBreakMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBreakMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBreakMutation, { data, loading, error }] = useCreateBreakMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBreakMutation(baseOptions?: Apollo.MutationHookOptions<CreateBreakMutation, CreateBreakMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBreakMutation, CreateBreakMutationVariables>(CreateBreakDocument, options);
      }
export type CreateBreakMutationHookResult = ReturnType<typeof useCreateBreakMutation>;
export type CreateBreakMutationResult = Apollo.MutationResult<CreateBreakMutation>;
export type CreateBreakMutationOptions = Apollo.BaseMutationOptions<CreateBreakMutation, CreateBreakMutationVariables>;
export const UpdateOrderDocument = gql`
    mutation UpdateOrder($id: String!, $updateInput: UpdateOrderInput!) {
  updateOrder(id: $id, updateInput: $updateInput) {
    ...OrderFields
    orderChildren {
      name
      age
      description
      position
    }
  }
}
    ${OrderFieldsFragmentDoc}`;
export type UpdateOrderMutationFn = Apollo.MutationFunction<UpdateOrderMutation, UpdateOrderMutationVariables>;

/**
 * __useUpdateOrderMutation__
 *
 * To run a mutation, you first call `useUpdateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderMutation, { data, loading, error }] = useUpdateOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updateInput: // value for 'updateInput'
 *   },
 * });
 */
export function useUpdateOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderMutation, UpdateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrderMutation, UpdateOrderMutationVariables>(UpdateOrderDocument, options);
      }
export type UpdateOrderMutationHookResult = ReturnType<typeof useUpdateOrderMutation>;
export type UpdateOrderMutationResult = Apollo.MutationResult<UpdateOrderMutation>;
export type UpdateOrderMutationOptions = Apollo.BaseMutationOptions<UpdateOrderMutation, UpdateOrderMutationVariables>;
export const RemoveOrderDocument = gql`
    mutation RemoveOrder($id: String!) {
  removeOrder(id: $id)
}
    `;
export type RemoveOrderMutationFn = Apollo.MutationFunction<RemoveOrderMutation, RemoveOrderMutationVariables>;

/**
 * __useRemoveOrderMutation__
 *
 * To run a mutation, you first call `useRemoveOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOrderMutation, { data, loading, error }] = useRemoveOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveOrderMutation(baseOptions?: Apollo.MutationHookOptions<RemoveOrderMutation, RemoveOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveOrderMutation, RemoveOrderMutationVariables>(RemoveOrderDocument, options);
      }
export type RemoveOrderMutationHookResult = ReturnType<typeof useRemoveOrderMutation>;
export type RemoveOrderMutationResult = Apollo.MutationResult<RemoveOrderMutation>;
export type RemoveOrderMutationOptions = Apollo.BaseMutationOptions<RemoveOrderMutation, RemoveOrderMutationVariables>;
export const SantaDocument = gql`
    query Santa($id: String) {
  santa(id: $id) {
    ...SantaFields
  }
}
    ${SantaFieldsFragmentDoc}`;

/**
 * __useSantaQuery__
 *
 * To run a query within a React component, call `useSantaQuery` and pass it any options that fit your needs.
 * When your component renders, `useSantaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSantaQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSantaQuery(baseOptions?: Apollo.QueryHookOptions<SantaQuery, SantaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SantaQuery, SantaQueryVariables>(SantaDocument, options);
      }
export function useSantaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SantaQuery, SantaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SantaQuery, SantaQueryVariables>(SantaDocument, options);
        }
export type SantaQueryHookResult = ReturnType<typeof useSantaQuery>;
export type SantaLazyQueryHookResult = ReturnType<typeof useSantaLazyQuery>;
export type SantaQueryResult = Apollo.QueryResult<SantaQuery, SantaQueryVariables>;
export const SantasDocument = gql`
    query Santas {
  santas {
    ...SantaFields
  }
}
    ${SantaFieldsFragmentDoc}`;

/**
 * __useSantasQuery__
 *
 * To run a query within a React component, call `useSantasQuery` and pass it any options that fit your needs.
 * When your component renders, `useSantasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSantasQuery({
 *   variables: {
 *   },
 * });
 */
export function useSantasQuery(baseOptions?: Apollo.QueryHookOptions<SantasQuery, SantasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SantasQuery, SantasQueryVariables>(SantasDocument, options);
      }
export function useSantasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SantasQuery, SantasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SantasQuery, SantasQueryVariables>(SantasDocument, options);
        }
export type SantasQueryHookResult = ReturnType<typeof useSantasQuery>;
export type SantasLazyQueryHookResult = ReturnType<typeof useSantasLazyQuery>;
export type SantasQueryResult = Apollo.QueryResult<SantasQuery, SantasQueryVariables>;
export const SeasonSantasDocument = gql`
    query SeasonSantas($seasonId: String!) {
  seasonSantas(seasonId: $seasonId) {
    ...SantaFields
  }
}
    ${SantaFieldsFragmentDoc}`;

/**
 * __useSeasonSantasQuery__
 *
 * To run a query within a React component, call `useSeasonSantasQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeasonSantasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeasonSantasQuery({
 *   variables: {
 *      seasonId: // value for 'seasonId'
 *   },
 * });
 */
export function useSeasonSantasQuery(baseOptions: Apollo.QueryHookOptions<SeasonSantasQuery, SeasonSantasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SeasonSantasQuery, SeasonSantasQueryVariables>(SeasonSantasDocument, options);
      }
export function useSeasonSantasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SeasonSantasQuery, SeasonSantasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SeasonSantasQuery, SeasonSantasQueryVariables>(SeasonSantasDocument, options);
        }
export type SeasonSantasQueryHookResult = ReturnType<typeof useSeasonSantasQuery>;
export type SeasonSantasLazyQueryHookResult = ReturnType<typeof useSeasonSantasLazyQuery>;
export type SeasonSantasQueryResult = Apollo.QueryResult<SeasonSantasQuery, SeasonSantasQueryVariables>;
export const CreateSantaDocument = gql`
    mutation CreateSanta($input: CreateSantaInput!) {
  createSanta(createSantaInput: $input) {
    seasonId
    name
    areaNameInternal
    areaNamePublic
    showOnFrontpage
    santaPhone
    santaEmail
    driverName
    driverPhone
    driverEmail
    position
  }
}
    `;
export type CreateSantaMutationFn = Apollo.MutationFunction<CreateSantaMutation, CreateSantaMutationVariables>;

/**
 * __useCreateSantaMutation__
 *
 * To run a mutation, you first call `useCreateSantaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSantaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSantaMutation, { data, loading, error }] = useCreateSantaMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSantaMutation(baseOptions?: Apollo.MutationHookOptions<CreateSantaMutation, CreateSantaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSantaMutation, CreateSantaMutationVariables>(CreateSantaDocument, options);
      }
export type CreateSantaMutationHookResult = ReturnType<typeof useCreateSantaMutation>;
export type CreateSantaMutationResult = Apollo.MutationResult<CreateSantaMutation>;
export type CreateSantaMutationOptions = Apollo.BaseMutationOptions<CreateSantaMutation, CreateSantaMutationVariables>;
export const RemoveSantaDocument = gql`
    mutation RemoveSanta($id: String!) {
  removeSanta(id: $id)
}
    `;
export type RemoveSantaMutationFn = Apollo.MutationFunction<RemoveSantaMutation, RemoveSantaMutationVariables>;

/**
 * __useRemoveSantaMutation__
 *
 * To run a mutation, you first call `useRemoveSantaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSantaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSantaMutation, { data, loading, error }] = useRemoveSantaMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveSantaMutation(baseOptions?: Apollo.MutationHookOptions<RemoveSantaMutation, RemoveSantaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveSantaMutation, RemoveSantaMutationVariables>(RemoveSantaDocument, options);
      }
export type RemoveSantaMutationHookResult = ReturnType<typeof useRemoveSantaMutation>;
export type RemoveSantaMutationResult = Apollo.MutationResult<RemoveSantaMutation>;
export type RemoveSantaMutationOptions = Apollo.BaseMutationOptions<RemoveSantaMutation, RemoveSantaMutationVariables>;
export const UpdateSantaDocument = gql`
    mutation UpdateSanta($id: String!, $updateSantaInput: UpdateSantaInput!) {
  updateSanta(id: $id, updateSantaInput: $updateSantaInput) {
    ...SantaFields
  }
}
    ${SantaFieldsFragmentDoc}`;
export type UpdateSantaMutationFn = Apollo.MutationFunction<UpdateSantaMutation, UpdateSantaMutationVariables>;

/**
 * __useUpdateSantaMutation__
 *
 * To run a mutation, you first call `useUpdateSantaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSantaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSantaMutation, { data, loading, error }] = useUpdateSantaMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updateSantaInput: // value for 'updateSantaInput'
 *   },
 * });
 */
export function useUpdateSantaMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSantaMutation, UpdateSantaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSantaMutation, UpdateSantaMutationVariables>(UpdateSantaDocument, options);
      }
export type UpdateSantaMutationHookResult = ReturnType<typeof useUpdateSantaMutation>;
export type UpdateSantaMutationResult = Apollo.MutationResult<UpdateSantaMutation>;
export type UpdateSantaMutationOptions = Apollo.BaseMutationOptions<UpdateSantaMutation, UpdateSantaMutationVariables>;
export const SeasonDocument = gql`
    query Season($id: String!) {
  season(id: $id) {
    ...SeasonFields
  }
}
    ${SeasonFieldsFragmentDoc}`;

/**
 * __useSeasonQuery__
 *
 * To run a query within a React component, call `useSeasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeasonQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSeasonQuery(baseOptions: Apollo.QueryHookOptions<SeasonQuery, SeasonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SeasonQuery, SeasonQueryVariables>(SeasonDocument, options);
      }
export function useSeasonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SeasonQuery, SeasonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SeasonQuery, SeasonQueryVariables>(SeasonDocument, options);
        }
export type SeasonQueryHookResult = ReturnType<typeof useSeasonQuery>;
export type SeasonLazyQueryHookResult = ReturnType<typeof useSeasonLazyQuery>;
export type SeasonQueryResult = Apollo.QueryResult<SeasonQuery, SeasonQueryVariables>;
export const SeasonsDocument = gql`
    query Seasons {
  seasons {
    ...SeasonFields
  }
}
    ${SeasonFieldsFragmentDoc}`;

/**
 * __useSeasonsQuery__
 *
 * To run a query within a React component, call `useSeasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeasonsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSeasonsQuery(baseOptions?: Apollo.QueryHookOptions<SeasonsQuery, SeasonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SeasonsQuery, SeasonsQueryVariables>(SeasonsDocument, options);
      }
export function useSeasonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SeasonsQuery, SeasonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SeasonsQuery, SeasonsQueryVariables>(SeasonsDocument, options);
        }
export type SeasonsQueryHookResult = ReturnType<typeof useSeasonsQuery>;
export type SeasonsLazyQueryHookResult = ReturnType<typeof useSeasonsLazyQuery>;
export type SeasonsQueryResult = Apollo.QueryResult<SeasonsQuery, SeasonsQueryVariables>;
export const OrganisationSeasonsDocument = gql`
    query OrganisationSeasons($organisationId: String!) {
  organisationSeasons(organisationId: $organisationId) {
    ...SeasonFields
  }
}
    ${SeasonFieldsFragmentDoc}`;

/**
 * __useOrganisationSeasonsQuery__
 *
 * To run a query within a React component, call `useOrganisationSeasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationSeasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationSeasonsQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *   },
 * });
 */
export function useOrganisationSeasonsQuery(baseOptions: Apollo.QueryHookOptions<OrganisationSeasonsQuery, OrganisationSeasonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganisationSeasonsQuery, OrganisationSeasonsQueryVariables>(OrganisationSeasonsDocument, options);
      }
export function useOrganisationSeasonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganisationSeasonsQuery, OrganisationSeasonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganisationSeasonsQuery, OrganisationSeasonsQueryVariables>(OrganisationSeasonsDocument, options);
        }
export type OrganisationSeasonsQueryHookResult = ReturnType<typeof useOrganisationSeasonsQuery>;
export type OrganisationSeasonsLazyQueryHookResult = ReturnType<typeof useOrganisationSeasonsLazyQuery>;
export type OrganisationSeasonsQueryResult = Apollo.QueryResult<OrganisationSeasonsQuery, OrganisationSeasonsQueryVariables>;
export const OpenSeasonDocument = gql`
    query OpenSeason($organisationId: String!) {
  openSeason(organisationId: $organisationId) {
    ...SeasonFields
  }
}
    ${SeasonFieldsFragmentDoc}`;

/**
 * __useOpenSeasonQuery__
 *
 * To run a query within a React component, call `useOpenSeasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpenSeasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpenSeasonQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *   },
 * });
 */
export function useOpenSeasonQuery(baseOptions: Apollo.QueryHookOptions<OpenSeasonQuery, OpenSeasonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OpenSeasonQuery, OpenSeasonQueryVariables>(OpenSeasonDocument, options);
      }
export function useOpenSeasonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OpenSeasonQuery, OpenSeasonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OpenSeasonQuery, OpenSeasonQueryVariables>(OpenSeasonDocument, options);
        }
export type OpenSeasonQueryHookResult = ReturnType<typeof useOpenSeasonQuery>;
export type OpenSeasonLazyQueryHookResult = ReturnType<typeof useOpenSeasonLazyQuery>;
export type OpenSeasonQueryResult = Apollo.QueryResult<OpenSeasonQuery, OpenSeasonQueryVariables>;
export const CreateSeasonDocument = gql`
    mutation CreateSeason($input: CreateSeasonInput!) {
  createSeason(createSeasonInput: $input) {
    organisationId
    year
    price
    duration
    interval
    christmasStatus
    minStartTime
    maxStartTime
  }
}
    `;
export type CreateSeasonMutationFn = Apollo.MutationFunction<CreateSeasonMutation, CreateSeasonMutationVariables>;

/**
 * __useCreateSeasonMutation__
 *
 * To run a mutation, you first call `useCreateSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSeasonMutation, { data, loading, error }] = useCreateSeasonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSeasonMutation(baseOptions?: Apollo.MutationHookOptions<CreateSeasonMutation, CreateSeasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSeasonMutation, CreateSeasonMutationVariables>(CreateSeasonDocument, options);
      }
export type CreateSeasonMutationHookResult = ReturnType<typeof useCreateSeasonMutation>;
export type CreateSeasonMutationResult = Apollo.MutationResult<CreateSeasonMutation>;
export type CreateSeasonMutationOptions = Apollo.BaseMutationOptions<CreateSeasonMutation, CreateSeasonMutationVariables>;
export const UpdateSeasonDocument = gql`
    mutation UpdateSeason($id: String!, $updateSeasonInput: UpdateSeasonInput!) {
  updateSeason(id: $id, updateSeasonInput: $updateSeasonInput) {
    ...SeasonFields
  }
}
    ${SeasonFieldsFragmentDoc}`;
export type UpdateSeasonMutationFn = Apollo.MutationFunction<UpdateSeasonMutation, UpdateSeasonMutationVariables>;

/**
 * __useUpdateSeasonMutation__
 *
 * To run a mutation, you first call `useUpdateSeasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSeasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSeasonMutation, { data, loading, error }] = useUpdateSeasonMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updateSeasonInput: // value for 'updateSeasonInput'
 *   },
 * });
 */
export function useUpdateSeasonMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSeasonMutation, UpdateSeasonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSeasonMutation, UpdateSeasonMutationVariables>(UpdateSeasonDocument, options);
      }
export type UpdateSeasonMutationHookResult = ReturnType<typeof useUpdateSeasonMutation>;
export type UpdateSeasonMutationResult = Apollo.MutationResult<UpdateSeasonMutation>;
export type UpdateSeasonMutationOptions = Apollo.BaseMutationOptions<UpdateSeasonMutation, UpdateSeasonMutationVariables>;