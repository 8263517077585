/* eslint-disable camelcase */
import { FormikValues } from 'formik';
import {
  ChristmasStatus,
  CreateOrganisationMutationVariables,
  CreateSantaMutationVariables,
  CreateSeasonMutationVariables,
  CreateUserMutationVariables,
  UpdateOrganisationMutationVariables,
  UpdateSantaMutationVariables,
  UpdateSeasonMutationVariables,
  UpdateUserMutationVariables,
  UserEntity,
} from '../../../graphql/generated';

export const genSeasonVariables = (
  values: FormikValues,
  organisationId: string | undefined
): { variables: CreateSeasonMutationVariables } => ({
  variables: {
    input: {
      organisationId: organisationId,
      year: Number(values['year']),
      price: Number(values['price']),
      duration: Number(values['duration']),
      interval: Number(values['interval']),
      christmasStatus: values['christmasStatus'] || ChristmasStatus.Wait,
      minStartTime: values['minStartTime'],
      maxStartTime: values['maxStartTime'],
    },
  },
});

export const genSantaVariables = (
  values: FormikValues,
  seasonId: string | undefined,
  orgUsers: UserEntity[] | undefined
): { variables: CreateSantaMutationVariables } => ({
  variables: {
    input: {
      seasonId: seasonId,
      name: values['name'],
      santaPhone: values['santaPhone'],
      santaEmail: values['santaEmail'] || null,
      position: Number(values['position']),
      startTime: values['startTime'],
      endTime: values['endTime'],
      areaNameInternal: values['areaNameInternal'],
      areaNamePublic: values['areaNamePublic'],
      showOnFrontpage: values['showOnFrontPage'],
      driverName: values['driverName'],
      driverPhone: values['driverPhone'],
      driverEmail: values['driverEmail'] || null,
      userId: orgUsers?.find(user => user.email === values['userEmail'])?.id || null,
    },
  },
});

export const genSeasonEditVariables = (
  values: FormikValues,
  seasonId: string | undefined
): { variables: UpdateSeasonMutationVariables } => ({
  variables: {
    id: seasonId || '',
    updateSeasonInput: {
      year: Number(values['year']),
      price: Number(values['price']),
      duration: Number(values['duration']),
      interval: Number(values['interval']),
      christmasStatus: values['christmasStatus'],
      minStartTime: values['minStartTime'],
      maxStartTime: values['maxStartTime'],
    },
  },
});

export const genSantaEditVariables = (
  values: FormikValues,
  santaId: string | undefined,
  seasonId: string | undefined,
  orgUsers: UserEntity[] | undefined
): { variables: UpdateSantaMutationVariables } => ({
  variables: {
    id: santaId || '',
    updateSantaInput: {
      name: values['name'],
      santaPhone: values['santaPhone'],
      santaEmail: values['santaEmail'] || null,
      position: Number(values['position']),
      startTime: values['startTime'],
      endTime: values['endTime'],
      areaNameInternal: values['areaNameInternal'],
      areaNamePublic: values['areaNamePublic'],
      showOnFrontpage: values['showOnFrontPage'],
      driverName: values['driverName'],
      driverPhone: values['driverPhone'],
      driverEmail: values['driverEmail'] || null,
      seasonId: seasonId || '',
      userId: orgUsers?.find(user => user.email === values['userEmail'])?.id || null,
    },
  },
});

export const genOrganisationVariables = (
  values: FormikValues
): { variables: CreateOrganisationMutationVariables } => ({
  variables: {
    input: {
      name: values['name'],
      email: values['email'],
      address: values['address'] || null,
      phone: values['phone'] || null,
      websiteURL: values['websiteURL'] || null,
      vatId: values['vatId'] || null,
      frontpageTitle: values['frontpageTitle'],
      frontpageSubtitle: values['frontpageSubtitle'],
      frontpageDescription: values['frontpageDescription'],
      logoFile: values['logoFile'] || null,
    },
    adminInput: {
      email: values['email'],
      family_name: values['familyName'],
      given_name: values['givenName'],
      phoneNumber: values['phoneNumber'],
    },
  },
});

export const genOrganisationEditVariables = (
  values: FormikValues,
  organisationId: string
): { variables: UpdateOrganisationMutationVariables } => ({
  variables: {
    id: organisationId,
    updateOrg: {
      name: values['name'],
      email: values['email'],
      address: values['address'] || null,
      phone: values['phone'] || null,
      websiteURL: values['websiteURL'] || null,
      vatId: values['vatId'] || null,
      frontpageTitle: values['frontpageTitle'],
      frontpageSubtitle: values['frontpageSubtitle'],
      frontpageDescription: values['frontpageDescription'],
      logoFile: values['logoFile'] || null,
    },
    updateOrgAdmin: {
      email: values['adminEmail'],
      family_name: values['adminFamilyName'],
      given_name: values['adminGivenName'],
      phoneNumber: values['adminPhoneNumber'],
    },
  },
});

export const genUserVariables = (
  values: FormikValues,
  organisationId: string
): { variables: CreateUserMutationVariables } => ({
  variables: {
    createUserInput: {
      organisationId: organisationId,
      email: values['email'],
      given_name: values['givenName'],
      family_name: values['familyName'],
      phoneNumber: values['phone'],
      roles: values['roles'],
    },
  },
});

export const genUserEditVariables = (
  values: FormikValues,
  userId?: string
): { variables: UpdateUserMutationVariables } => ({
  variables: {
    id: userId || '',
    updateUserInput: {
      given_name: values['givenName'],
      family_name: values['familyName'],
      phoneNumber: values['phone'],
      roles: values['roles'],
    },
  },
});
