import React, { useContext } from 'react';
import styled from 'styled-components';

import { COLORS } from '../../styles/colors';
import { FONTSIZE } from '../../styles/fontsize';
import Footer from '../../components/common/Footer';
import { ReactComponent as LightsImage } from '../../images/lights.svg';
import FirstBox from './components/FirstBox';
import SecondBox from './components/SecondBox';
import ThirdBox from './components/ThirdBox';
import FourthBox from './components/FourthBox';
import CarWithGift from '../../images/carWithGift.svg';
import { OrganisationSeasonContext } from '../../App';
import { Navigate, Outlet } from 'react-router-dom';
import { CommonBoxHeader, CommonImageContainer } from './components/HomepageStyles';

const Home: React.FC = () => {
  const { organisation, openSeason } = useContext(OrganisationSeasonContext);

  if (!organisation) {
    return <Navigate to="/" replace={true} />;
  }

  return (
    <>
      <HomePageContainer>
        <HomePageTopContainer>
          <HomePageHeaderContainer>
            <HomePageHeader>{organisation.frontpageTitle}</HomePageHeader>
            <HomePageSubtitle>{organisation.frontpageSubtitle}</HomePageSubtitle>
          </HomePageHeaderContainer>
          <HomePageImage>
            <LightsImage />
          </HomePageImage>
        </HomePageTopContainer>
        <FirstBox />
        <SecondBox />
        {openSeason && <ThirdBox />}
        <FourthBox />
        <ImageContainer>
          <img src={CarWithGift} width={'14%'} />
        </ImageContainer>
        <Outlet />
      </HomePageContainer>
      <Footer />
    </>
  );
};

const HomePageContainer = styled.div`
  width: 100%;
  background-color: ${COLORS.green2};
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 10% 0 10%;
  @media (max-width: 768px) {
    padding: 0;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`;

const HomePageTopContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

const HomePageHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  height: fit-content;
  @media (max-width: 768px) {
    margin-left: 25px;
    margin-right: 25px;
  }
  margin-top: 5%;
`;

const HomePageHeader = styled(CommonBoxHeader)`
  flex: 5;
  color: ${COLORS.white};
  letter-spacing: 2.4px;
  text-transform: uppercase;
  align-items: end;
  margin-left: -5px;
  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

const HomePageSubtitle = styled(CommonBoxHeader)`
  flex: 1;
  color: ${COLORS.white};
  letter-spacing: 0.36px;
  font-size: ${FONTSIZE.subheading};
`;

const HomePageImage = styled(CommonImageContainer)`
  display: block;
  @media (max-width: 768px) {
    margin-left: 10%;
  }
  margin-top: 0;
  margin-right: -15%;
`;

const ImageContainer = styled(CommonImageContainer)`
  @media (min-width: 768px) {
    margin-bottom: 10px;
    margin-top: 150px;
    justify-content: flex-start;
  }
`;

export default Home;
