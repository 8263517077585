import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../styles/colors';
import { FONTS } from '../../styles/fonts';
import { WEIGHTS } from '../../styles/weights';

const StyledAlertBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
`;

const StyledAlertBox = styled.div`
  height: 180px;
  width: 400px;
  border: 2px solid ${COLORS.grey};
  border-radius: 20px;
  background-color: ${COLORS.white};
  box-shadow: 0px 3px 6px #00000029;
  padding: 20px;
`;

const StyledAlertRow = styled.div<{ height: number }>`
  display: flex;
  justify-content: left;
  height: ${props => props.height}px;
  padding: 10px;
`;

const StyledAlertText = styled.p<{ font: string; weight: number; size: number }>`
  font-size: ${props => props.size}px;
  font-family: ${props => props.font};
  font-weight: ${props => props.weight};
  color: ${COLORS.brightGreen};
  margin-left: 10px;
  margin-bottom: 20px;
`;

interface Props {
  time: number;
}

const AlertDialog: React.FC<Props> = ({ time }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'sessionExpiredDialog' });
  return (
    <StyledAlertBackground>
      <StyledAlertBox>
        <StyledAlertRow height={50}>
          <StyledAlertText font={FONTS.Lora} weight={WEIGHTS.medium} size={16}>
            {t('sessionExpired')}
          </StyledAlertText>
        </StyledAlertRow>
        <StyledAlertRow height={60}>
          <StyledAlertText font={FONTS.Karla} weight={WEIGHTS.regular} size={14}>
            {t('loginInfo', { param: time })}
          </StyledAlertText>
        </StyledAlertRow>
      </StyledAlertBox>
    </StyledAlertBackground>
  );
};

export default AlertDialog;
