import { Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OrderContext } from '../../pages/mapView';
import { Order } from '../../graphql/generated';
import StyledCardColumn from '../common/StyledCardColumn';
import StyledCardRow from '../common/StyledCardRow';
import DropDown from '../common/DropDown';
import OrderCard from './OrderCard';
import StyledCardText from '../common/StyledCardText';
import { FONTS } from '../../styles/fonts';
import { WEIGHTS } from '../../styles/weights';
import { COLORS } from '../../styles/colors';

const sortOptions = [
  { text: 'oldestOrderFirst', key: 'createdAt' },
  { text: 'earliestTimeWishFirst', key: 'wishEarliest' },
  { text: 'nameAsc', key: 'customerName' },
  { text: 'addressAsc', key: 'streetAddress' },
];

interface Props {
  setFormOpen: Dispatch<SetStateAction<boolean>>;
  setChangeStatusWindowOpen: Dispatch<SetStateAction<boolean>>;
  setTypeOfChange: Dispatch<SetStateAction<string>>;
  setShowAlert: Dispatch<SetStateAction<boolean>>;
  setShowDialog: Dispatch<SetStateAction<boolean>>;
}

const OrderCardList: React.FC<Props> = ({
  setFormOpen,
  setChangeStatusWindowOpen,
  setTypeOfChange,
  setShowAlert,
  setShowDialog,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'orderCards' });

  // TODO: use selectedOrder & setSelectedOrder
  const { orders, filters } = useContext(OrderContext);

  const [sortBy, setSortBy] = useState(sortOptions[0]);

  const sortHandler = (data: Order[] | undefined, key: string) => {
    if (data !== undefined) {
      return data
        .slice()
        .sort((a, b) =>
          a[key].toUpperCase().localeCompare(b[key].toUpperCase(), 'fi', { sensitivity: 'base' })
        );
    } else {
      return [];
    }
  };

  const sortedData = useMemo(
    () =>
      sortHandler(
        filters.includes('ALL')
          ? orders
          : orders.filter(order => filters.includes(order.orderStatus!)),
        sortBy.key
      ),
    [orders, filters, sortBy]
  );

  if (orders.length <= 0) {
    return (
      <div style={{ marginTop: '15px' }}>
        <StyledCardText
          display={'inline-block'}
          font={FONTS.Karla}
          weight={WEIGHTS.regular}
          size={18}
          color={COLORS.brightGreen}>
          {t('noData')}
        </StyledCardText>
      </div>
    );
  }

  const dropDownHandler = (sortText: string, sortKey: string) => {
    setSortBy({ text: sortText, key: sortKey });
  };

  return (
    <div>
      <StyledCardRow>
        <StyledCardColumn size={1}>
          <DropDown
            defaultValue={sortBy.text}
            optionArray={sortOptions}
            onArrangeChange={dropDownHandler}
          />
        </StyledCardColumn>
      </StyledCardRow>
      {sortedData.map((order, index) => (
        <OrderCard
          key={index}
          order={order}
          index={index}
          setFormOpen={setFormOpen}
          setChangeStatusWindowOpen={setChangeStatusWindowOpen}
          setTypeOfChange={setTypeOfChange}
          setShowAlert={setShowAlert}
          setShowDialog={setShowDialog}
        />
      ))}
    </div>
  );
};

export default OrderCardList;
