import DropDown from '../common/DropDown';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { COLORS } from '../../styles/colors';
import { useState } from 'react';
import { Order, Santa, SantaSchedule, Season } from '../../graphql/generated';
import { saveSantasSchedulePdf } from '../utilities/saveSantasSchedulePdf';
import { saveSantasOrdersPdf } from '../utilities/saveSantasOrdersPdf';
import { useTranslation } from 'react-i18next';
const MAP_TOKEN = process.env.REACT_APP_MAP_TOKEN;

interface TableHeaderProps {
  santas: Santa[];
  season: Season;
  santasSlots: SantaSchedule[];
  santasSchedule: (santa: Santa) => {
    timeSlot: string;
    santa: Santa;
    order: Order;
  }[];
}

const TableHeader: React.FC<TableHeaderProps> = ({
  santas,
  season,
  santasSlots,
  santasSchedule,
}) => {
  const [selectedSanta, setSelectedSanta] = useState<Santa>({} as Santa);
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard' });

  const printEventHandler = (_text: string, key: string) => {
    switch (key) {
      case 'printSchedule': {
        const slot = santasSlots.find(slot => slot.id === selectedSanta.id);
        saveSantasSchedulePdf(
          `${t('season')} ${season.year}, ${selectedSanta.name}`,
          selectedSanta.areaNamePublic,
          `${t('orders')}: ${slot?.orders} | ${t('freeSlots')}: ${
            slot ? slot.totalSlots - slot.breaks - slot.orders : 0
          }`,
          santasSchedule(selectedSanta).map(({ timeSlot, order }) => ({
            time: timeSlot,
            order,
          })),
          `pukkipalvelu_schedule_${selectedSanta.name}`
        );
        break;
      }

      case 'printAll': {
        saveSantasOrdersPdf(
          santasSchedule(selectedSanta).flatMap(({ order }) =>
            order
              ? {
                  ...order,
                  map: `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-l+306e57(${
                    order.lonlat?.split(',')[0]
                  },${order.lonlat?.split(',')[1]})/${order.lonlat?.split(',')[0]},${
                    order.lonlat?.split(',')[1]
                  },16/900x270?access_token=${MAP_TOKEN}`,
                }
              : []
          ),
          {
            name: selectedSanta.name,
            area: selectedSanta.areaNamePublic,
          },
          `pukkipalvelu_allOrders_${selectedSanta.name}`
        );
        break;
      }
      default:
        console.log('Something went wrong. Dropdown option is not implemented.', key);
        break;
    }
  };
  return (
    <thead>
      <tr>
        <th className="cornerCell"></th>
        {santas?.map(santa => (
          <th className="santaCell" key={santa.id} onClick={() => setSelectedSanta(santa)}>
            <div className="dropdown-wrapper-santa">
              <DropDown
                optionArray={[
                  { text: 'PrintSchedule', key: 'printSchedule' },
                  { text: 'PrintAll', key: 'printAll' },
                ]}
                onArrangeChange={printEventHandler}
                defaultIcon={
                  <MoreHorizOutlinedIcon
                    sx={{ color: COLORS.yellow, fontSize: 25 }}
                    onClick={() => setSelectedSanta(santa)}
                  />
                }
              />
            </div>
            <p>{santa.name}</p>
            <p className="areaText">
              {`${t('area')}: ${santa.areaNameInternal || santa.areaNamePublic}`}
            </p>
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHeader;
