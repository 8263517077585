import { useTranslation } from 'react-i18next';
import { Santa, SantaSchedule } from '../../graphql/generated';

interface OverViewRowProps {
  santas: Santa[];
  slots: SantaSchedule[];
}

const OverviewRow: React.FC<OverViewRowProps> = ({ santas, slots }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard' });

  return (
    <tr className="overviewRow">
      <td></td>
      {santas?.map(santa => {
        const slot = slots.find(slot => slot.id === santa.id);
        return (
          <td key={santa.id}>
            <p>
              {t('freeSlots')}: {slot ? slot.totalSlots - slot.breaks - slot.orders : 0}
            </p>
            <p>
              {t('reservedSlots')}: {slot?.orders}
            </p>
          </td>
        );
      })}
    </tr>
  );
};

export default OverviewRow;
