import styled from 'styled-components';

import { COLORS } from '../../styles/colors';
import { FONTS } from '../../styles/fonts';
import { WEIGHTS } from '../../styles/weights';

const NoDataContainer = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${COLORS.green2};
  color: ${COLORS.white};
  font-family: ${FONTS.Lora};
  font-weight: ${WEIGHTS.medium};
  font-size: 20px;
  padding: 50px;
`;

export default NoDataContainer;
