import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { Dispatch, SetStateAction, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { OrganisationSeasonContext } from '../../App';
import DropDown, { StyledDropdownContainer } from '../common/DropDown';
import { StyledInfoCard, StyledInfoCardHeader } from './StyledInfoCard';

const StyledDropDownWrapper = styled.div`
  ${StyledDropdownContainer} {
    position: relative;
  }
`;

interface OrganisationCardProps {
  setTypeCreate: Dispatch<SetStateAction<string>>;
  setFormOpen: Dispatch<SetStateAction<boolean>>;
}

const OrganisationCard: React.FC<OrganisationCardProps> = ({ setTypeCreate, setFormOpen }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin' });
  const { organisation } = useContext(OrganisationSeasonContext);

  const organisationOptions = [{ text: 'Edit', key: 'edit' }];

  const organisationFunctionHandler = (text: string, key: string) => {
    if (key === 'edit') {
      setTypeCreate('organisationEdit');
      setFormOpen(true);
    }
  };

  return (
    <StyledInfoCard>
      <StyledInfoCardHeader>
        <h1>{t('organisation')}</h1>
        <StyledDropDownWrapper>
          <DropDown
            optionArray={organisationOptions}
            onArrangeChange={organisationFunctionHandler}
            defaultIcon={<MoreHorizOutlinedIcon />}
          />
        </StyledDropDownWrapper>
      </StyledInfoCardHeader>
      <p>{organisation.name}</p>
      <p>
        {t('address')}: {organisation.address || '-'}
      </p>
      <p>
        {t('vatId')}: {organisation.vatId || '-'}
      </p>
      <p>
        {t('phone')}: {organisation.phone || '-'}
      </p>
      <p>
        {t('email')}: {organisation.email}
      </p>
    </StyledInfoCard>
  );
};

export default OrganisationCard;
