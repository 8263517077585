import moment from 'moment';
import { Order, OrderChild, OrderInput, Santa } from '../graphql/generated';
import { orderStatus } from '../types/order';

export const findOrdersByStatus = (
  orders: Order[],
  status: orderStatus | orderStatus[]
): Order[] => {
  // Convert the single status argument to an array if necessary
  const statusArray = Array.isArray(status) ? status : [status];
  // Filter the orders based on the specified status(es)
  return orders.filter(order => statusArray.includes(order.orderStatus as orderStatus));
};

export const findOrdersBySanta = (orders: Order[], santaId: Santa['id']): Order[] =>
  filterDeleted(orders).filter(order => order.santaId === santaId);

export const findOrderAtTimeSlot = (orders: Order[], time: string, santa: Santa['id']): Order => {
  const order = filterDeleted(orders).find(
    order =>
      order.santaId === santa &&
      moment(order.confirmedStartAt, 'HH:mm:ss').isSame(moment(time, 'HH:mm'))
  );

  return order as Order;
};

const filterDeleted = (orders: Order[]) =>
  orders.filter(order => order.orderStatus !== orderStatus.deleted);

export const updateRecord = <T extends Record<string, unknown>>(
  record: T,
  updates: Partial<T>
): T => ({
  ...record,
  ...updates,
});

export const createOrder = () => createEmptyOrder();

export const createBreak = (santa: Santa, timeSlot: string, label: string) => {
  const { santaPhone, seasonId, id } = santa;
  const emptyOrder = createEmptyOrderInput();
  return updateRecord(emptyOrder, {
    customerName: santa.name,
    phone: santaPhone,
    visitName: label,
    seasonId,
    santaId: id,
    confirmedStartAt: timeSlot,
    email: 'tauko@mail.com',
    orderStatus: orderStatus.closed,
  });
};

const createEmptyOrder = (): Order =>
  ({
    city: ' ',
    confirmedStartAt: ' ',
    createOrderChildren: {} as OrderChild,
    createdAt: ' ',
    customerName: ' ',
    doorCode: ' ',
    duration: 0,
    email: ' ',
    giftInfo: ' ',
    id: ' ',
    language: ' ',
    lonlat: ' ',
    meetOutside: false,
    newsletterIsSubscribed: false,
    orderChildren: [],
    orderStatus: ' ',
    otherInfo: ' ',
    phone: ' ',
    postalCode: ' ',
    price: 0,
    routeInfo: ' ',
    santaId: ' ',
    seasonId: ' ',
    streetAddress: ' ',
    visitName: ' ',
    wishEarliest: '00:00',
    wishLatest: '00:00',
  } as Order);

const createEmptyOrderInput = () =>
  ({
    city: ' ',
    confirmedStartAt: ' ',
    customerName: ' ',
    doorCode: ' ',
    duration: 0,
    email: ' ',
    giftInfo: ' ',
    language: ' ',
    lonlat: ' ',
    meetOutside: false,
    newsletterIsSubscribed: false,
    orderStatus: ' ',
    otherInfo: ' ',
    phone: ' ',
    postalCode: ' ',
    price: 0,
    routeInfo: ' ',
    santaId: ' ',
    seasonId: ' ',
    streetAddress: ' ',
    visitName: ' ',
    wishEarliest: '00:00',
    wishLatest: '00:00',
  } as OrderInput);
