import styled from 'styled-components';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../styles/colors';
import { FONTS } from '../../styles/fonts';
import { WEIGHTS } from '../../styles/weights';

const StyledAlertBox = styled.div<{ center: boolean }>`
  position: fixed;
  top: 60px;
  right: ${props => (props.center ? 'calc(100% / 2 - 225px)' : '20px')};
  height: 60px;
  width: 450px;
  border: 2px solid ${props => props.color};
  background-color: ${COLORS.white};
  display: flex;
  z-index: 2;
`;

const StyledAlertText = styled.p`
  font-size: 12px;
  font-family: ${FONTS.Karla};
  font-weight: ${WEIGHTS.regular};
  color: ${COLORS.brightGreen};
`;

const StyledAlertColumn = styled.div<{ size: number; place: string }>`
  flex: ${props => props.size};
  display: flex;
  align-items: ${props => props.place};
`;

interface Props {
  alertType: string;
  alertText: string;
  close: () => void;
  center?: boolean;
}

const AlertBox: React.FC<Props> = ({ alertType, alertText, close, center = false }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'alertSnackbar' });

  const borderColors = {
    error: COLORS.red,
    success: COLORS.brightGreen2,
    info: COLORS.mediumGrey,
    warning: COLORS.darkYellow,
  };

  const iconColors = {
    error: COLORS.red,
    success: COLORS.brightGreen2,
    info: COLORS.green2,
    warning: COLORS.darkYellow,
  };

  const iconStyle = { color: iconColors[alertType], fontSize: 20, m: 2 };

  const icons = {
    error: <ErrorIcon sx={iconStyle} />,
    success: <CheckCircleIcon sx={iconStyle} />,
    info: <InfoIcon sx={iconStyle} />,
    warning: <WarningIcon sx={iconStyle} />,
  };

  return (
    <StyledAlertBox color={borderColors[alertType]} center={center}>
      <StyledAlertColumn size={13} place={'center'}>
        {icons[alertType]}
        <StyledAlertText>{t(alertText)}</StyledAlertText>
      </StyledAlertColumn>
      <StyledAlertColumn size={1} place={'top'}>
        <CloseOutlinedIcon
          style={{ fill: iconColors[alertType] }}
          sx={{ fontSize: 18, m: 0.5 }}
          onClick={close}
        />
      </StyledAlertColumn>
    </StyledAlertBox>
  );
};

export default AlertBox;
