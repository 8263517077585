import mapboxgl from 'mapbox-gl';
// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

import 'mapbox-gl/dist/mapbox-gl.css';
import React, { Dispatch, FormEvent, SetStateAction, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Map, { Marker, Popup } from 'react-map-gl';
import { Checkbox, CheckboxProps, Dropdown, DropdownItem, DropdownMenu } from 'semantic-ui-react';
import styled from 'styled-components';

import { Order } from '../../graphql/generated';
import { OrderContext } from '../../pages/mapView';
import { SantasOrderContext } from '../../pages/santasView';
import { COLORS } from '../../styles/colors';
import { FONTS } from '../../styles/fonts';
import { WEIGHTS } from '../../styles/weights';
import ContactInfoCard from '../mapView/ContactInfoCard';
import './checkbox.css';
import Pin from './Pin';

const MAP_TOKEN = process.env.REACT_APP_MAP_TOKEN;

const StyledDropdown = styled(Dropdown)`
  font-family: ${FONTS.Karla} !important;
  font-size: 13px;
  font-weight: ${WEIGHTS.regular};
  color: ${COLORS.brightGreen} !important;
`;

const MapWrapper = styled.div`
  position: sticky;
  top: 45px;
  height: calc(100vh - 45px);
`;

const DropDownWrapper = styled.div`
  position: absolute;
  background-color: ${COLORS.white};
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin: 18px;
  border-radius: 25px;
`;

interface OrderMarkerProps {
  order: Order;
  onClick: (order: Order) => void;
}

export const OrderMarker: React.FC<OrderMarkerProps> = ({ order, onClick }) => {
  const coordinates = parsePosition(order.lonlat);
  return (
    <Marker
      longitude={coordinates.longitude}
      latitude={coordinates.latitude}
      anchor="bottom"
      onClick={e => {
        // If we let the click event propagate to the map, it will immediately close the popup with `closeOnClick: true`
        e.originalEvent.stopPropagation();
        onClick(order);
      }}>
      <Pin order={order} />
    </Marker>
  );
};

// placeholder until proper position types are implemented
const parsePosition = (lonlat: string) => {
  const comma = lonlat.indexOf(',');
  return {
    longitude: parseFloat(lonlat.substring(0, comma)),
    latitude: parseFloat(lonlat.substring(comma + 1)),
  };
};

interface MapComponentProps {
  setFormOpen?: Dispatch<SetStateAction<boolean>>;
  setShowDialog?: Dispatch<SetStateAction<boolean>>;
  setChangeStatusWindowOpen?: Dispatch<SetStateAction<boolean>>;
  setTypeOfChange?: Dispatch<SetStateAction<string>>;
  santaView?: boolean;
}

export const MapComponent: React.FC<MapComponentProps> = ({
  setFormOpen,
  setShowDialog,
  setChangeStatusWindowOpen,
  setTypeOfChange,
  santaView,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'filterOrders' });

  const { orders, selectedOrder, setSelectedOrder, filterOptions, filters, setFilters } =
    useContext(OrderContext);

  const { santaOrders, setSelectedSantaOrder } = useContext(SantasOrderContext);

  const filteredOrders = () => {
    if (santaView && santaOrders !== undefined) {
      return santaOrders;
    }
    return filters.includes('ALL')
      ? orders
      : orders.filter(order => filters.includes(order.orderStatus!));
  };

  const toggleSelection = (e: FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    const { id, checked } = data;

    if (id === 'ALL') {
      checked ? setFilters(filterOptions.map(option => option.key)) : setFilters([]);
    } else if (filters.includes('ALL')) {
      setFilters(filters.filter(e => e !== 'ALL' && e !== id));
    } else {
      if (checked) {
        setFilters([...filters, filterOptions[filterOptions.findIndex(e => e.key === id)].key]);
      } else {
        setFilters(filters.filter(e => e !== id));
      }
    }
  };

  const [map, setMap] = useState<mapboxgl.Map>();

  useEffect(() => {
    const moveMap = (coordinates: { longitude: number; latitude: number }) => {
      map!.flyTo({
        center: [coordinates.longitude, coordinates.latitude],
        duration: 2000,
        zoom: 14,
      });
    };

    if (map && selectedOrder) {
      moveMap(parsePosition(selectedOrder!.lonlat!));
    }
  }, [map, selectedOrder]);

  useEffect(() => {
    const selectedOrders = santaOrders?.length ? santaOrders : orders?.length ? orders : [];

    if (selectedOrders.length > 0) {
      const coordinates = selectedOrders.map(
        order =>
          new mapboxgl.LngLat(
            parsePosition(order.lonlat).longitude,
            parsePosition(order.lonlat).latitude
          )
      );

      // Create a 'LngLatBounds' with both corners at the first coordinate.
      const bounds = new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]);

      // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
      for (const coord of coordinates) {
        bounds.extend(coord);
      }

      map?.fitBounds(bounds, {
        padding: 40,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, santaOrders]);

  return (
    <MapWrapper>
      <Map
        ref={ref => ref && setMap(ref?.getMap())}
        attributionControl={false}
        mapStyle="mapbox://styles/mapbox/streets-v11"
        initialViewState={{ latitude: 65.044333, longitude: 27.410889, zoom: 4.5 }}
        mapboxAccessToken={MAP_TOKEN}>
        {filteredOrders().map(order => (
          <OrderMarker
            order={order}
            key={order.id}
            onClick={() => (santaView ? setSelectedSantaOrder(order) : setSelectedOrder(order))}
          />
        ))}

        {!santaView && (
          <DropDownWrapper>
            <StyledDropdown
              item
              simple
              text={t('filterOrders', {
                filter: t(filters[0]),
              })}>
              <DropdownMenu>
                {filterOptions.map(({ key }) => (
                  <DropdownItem key={key}>
                    <Checkbox
                      className="ui checkbox"
                      checked={filters.includes(key)}
                      label={t(key)}
                      id={key}
                      onChange={toggleSelection}
                    />
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </StyledDropdown>
          </DropDownWrapper>
        )}

        {selectedOrder && (
          <Popup
            offset={7}
            anchor="top"
            maxWidth="320px"
            style={{ width: '320px' }}
            {...parsePosition(selectedOrder.lonlat)}
            onClose={() => setSelectedOrder(null)}>
            <ContactInfoCard
              order={selectedOrder}
              place={'map'}
              setFormOpen={setFormOpen}
              setShowDialog={setShowDialog}
              setChangeStatusWindowOpen={setChangeStatusWindowOpen}
              setTypeOfChange={setTypeOfChange}
            />
          </Popup>
        )}
      </Map>
    </MapWrapper>
  );
};
