import { useFormik } from 'formik';
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Organisation, useUpdateOrganisationMutation } from '../../graphql/generated';
import { COLORS } from '../../styles/colors';
import { FONTS } from '../../styles/fonts';
import { secondaryButtonTheme } from '../../styles/themes';
import { WEIGHTS } from '../../styles/weights';
import SimpleTextField from '../common/SimpleTextField';
import { StyledButton } from '../common/StyledButton';
import { organisationFields } from './utilities/fields';
import { genOrganisationEditVariables } from './utilities/generateVariables';

const StyledBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
`;

const StyledFormBox = styled.div`
  height: 75%;
  min-width: 45%;
  border: 2px solid ${COLORS.grey};
  border-radius: 10px;
  background-color: ${COLORS.white};
  color: ${COLORS.brightGreen};
  padding: 10px;
  overflow-y: auto;
`;

const StyledFormHeader = styled.h1`
  font-family: ${FONTS.Lora};
  font-weight: ${WEIGHTS.medium};
  font-size: 24px;
  letter-spacing: 0.33px;
  margin: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const StyledFieldRow = styled.div`
  display: flex;
  column-gap: 13px;
  min-height: 65px;
  padding-left: 20px;
  padding-right: 20px;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  float: right;
`;

const StyledErrorMessage = styled.div`
  font-family: ${FONTS.Lora};
  font-weight: ${WEIGHTS.medium};
  color: ${COLORS.red};
  font-size: 14px;
  margin-right: 15px;
  display: flex;
  align-items: center;
`;

export enum OrganisationFormType {
  Edit = 'EDIT',
  Add = 'ADD',
}

interface Props {
  type: OrganisationFormType;
  organisation: Organisation | undefined;
  setFormOpen: Dispatch<SetStateAction<boolean>>;
  setShowAlert: Dispatch<SetStateAction<boolean>>;
}

const EditOrganisation: React.FC<Props> = ({ type, organisation, setFormOpen, setShowAlert }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin' });
  const [updateOrganisation] = useUpdateOrganisationMutation();
  const [error, setError] = useState('');
  const [header, setHeader] = useState('');

  const [fieldDetails, setFieldDetails] = useState([
    [{ label: '', required: false, disabled: false, type: '' }],
  ]);

  const validationHandler = () =>
    Yup.object({
      name: Yup.string().required(t('required')),
      email: Yup.string().email(t('errorEmail')),
      frontpageTitle: Yup.string().required(t('required')),
      frontpageSubtitle: Yup.string().required(t('required')),
      frontpageDescription: Yup.string().required(t('required')),
    });

  const formik = useFormik({
    initialValues: {},
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationHandler(),
    onSubmit: async () => {
      try {
        switch (type) {
          case OrganisationFormType.Edit:
            await updateOrganisation(genOrganisationEditVariables(formik.values, organisation!.id));
            break;
        }
        setFormOpen(false);
        setShowAlert(true);
      } catch (error) {
        setError(t('errorEditOrganisation'));
      }
    },
  });

  const createNewData = async () => {
    formik.handleSubmit();
  };

  useEffect(() => {
    switch (type) {
      case OrganisationFormType.Add:
        formik.setValues({});
        setHeader(t('addOrganisation'));
        break;
      case OrganisationFormType.Edit:
        formik.setValues({
          ...organisation,
        });
        setHeader(t('editOrganisation'));
        break;
    }
    setFieldDetails(organisationFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, organisation]);

  return (
    <StyledBackground>
      <StyledFormBox>
        <StyledFormHeader>{header}</StyledFormHeader>
        {fieldDetails.map((rowFields, index) => (
          <Fragment key={index}>
            <StyledFieldRow>
              {rowFields.map(field => (
                <SimpleTextField
                  key={field.label}
                  label={t(field.label).toString()}
                  required={field.required}
                  type={field.type}
                  select={field.type === 'selected'}
                  disabled={field.disabled}
                  id={field.label}
                  multiline={
                    field.label === 'frontpageSubtitle' ||
                    field.label === 'frontpageDescription' ||
                    field.label === 'frontpageTitle'
                  }
                  style={{ marginBottom: '12px' }}
                  onChange={event => {
                    formik.setFieldValue(field.label, event.target.value);
                  }}
                  error={formik.errors[field.label]}
                  helperText={''}
                  selectedItems={field.type === 'selected' ? undefined : undefined}
                  defaultValue={formik.values[field.label]}
                />
              ))}
            </StyledFieldRow>
          </Fragment>
        ))}
        <StyledButtonContainer>
          {error !== '' && <StyledErrorMessage>{error}</StyledErrorMessage>}
          <StyledButton theme={secondaryButtonTheme} onClick={() => setFormOpen(false)}>
            {t('cancel')}
          </StyledButton>
          <StyledButton type="submit" onClick={createNewData}>
            {t('save')}
          </StyledButton>
        </StyledButtonContainer>
      </StyledFormBox>
    </StyledBackground>
  );
};

export default EditOrganisation;
