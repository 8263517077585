import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Dispatch, SetStateAction, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { OrganisationSeasonContext } from '../../App';
import { useOrganisationUsersQuery } from '../../graphql/generated';
import { StyledButton } from '../common/StyledButton';
import { StyledInfoCard, StyledInfoCardHeader } from './StyledInfoCard';

interface UsersCardProps {
  setShowUsersTable: Dispatch<SetStateAction<boolean>>;
}

const StyledUsersCardHeader = styled(StyledInfoCardHeader)`
  margin-bottom: 16px;
  h1 {
    margin-bottom: 0;
  }
`;

const UsersCard: React.FC<UsersCardProps> = ({ setShowUsersTable }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin' });
  const { organisation } = useContext(OrganisationSeasonContext);
  const { data } = useOrganisationUsersQuery({ variables: { organisationId: organisation.id } });

  const columns: GridColDef[] = [
    { field: 'email', headerName: t('userName'), flex: 5 },
    { field: 'given_name', headerName: t('givenName'), flex: 2 },
    { field: 'family_name', headerName: t('familyName'), flex: 2 },
  ];

  const getRows = () => {
    const rows = data?.organisationUsers.map(user => ({
      ...user,
      id: user.id,
    }));
    return rows || [];
  };

  return (
    <StyledInfoCard>
      <StyledUsersCardHeader>
        <h1>{t('users')}</h1>
        <StyledButton onClick={() => setShowUsersTable(true)}>{t('manage')}</StyledButton>
      </StyledUsersCardHeader>
      <DataGrid
        sx={{
          '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
          },
        }}
        disableColumnMenu
        disableSelectionOnClick
        rows={getRows()}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        autoHeight
      />
    </StyledInfoCard>
  );
};

export default UsersCard;
