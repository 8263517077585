import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import ChangeViewButton from './components/ChangeViewButton';
import { MapComponent } from '../../components/map/Map';
import ContactInfoCard from '../../components/mapView/ContactInfoCard';
import MoreOptionsList from './components/MoreOptionsList';
import SantasList from './components/SantasList';
import SantasSelectedOrder from './components/SantasSelectedOrder';
import { Order, Santa, useSantaQuery, useSantasOrdersQuery } from '../../graphql/generated';
import { COLORS } from '../../styles/colors';
import { saveSantasSchedulePdf } from '../../components/utilities/saveSantasSchedulePdf';
import { saveSantasOrdersPdf } from '../../components/utilities/saveSantasOrdersPdf';
import { orderStatus } from '../../types/order';
import { OrganisationSeasonContext } from '../../App';
import { useParams } from 'react-router-dom';

const MAP_TOKEN = process.env.REACT_APP_MAP_TOKEN;

const StyledSantaViewContainer = styled.div`
  width: 100%;
  height: calc(100% - 45px);
  top: 45;
`;

const StyledSelectedOrderPopUp = styled.div`
  position: absolute;
  bottom: calc(100% / 8);
  right: calc(100% / 2 - 175px);
  width: 350px;
  height: 100px;
  background: ${COLORS.white};
  padding-top: 10px;
  padding-bottom: 10px;
`;

interface ISantasOrderContext {
  santaData: Santa | undefined;
  santaOrders: Order[];
  selectedSantaOrder: Order | null;
  setSelectedSantaOrder: (order: Order | null) => void;
}

export const SantasOrderContext = React.createContext<ISantasOrderContext>(
  {} as ISantasOrderContext
);

interface Props {
  santaAction: string;
  setSantaAction: Dispatch<SetStateAction<string>>;
}

const SantasView: React.FC<Props> = ({ santaAction, setSantaAction }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'santasView' });

  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [showListView, setShowListView] = useState(false);
  const [showSelectedOrder, setShowSelectedOrder] = useState(false);

  const { openSeason } = useContext(OrganisationSeasonContext);
  const { santaId } = useParams();

  const {
    data: santaQuery,
    loading,
    error,
  } = useSantaQuery({
    variables: { id: santaId },
  });

  const santaData = useMemo(() => (santaQuery?.santa || {}) as Santa, [santaQuery?.santa]);

  const { data } = useSantasOrdersQuery({
    variables: { santaId },
  });

  const [selectedSantaOrder, setSelectedSantaOrder] = useState<Order | null>(null);

  useEffect(() => {
    if (!showListView) {
      window.scrollTo(0, 0);
    }
  }, [showListView, showSelectedOrder]);

  // Remove orders that have status CANCELLED (break)
  const santaOrders = useMemo(
    () =>
      (data?.santasOrders.flatMap(order =>
        order.orderStatus !== orderStatus.confirmed ? [] : order
      ) || []) as Order[],
    [data?.santasOrders]
  );

  const generateTimeSlots = useMemo(() => {
    const hours: string[] = [];
    if (openSeason) {
      if (santaData.startTime && santaData.endTime) {
        let startMinutes = Number(santaData.startTime.slice(3, 5));

        for (
          let hour = Number(santaData.startTime.slice(0, 2));
          hour <= Number(santaData.endTime.slice(0, 2));
          hour++
        ) {
          for (let minute = startMinutes; minute < 60; minute += openSeason.interval) {
            if (
              hour === Number(santaData.endTime.slice(0, 2)) &&
              minute > Number(santaData.endTime.slice(3, 5))
            ) {
              break;
            }
            hours.push(moment({ hour, minute }).format('HH:mm'));
            startMinutes = (startMinutes + openSeason?.interval) % 60;
          }
        }
      }
    }

    return hours;
  }, [openSeason, santaData]);

  const santaMenuHandler = useCallback(() => {
    switch (santaAction) {
      case 'printSchedule': {
        const santaSchedule = generateTimeSlots.flatMap(time => {
          const order = santaOrders.find(
            order =>
              order.confirmedStartAt?.slice(0, 5) == time &&
              order.orderStatus === orderStatus.confirmed
          );

          return { time: time, order: order || ({} as Order) };
        });

        saveSantasSchedulePdf(
          `${t('season')} ${openSeason?.year}, ${santaData.name}`,
          santaData.areaNamePublic,
          `${t('orders')}: ${data?.santasOrders.length} | ${t('freeSlots')}: ${
            generateTimeSlots.length - data!.santasOrders!.length
          }`,
          santaSchedule,
          `pukkipalvelu_schedule_${santaData.name}`
        );
        setSantaAction('');
        break;
      }

      case 'printAll': {
        const santaSchedule = generateTimeSlots.flatMap(time => {
          const order = santaOrders.find(
            order => order.confirmedStartAt?.slice(0, 5) == time
          ) as Order;

          if (order) {
            return order;
          }
          return [];
        });

        saveSantasOrdersPdf(
          santaSchedule.map(order => ({
            ...order,
            map: `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-l+306e57(${
              order.lonlat?.split(',')[0]
            },${order.lonlat?.split(',')[1]})/${order.lonlat?.split(',')[0]},${
              order.lonlat?.split(',')[1]
            },16/900x270?access_token=${MAP_TOKEN}`,
          })),
          {
            name: santaData.name,
            area: santaData.areaNamePublic,
          },
          `pukkipalvelu_allOrders_${santaData.name}`
        );
        setSantaAction('');
        break;
      }
      default:
        console.log('Something went wrong. Dropdown option is not implemented.');
        break;
    }
  }, [
    data,
    generateTimeSlots,
    openSeason?.year,
    santaData,
    santaOrders,
    t,
    santaAction,
    setSantaAction,
  ]);

  useEffect(() => {
    if (santaAction) {
      santaMenuHandler();
    }
  }, [santaMenuHandler, santaAction]);

  if (!data || loading || error) {
    return <div>Loading...</div>;
  }

  return (
    <StyledSantaViewContainer>
      <SantasOrderContext.Provider
        value={{ santaData, santaOrders, selectedSantaOrder, setSelectedSantaOrder }}>
        {!showSelectedOrder &&
          (showListView ? (
            <SantasList
              setShowMoreOptions={setShowMoreOptions}
              setShowSelectedOrder={setShowSelectedOrder}
            />
          ) : (
            <MapComponent santaView={true} />
          ))}
        <ChangeViewButton listView={showListView} changeView={setShowListView} />

        {selectedSantaOrder && !showListView && (
          <StyledSelectedOrderPopUp onClick={() => !showMoreOptions && setShowSelectedOrder(true)}>
            <ContactInfoCard
              order={selectedSantaOrder}
              place={'santaview'}
              close={() => setSelectedSantaOrder(null)}
              setShowMoreOptions={setShowMoreOptions}
              setShowSelectedOrder={setShowSelectedOrder}
            />
          </StyledSelectedOrderPopUp>
        )}

        {showMoreOptions && <MoreOptionsList setShowMoreOptions={setShowMoreOptions} />}
        {showSelectedOrder && (
          <SantasSelectedOrder
            setShowMoreOptions={setShowMoreOptions}
            setShowSelectedOrder={setShowSelectedOrder}
          />
        )}
      </SantasOrderContext.Provider>
    </StyledSantaViewContainer>
  );
};

export default SantasView;
