import { ApolloQueryResult } from '@apollo/client';
import { Order, Santa, Season, SeasonOrdersQuery } from '../../graphql/generated';
import Cell from './Cell';

interface CellData {
  timeSlot: string;
  santa: Santa;
  order?: Order;
}

interface TableRowProps {
  time: string;
  timeTableData: CellData[];
  refetchOrders: () => Promise<ApolloQueryResult<SeasonOrdersQuery>>;
  setSelectedOrder: (order: Order) => void;
  season: Season;
}

const TableRow: React.FC<TableRowProps> = ({
  time,
  refetchOrders,
  timeTableData,
  setSelectedOrder,
  season,
}) => {
  const rowData = timeTableData.filter(data => data.timeSlot === time);

  return (
    <tr>
      <th className="timeCell">{time.slice(0, 5)}</th>
      {rowData.map(cellData => (
        <Cell
          key={`${cellData.timeSlot}&${cellData.santa.id}`}
          cellData={cellData}
          refetchOrders={refetchOrders}
          setSelectedOrder={setSelectedOrder}
          season={season}
        />
      ))}
    </tr>
  );
};

export default TableRow;
