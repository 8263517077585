import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { OrderContext } from '../../pages/mapView';
import { Order } from '../../graphql/generated';

import StyledCardColumn from '../common/StyledCardColumn';
import StyledCardRow from '../common/StyledCardRow';
import ContactInfoCard from './ContactInfoCard';
import OrderCardDetails from './OrderCardDetails';
import TimeInfoCard from './TimeInfoCard';

interface Props {
  order: Order;
  index: number;
  setFormOpen: Dispatch<SetStateAction<boolean>>;
  setChangeStatusWindowOpen: Dispatch<SetStateAction<boolean>>;
  setTypeOfChange: Dispatch<SetStateAction<string>>;
  setShowAlert: Dispatch<SetStateAction<boolean>>;
  setShowDialog: Dispatch<SetStateAction<boolean>>;
}

const OrderCard: React.FC<Props> = ({
  order,
  index,
  setFormOpen,
  setChangeStatusWindowOpen,
  setTypeOfChange,
  setShowAlert,
  setShowDialog,
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const [moreFunctionsOpen, setMoreFunctionsOpen] = useState(false);

  const showDetailsHandler = (isOpen: boolean) => {
    setShowDetails(isOpen);
  };

  const { selectedOrder, setSelectedOrder } = useContext(OrderContext);

  return (
    <>
      <StyledCardRow
        key={index}
        onClick={() => setSelectedOrder(order)}
        selected={order.id === selectedOrder?.id}>
        <StyledCardColumn size={5} selected={moreFunctionsOpen}>
          <ContactInfoCard order={order} detailsHandler={showDetailsHandler} place={'list'} />
        </StyledCardColumn>
        <StyledCardColumn size={3} selected={moreFunctionsOpen}>
          <TimeInfoCard
            order={order}
            toggleFunctionsOpen={() => {
              setSelectedOrder(order);
              setMoreFunctionsOpen(prevState => !prevState);
            }}
            setFormOpen={setFormOpen}
            setChangeStatusWindowOpen={setChangeStatusWindowOpen}
            setTypeOfChange={setTypeOfChange}
            setShowAlert={setShowAlert}
            setShowDialog={setShowDialog}
          />
        </StyledCardColumn>
      </StyledCardRow>
      {showDetails && (
        <StyledCardRow>
          <OrderCardDetails order={order} />
        </StyledCardRow>
      )}
    </>
  );
};

export default OrderCard;
