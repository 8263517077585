import { t } from 'i18next';
import styled from 'styled-components';
import SimpleTextField from '../../../../components/common/SimpleTextField';

const EmailInput: React.FC<{
  input: string;
  setInput: (value: string) => void;
  error: string;
}> = ({ input, setInput, error }) => (
  <AddressFieldContainer>
    <SimpleTextField
      id={'addressBox'}
      onChange={event => setInput(event.target.value)}
      error={error !== ''}
      helperText={error}
      selectedItems={undefined}
      placeholder={t('orderForm.email')}
      value={input}
    />
  </AddressFieldContainer>
);

const AddressFieldContainer = styled.div`
  padding-top: 8px;
`;

export default EmailInput;
