import { FormEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, CheckboxProps, Dropdown, DropdownItem, DropdownMenu } from 'semantic-ui-react';
import styled from 'styled-components';
import { OrganisationSeasonContext } from '../../App';
import StyledCardText from '../../components/common/StyledCardText';
import TimeTable from '../../components/dashboard/TimeTable';
import FormModal from '../../components/form/FormModal';
import {
  Order,
  Santa,
  Season,
  useSeasonOrdersLazyQuery,
  useSeasonSantasLazyQuery,
} from '../../graphql/generated';
import { COLORS } from '../../styles/colors';
import { FONTS } from '../../styles/fonts';
import { WEIGHTS } from '../../styles/weights';

const StyledHeader = styled.div`
  display: flex;
  margin-left: 32px;
  h1 {
    color: ${COLORS.brightGreen};
    font-family: ${FONTS.Lora};
    font-weight: ${WEIGHTS.medium};
    font-size: 26px;
    letter-spacing: 0.51px;
    padding-top: 13px;
  }
`;

const StyledDropdown = styled(Dropdown)`
  font-family: ${FONTS.Karla} !important;
  font-size: 13px;
  font-weight: ${WEIGHTS.regular};
  color: ${COLORS.brightGreen} !important;
`;

const DropDownWrapper = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  margin: 18px;
  border-radius: 25px;
`;

//TODO add functionality to select season from a dropdown menu
const Schedules: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard' });
  const { openSeason, closedSeason } = useContext(OrganisationSeasonContext);

  const [formState, setFormState] = useState(false);
  const [order, setOrder] = useState<Order | null>(null);
  const [getSeasonSantas, { data: santasData }] = useSeasonSantasLazyQuery();
  const [getSeasonOrders, { data: ordersData, refetch: refetchOrders }] =
    useSeasonOrdersLazyQuery();
  const [filterOptions, setFilterOptions] = useState([{ text: 'all', key: 'ALL' }]);
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

  useEffect(() => {
    if (order) setFormState(true);
  }, [order]);

  useEffect(() => {
    if (openSeason || closedSeason) {
      getSeasonSantas({
        variables: { seasonId: openSeason ? openSeason.id : closedSeason!.id },
      });
      getSeasonOrders({
        variables: { seasonId: openSeason ? openSeason.id : closedSeason!.id },
      });
    }
  }, [openSeason, getSeasonSantas, getSeasonOrders, closedSeason]);

  useEffect(() => {
    if (santasData) {
      const santas = [...santasData.seasonSantas].sort((a, b) => a.position - b.position);
      const newFilterOptions: {
        text: string;
        key: string;
      }[] = [{ text: t('all'), key: 'ALL' }];
      santas.forEach(santa => {
        newFilterOptions.push({
          text: `${santa.name} (${santa.areaNamePublic})`,
          key: santa.id,
        });
      });
      setFilterOptions(newFilterOptions);
      setSelectedFilters(newFilterOptions.map(e => e.key));
    }
  }, [santasData, t]);

  const closeFormHandler = () => {
    refetchOrders();
    setFormState(prevState => !prevState);
    setOrder(null);
  };

  if ((!openSeason && !closedSeason) || !santasData || !ordersData) {
    return (
      <div>
        <StyledHeader>
          <h1>{t('dashboard')}</h1>
          <div style={{ margin: '20px' }}>
            <StyledCardText
              display={'inline-block'}
              font={FONTS.Karla}
              weight={WEIGHTS.regular}
              size={18}
              color={COLORS.brightGreen}>
              {t('noSeason')}
            </StyledCardText>
          </div>
        </StyledHeader>
      </div>
    );
  }

  const toggleSelection = (e: FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    const { id, checked } = data;

    if (id === 'ALL') {
      checked
        ? setSelectedFilters(filterOptions.map(option => option.key))
        : setSelectedFilters([]);
    } else if (selectedFilters.includes('ALL')) {
      setSelectedFilters(selectedFilters.filter(e => e !== 'ALL' && e !== id));
    } else {
      if (checked) {
        setSelectedFilters([
          ...selectedFilters,
          filterOptions[filterOptions.findIndex(e => e.key === id)].key,
        ]);
      } else {
        setSelectedFilters(selectedFilters.filter(e => e !== id));
      }
    }
  };

  const selectedSantas = () => {
    const santas = [...santasData.seasonSantas].sort((a, b) => a.position - b.position);

    if (selectedFilters.includes('ALL')) {
      return santas as Santa[];
    }
    return santas.filter(e => selectedFilters.includes(e.id)) as Santa[];
  };

  return (
    <div>
      <StyledHeader>
        <h1>{t('dashboard')}</h1>
        <DropDownWrapper>
          <StyledDropdown item simple text={t('santa/area')}>
            <DropdownMenu>
              {filterOptions.map(({ text, key }) => (
                <DropdownItem key={key}>
                  <Checkbox
                    className="ui checkbox"
                    checked={selectedFilters!.includes(key)}
                    label={text}
                    id={key}
                    onChange={toggleSelection}
                  />
                </DropdownItem>
              ))}
            </DropdownMenu>
          </StyledDropdown>
        </DropDownWrapper>
      </StyledHeader>
      <div>
        {selectedSantas().length === 0 ? (
          <div style={{ margin: '20px' }}>
            <StyledCardText
              display={'inline-block'}
              font={FONTS.Karla}
              weight={WEIGHTS.regular}
              size={18}
              color={COLORS.brightGreen}>
              {t('noSantas')}
            </StyledCardText>
          </div>
        ) : (
          <TimeTable
            season={(openSeason as Season) || (closedSeason as Season)}
            orders={ordersData.seasonOrders as Order[]}
            refetchOrders={refetchOrders}
            santas={selectedSantas()}
            setSelectedOrder={setOrder}
          />
        )}
        {formState && (
          <FormModal
            closeForm={closeFormHandler}
            selectedOrder={order}
            season={(openSeason as Season) || (closedSeason as Season)}
            santa={santasData.seasonSantas.find(santa => santa.id === order?.santaId) as Santa}
          />
        )}
      </div>
    </div>
  );
};

export default Schedules;
