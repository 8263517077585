import styled from 'styled-components';

import { COLORS } from '../../styles/colors';

const StyledCardColumn = styled.div<{ size: number; selected?: boolean }>`
  display: flex;
  justify-content: top;
  flex-direction: column;
  flex: ${props => props.size};
  /* background: ${props => (props.selected ? COLORS.lightGrey : COLORS.white)}; */
  border-right: 1px solid ${COLORS.grey};
  padding-top: 10px;
`;

export default StyledCardColumn;
