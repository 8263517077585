import axios from 'axios';
import fileDownload from 'js-file-download';
import i18next from 'i18next';
import { Maybe, Order } from '../../graphql/generated';
import { orderStatus } from '../../types/order';

const URL = process.env.REACT_APP_API_URL;
const MAP_TOKEN = process.env.REACT_APP_MAP_TOKEN;
const API_KEY = process.env.REACT_APP_BACKEND_API_KEY!;

interface pdfValues {
  name: string;
  phone: string;
  email: string;
  doorname: string;
  address: string;
  postcode: string;
  city: string;
  wishEarliest: string;
  wishLatest: string;
  children: { name: string; age: string; goodToKnow: string }[] | Record<string, never>[];
  meetOutside: boolean;
  routeInfo: string;
  doorCode: string;
  giftInfo: string;
  otherInfo: string;
  confirmedStartAt?: string;
  map?: string;
}

const buildMainHeader = (
  confirmedOrder: boolean,
  status: Maybe<string> | undefined,
  values: pdfValues | Order | undefined,
  santa: { name: string | undefined; area: string | undefined } | undefined
) => {
  if (confirmedOrder) {
    return `${i18next.t('form.order')}:  ${values?.confirmedStartAt?.slice(0, 5)} - 
    ${santa?.name} | ${santa?.area}`;
  } else if (status === undefined) {
    return i18next.t('form.orderDetails');
  } else if (status === orderStatus.rejected) {
    return `${i18next.t('form.order')}:  ${i18next.t('form.rejected')}`;
  } else {
    return `${i18next.t('form.order')}:  ${i18next.t('form.notConfirmed')}`;
  }
};

export const saveOrderPdf = async (
  orderValues: pdfValues | undefined,
  databaseOrderValues: Order | undefined,
  santaValues: { name: string | undefined; area: string | undefined } | undefined,
  lonlat: string | undefined,
  confirmedOrder: boolean,
  filename: string
) => {
  try {
    const form = {
      template: 'orderTemplate',
      options: {
        format: 'A4',
        orientation: 'portrait',
        border: '10mm',
        header: {
          height: '10mm',
        },
        footer: {
          height: '10mm',
        },
        locals: {
          form: {
            mainHeader: buildMainHeader(
              confirmedOrder,
              databaseOrderValues?.orderStatus,
              orderValues || databaseOrderValues,
              santaValues
            ),
            header1: i18next.t('form.subscriberDetails'),
            header2: i18next.t('form.christmasEveVisitDetails'),
            ...(!confirmedOrder ? { header3: i18next.t('form.timeWishDetails') } : {}),
            header4: i18next.t('form.childrenDetails'),
            header5: i18next.t('form.additionalDetails'),
            name: i18next.t('form.name'),
            phone: i18next.t('form.phone'),
            email: i18next.t('form.email'),
            doorname: i18next.t('form.doorname'),
            address: i18next.t('form.address'),
            postcode: i18next.t('form.postcode'),
            city: i18next.t('form.city'),
            wishEarliest: i18next.t('form.wishEarliest'),
            wishLatest: i18next.t('form.wishLatest'),
            child: {
              name: i18next.t('form.name'),
              age: i18next.t('form.age'),
              goodToKnow: i18next.t('form.goodToKnow'),
            },
            meetOutside: i18next.t('form.meetOutside'),
            routeInfo: i18next.t('form.routeInfo'),
            doorCode: i18next.t('form.doorCode'),
            giftInfo: i18next.t('form.giftInfo'),
            otherInfo: i18next.t('form.otherInfo'),
            annualMessages: i18next.t('form.annualMessages'),
            santaName: i18next.t('form.confirmedSantaName'),
            santaArea: i18next.t('form.confirmedSantaArea'),
            confirmedTime: i18next.t('form.confirmedStartAt'),
          },
          order: {
            name: orderValues?.name || databaseOrderValues?.customerName,
            phone: orderValues?.phone || databaseOrderValues?.phone,
            email: orderValues?.email || databaseOrderValues?.email,
            doorname: orderValues?.doorname || databaseOrderValues?.visitName,
            address: orderValues?.address || databaseOrderValues?.streetAddress,
            postcode: orderValues?.postcode || databaseOrderValues?.postalCode,
            city: orderValues?.city || databaseOrderValues?.city,
            wishEarliest:
              orderValues?.wishEarliest || databaseOrderValues?.wishEarliest.slice(0, 5),
            wishLatest: orderValues?.wishLatest || databaseOrderValues?.wishLatest.slice(0, 5),
            child:
              orderValues?.children ||
              databaseOrderValues?.orderChildren.map(({ name, age, description }) => ({
                name,
                age,
                goodToKnow: description,
              })),
            meetOutside:
              orderValues?.meetOutside || databaseOrderValues?.meetOutside ? 'checked' : '',
            routeInfo: orderValues?.routeInfo || databaseOrderValues?.routeInfo,
            doorCode: orderValues?.doorCode || databaseOrderValues?.doorCode,
            giftInfo: orderValues?.giftInfo || databaseOrderValues?.giftInfo,
            otherInfo: orderValues?.otherInfo || databaseOrderValues?.otherInfo,
            map:
              orderValues?.map ||
              `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-l+306e57(${
                lonlat?.split(',')[0]
              },${lonlat?.split(',')[1]})/${lonlat?.split(',')[0]},${
                lonlat?.split(',')[1]
              },16/900x270?access_token=${MAP_TOKEN}`,
            santaName: santaValues?.name,
            santaArea: santaValues?.area,
            confirmedTime:
              orderValues?.confirmedStartAt?.slice(0, 5) ||
              databaseOrderValues?.confirmedStartAt?.slice(0, 5),
          },
        },
      },
    };
    const response = await axios.post(`${URL}/pdf/generatePDF`, form, {
      responseType: 'arraybuffer',
      headers: {
        apiKey: API_KEY,
      },
    });

    fileDownload(response.data, `${filename}.pdf`);
  } catch (error) {
    console.log('Something went wrong when saving pdf', error);
  }
};
