import React, { useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';

import { User } from '../../components/utilities/RouteGuard';
import AuthService from '../../services/AuthService';
import styled from 'styled-components';
import SessionExpiredDialog from '../../components/alerts/SessionExpiredDialog';
import { UserRole } from '../../graphql/generated';

interface Props {
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
}

const CenterDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoginHandler: React.FC<Props> = ({ setUser }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const reqSent = React.useRef(false);
  const location = useLocation();
  const [time, setTime] = useState(6);

  const getNavigateURL = (user: User | null) => {
    let URL = '/';
    if (user?.roles.includes(UserRole.SUPERADMIN)) {
      URL = `/admin`;
    } else if (
      user?.roles.includes(UserRole.ORG_ADMIN) ||
      user?.roles.includes(UserRole.ORG_USER)
    ) {
      URL = `/${user?.orgShortName.toLowerCase()}/admin`;
    } else if (user?.roles.includes(UserRole.SANTA)) {
      URL = `/${user?.orgShortName.toLowerCase()}/santa`;
    }
    return URL;
  };

  React.useEffect(() => {
    if (location.pathname === '/login/expired' && time > 0) {
      setTimeout(() => {
        setTime(prevState => prevState - 1);
      }, 1000);
    }
  }, [location.pathname, time]);

  React.useEffect(() => {
    if (location.pathname !== '/login/expired' || time === 0) {
      const handleAuth = async () => {
        const code = searchParams.get('code');
        if (!code) {
          AuthService.initiateLogin();
        }

        if (code && reqSent.current === false) {
          reqSent.current = true;

          const user = await AuthService.login(code);
          if (user) {
            setUser(user);
            navigate(getNavigateURL(user), { replace: true });
          }
        }
      };
      handleAuth();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  return (
    <>
      {location.pathname === '/login/expired' && <SessionExpiredDialog time={time} />}
      <CenterDiv>
        <ThreeDots />
      </CenterDiv>
    </>
  );
};
