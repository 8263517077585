import { Dispatch, SetStateAction, useContext } from 'react';

import { SantasOrderContext } from '..';
import StyledCardRow from '../../../components/common/StyledCardRow';
import SantasSelectedOrderDetails from './SantasSelectedOrderDetails';
import SantasSelectedOrderMainInfo from './SantasSelectedOrderMainInfo';

interface Props {
  setShowMoreOptions: Dispatch<SetStateAction<boolean>>;
  setShowSelectedOrder: Dispatch<SetStateAction<boolean>>;
}

const SantasSelectedOrder: React.FC<Props> = ({ setShowMoreOptions, setShowSelectedOrder }) => {
  const { selectedSantaOrder } = useContext(SantasOrderContext);

  return (
    <StyledCardRow>
      {selectedSantaOrder !== null && (
        <>
          <SantasSelectedOrderMainInfo
            setShowSelectedOrder={setShowSelectedOrder}
            setShowMoreOptions={setShowMoreOptions}
          />
          <SantasSelectedOrderDetails />
        </>
      )}
    </StyledCardRow>
  );
};

export default SantasSelectedOrder;
