import axios, { AxiosError } from 'axios';
import { getUser } from '../utils/getUser';

const clientURL = process.env.REACT_APP_CLIENT_URL;
const apiKey = process.env.REACT_APP_BACKEND_API_KEY!;
const cognitoClientID = process.env.REACT_APP_COGNITO_CLIENT_ID;
const cognitoURL = process.env.REACT_APP_COGNITO_URL;

interface TokenResponse {
  token: string;
}

const AuthService = {
  initiateLogin: () => {
    window.location.replace(
      `${cognitoURL}/login?client_id=${cognitoClientID}&response_type=code&scope=email+openid&redirect_uri=${clientURL}/login`
    );
  },

  login: async (accessCode: string) => {
    const response = await axios.post<TokenResponse>(
      `${process.env.REACT_APP_API_URL}/auth/login`,
      {
        code: accessCode,
        clientURL,
      },
      {
        withCredentials: true,
        headers: {
          apiKey,
        },
      }
    );
    const data = response.data;
    sessionStorage.setItem('token', data.token);
    const user = getUser();
    return user;
  },

  logout: async (clearUserData: () => void) => {
    const token = sessionStorage.getItem('token');

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/auth/logout`, null, {
        headers: { Authorization: `Bearer ${token}`, apiKey },
      });
      clearUserData();
    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        clearUserData();
      }
    }
  },

  refreshToken: async () => {
    try {
      const response = await axios.get<{ token: string }>(
        `${process.env.REACT_APP_API_URL}/auth/refresh`,
        { headers: { apiKey } }
      );
      console.log(response);

      const { token } = response.data;
      sessionStorage.setItem('token', token);
      return token;
    } catch (err) {
      console.log(err);

      return null;
    }
  },
};

export default AuthService;
