import { Dispatch, SetStateAction, useContext } from 'react';
import styled from 'styled-components';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../styles/colors';
import { WEIGHTS } from '../../../styles/weights';
import { FONTS } from '../../../styles/fonts';
import { SantasOrderContext } from '..';

export const StyledChangeViewButton = styled.button`
  position: sticky;
  bottom: calc(100% / 15);
  left: calc(100% / 2 - 45px);
  width: 90px;
  height: 30px;
  font-family: ${FONTS.Karla};
  font-weight: ${WEIGHTS.medium};
  color: ${COLORS.white};
  border: 1px solid ${COLORS.red};
  background: ${COLORS.red};
  font-size: 11px;
  letter-spacing: 0.51px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface Props {
  listView: boolean;
  changeView: Dispatch<SetStateAction<boolean>>;
}

const ChangeViewButton: React.FC<Props> = ({ listView, changeView }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'santasView' });

  const { setSelectedSantaOrder } = useContext(SantasOrderContext);

  return (
    <StyledChangeViewButton
      onClick={() => [setSelectedSantaOrder(null), changeView(prevState => !prevState)]}>
      {listView ? (
        <MapOutlinedIcon sx={{ m: 0.5, fontSize: 18 }} />
      ) : (
        <ListOutlinedIcon sx={{ m: 0.5, fontSize: 18 }} />
      )}
      {listView ? t('map') : t('list')}
    </StyledChangeViewButton>
  );
};

export default ChangeViewButton;
