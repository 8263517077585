import { useEffect } from 'react';
import { Order, Season, SeasonSchedule, useSeasonScheduleLazyQuery } from '../graphql/generated';

export const useSeasonSchedule = (openSeason: Season | undefined, orders = [] as Order[]) => {
  const [query, { data: seasonScheduleQuery, refetch, called }] = useSeasonScheduleLazyQuery();

  useEffect(() => {
    if (openSeason) {
      if (called) {
        refetch();
      } else {
        query({ variables: { seasonId: openSeason.id } });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders.values(), openSeason]);

  return (seasonScheduleQuery?.seasonSchedule ?? { areas: [], santas: [] }) as SeasonSchedule;
};
