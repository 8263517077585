import axios from 'axios';
import fileDownload from 'js-file-download';
import i18next from 'i18next';
import { Maybe } from 'graphql/jsutils/Maybe';

const URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_BACKEND_API_KEY!;

interface pdfValues {
  customerName: string;
  phone: string;
  email: string;
  visitName: string;
  streetAddress: string;
  postalCode: string;
  city: string;
  wishEarliest: string;
  wishLatest: string;
  orderChildren: {
    name: string;
    age?: Maybe<number> | undefined;
    description?: Maybe<string> | undefined;
  }[];
  meetOutside: boolean;
  routeInfo?: Maybe<string> | undefined;
  doorCode?: Maybe<string> | undefined;
  giftInfo?: Maybe<string> | undefined;
  otherInfo?: Maybe<string> | undefined;
  newsletterIsSubscribed?: boolean | null | undefined;
  confirmedStartAt?: Maybe<string> | undefined;
  map: string;
}

export const saveSantasOrdersPdf = async (
  orderValues: pdfValues[],
  santaValues: { name: string; area: string },
  filename: string
) => {
  try {
    const form = {
      template: 'allOrdersTemplate',
      options: {
        format: 'A4',
        orientation: 'portrait',
        border: '10mm',
        header: {
          height: '10mm',
        },
        footer: {
          height: '10mm',
        },
        locals: {
          form: {
            mainHeader: i18next.t('form.order'),
            header1: i18next.t('form.subscriberDetails'),
            header2: i18next.t('form.christmasEveVisitDetails'),
            header4: i18next.t('form.childrenDetails'),
            header5: i18next.t('form.additionalDetails'),
            name: i18next.t('form.name'),
            phone: i18next.t('form.phone'),
            email: i18next.t('form.email'),
            doorname: i18next.t('form.doorname'),
            address: i18next.t('form.address'),
            postcode: i18next.t('form.postcode'),
            city: i18next.t('form.city'),
            wishEarliest: i18next.t('form.wishEarliest'),
            wishLatest: i18next.t('form.wishLatest'),
            child: {
              name: i18next.t('form.name'),
              age: i18next.t('form.age'),
              goodToKnow: i18next.t('form.goodToKnow'),
            },
            meetOutside: i18next.t('form.meetOutside'),
            routeInfo: i18next.t('form.routeInfo'),
            doorCode: i18next.t('form.doorCode'),
            giftInfo: i18next.t('form.giftInfo'),
            otherInfo: i18next.t('form.otherInfo'),
            annualMessages: i18next.t('form.annualMessages'),
            santaName: i18next.t('form.confirmedSantaName'),
            santaArea: i18next.t('form.confirmedSantaArea'),
            confirmedTime: i18next.t('form.confirmedStartAt'),
          },
          orders: orderValues.map(order => ({
            ...order,
            confirmedStartAt: order.confirmedStartAt?.slice(0, 5) || '',
          })),
          santaName: santaValues?.name,
          santaArea: santaValues?.area,
        },
      },
    };
    const response = await axios.post(`${URL}/pdf/generatePDF`, form, {
      responseType: 'arraybuffer',
      headers: {
        apiKey: API_KEY,
      },
    });

    fileDownload(response.data, `${filename}.pdf`);
  } catch (error) {
    console.log('Something went wrong when saving pdf', error);
  }
};
