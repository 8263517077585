import styled from 'styled-components';

import { COLORS } from '../../styles/colors';

const StyledHr = styled.hr<{ margin?: boolean }>`
  background: ${COLORS.grey};
  color: ${COLORS.grey};
  border: 0.1px solid ${COLORS.grey};
  height: 0.1px;
  margin: ${props => (props.margin ? '15px' : '0px')};
`;

export default StyledHr;
