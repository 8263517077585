import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { OrganisationSeasonContext } from '../../App';
import CreateNew from '../../components/admin/CreateNew';
import EditOrganisation, { OrganisationFormType } from '../../components/admin/EditOrganisation';
import OrganisationCard from '../../components/admin/OrganisationCard';
import SantasCard from '../../components/admin/SantasCard';
import SantaTable from '../../components/admin/SantaTable';
import SeasonCard from '../../components/admin/SeasonCard';
import UsersCard from '../../components/admin/UsersCard';
import UsersTable from '../../components/admin/UsersTable';
import AlertSnackbar from '../../components/alerts/AlertSnackbar';
import { StyledButton } from '../../components/common/StyledButton';
import { Santa, Season, UserEntity } from '../../graphql/generated';
import { COLORS } from '../../styles/colors';
import { FONTS } from '../../styles/fonts';
import { WEIGHTS } from '../../styles/weights';

const StyledHeader = styled.div`
  margin-top: 45px;
  margin-bottom: 16px;
  display: flex;
  h1 {
    color: ${COLORS.brightGreen};
    font-family: ${FONTS.Lora};
    font-weight: ${WEIGHTS.medium};
    font-size: 26px;
    letter-spacing: 0.51px;
    padding-top: 13px;
  }
`;

const AdminPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 32px;
  padding-right: 32px;
  background-color: ${COLORS.lightGrey};
  height: 100vh;
  margin-top: -45px;
`;

const CardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const UsersAndOrganisationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  width: 100%;
`;

const Admin: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin' });
  const { organisation } = useContext(OrganisationSeasonContext);
  const [selectedSeason, setSelectedSeason] = useState<Season | undefined>(undefined);
  const [selectedSanta, setSelectedSanta] = useState<Santa | null>(null);
  const [selectedUser, setSelectedUser] = useState<UserEntity | null>(null);
  const [showSantasTable, setShowSantasTable] = useState(false);
  const [showCreateNew, setShowCreateNew] = useState(false);
  const [showEditOrganisation, setShowEditOrganisation] = useState(false);
  const [typeCreate, setTypeCreate] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [showUsersTable, setShowUsersTable] = useState(false);

  return (
    <div onClick={() => setShowAlert(false)}>
      {showAlert && (
        <AlertSnackbar
          alertType={'success'}
          alertText={typeCreate}
          close={() => setShowAlert(false)}
        />
      )}
      {showCreateNew && (
        <CreateNew
          type={typeCreate}
          setFormOpen={setShowCreateNew}
          selectedSeason={selectedSeason}
          selectedSanta={selectedSanta}
          setShowAlert={setShowAlert}
          selectedUser={selectedUser}
        />
      )}
      {showEditOrganisation && (
        <EditOrganisation
          organisation={organisation || undefined}
          type={OrganisationFormType.Edit}
          setFormOpen={setShowEditOrganisation}
          setShowAlert={setShowAlert}
        />
      )}
      <AdminPageWrapper>
        {!showSantasTable && !showUsersTable && (
          <>
            <StyledHeader>
              <h1>{t('admin')}</h1>
            </StyledHeader>
            <CardsWrapper>
              <UsersAndOrganisationWrapper>
                <OrganisationCard
                  setTypeCreate={setTypeCreate}
                  setFormOpen={setShowEditOrganisation}
                />
                <UsersCard setShowUsersTable={setShowUsersTable} />
              </UsersAndOrganisationWrapper>
              <SeasonCard
                selectedSeason={selectedSeason}
                setSelectedSeason={setSelectedSeason}
                setTypeCreate={setTypeCreate}
                setFormOpen={setShowCreateNew}
                formClosed={showCreateNew}
              />
              <SantasCard
                season={selectedSeason}
                setShowSantasTable={setShowSantasTable}
                formClosed={showCreateNew}
              />
            </CardsWrapper>
          </>
        )}
        {showUsersTable && (
          <>
            <StyledHeader>
              <h1>{t('manageUsers')}</h1>
              <StyledButton
                onClick={() => {
                  setTypeCreate('user');
                  setShowCreateNew(true);
                }}>
                {t('createUser')}
              </StyledButton>
              <StyledButton type="reset" onClick={() => setShowUsersTable(false)}>
                {t('back')}
              </StyledButton>
            </StyledHeader>
            <UsersTable
              organisation={organisation}
              formClosed={showCreateNew}
              setFormOpen={setShowCreateNew}
              setTypeCreate={setTypeCreate}
              setSelectedUser={setSelectedUser}
            />
          </>
        )}
        {showSantasTable && selectedSeason && (
          <>
            <StyledHeader>
              <h1>{t('manageSantas')}</h1>
              <StyledButton
                onClick={() => {
                  setTypeCreate('santa');
                  setShowCreateNew(true);
                }}>
                {t('createSanta')}
              </StyledButton>
              <StyledButton type="reset" onClick={() => setShowSantasTable(false)}>
                {t('back')}
              </StyledButton>
            </StyledHeader>
            <SantaTable
              season={selectedSeason}
              setTypeCreate={setTypeCreate}
              setFormOpen={setShowCreateNew}
              formClosed={showCreateNew}
              setSelectedSanta={setSelectedSanta}
            />
          </>
        )}
      </AdminPageWrapper>
    </div>
  );
};

export default Admin;
