import styled from 'styled-components';
import { COLORS } from '../../styles/colors';
import { FONTS } from '../../styles/fonts';
import { WEIGHTS } from '../../styles/weights';

const StyledLogoText = styled.div`
  font-family: ${FONTS.Karla};
  font-weight: ${WEIGHTS.semibold};
  color: ${COLORS.lightYellow};
  font-size: 18px;
  padding: 10px;
  padding-left: 18px;
  padding-right: 18px;
`;

const Logo: React.FC = () => <StyledLogoText>tilaapukki.fi</StyledLogoText>;

export default Logo;
