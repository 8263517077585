import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import OrganisationInfoCard from '../../components/admin/superadmin/OrganisationInfoCard';
import OrganisationInfoForm from '../../components/admin/superadmin/OrganisationInfoForm';
import { Organisation, useOrganisationsQuery } from '../../graphql/generated';
import { COLORS } from '../../styles/colors';

const SuperAdmin: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin' });

  const { data, refetch } = useOrganisationsQuery();
  const [selectedOrganisation, setSelectedOrganisation] = useState<Organisation | null>(null);

  return (
    <PageContentWrapper>
      <SectionWrapper>
        <h1>{t('organisations')}</h1>
        {data?.organisations.map(item => (
          <OrganisationInfoCard
            key={item.id}
            organisation={item}
            setSelectedOrganisation={setSelectedOrganisation}
          />
        ))}
      </SectionWrapper>
      <SectionWrapper weight={2}>
        <OrganisationInfoForm
          organisation={selectedOrganisation}
          setOrganisation={setSelectedOrganisation}
          refetchOrganisations={refetch}
        />
      </SectionWrapper>
    </PageContentWrapper>
  );
};

const PageContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const SectionWrapper = styled.div<{ weight?: number }>`
  display: flex;
  flex: ${({ weight }) => weight || 1};
  flex-direction: column;
  padding: 25px;
  margin: 10px;
  border: 1px solid ${COLORS.grey};
  border-radius: 10px;
  color: ${COLORS.brightGreen};
  box-shadow: 0 4px 8px 0 ${COLORS.grey}, 0 6px 20px 0 ${COLORS.grey};
`;

export default SuperAdmin;
