import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { COLORS } from '../../styles/colors';
import { FONTS } from '../../styles/fonts';
import { WEIGHTS } from '../../styles/weights';
import { orderStatus } from '../../types/order';

const StyledStatus = styled.p<{ color: string; fontColor: string }>`
  display: flex;
  width: 84px;
  height: 20px;
  background: ${props => props.color};
  color: ${props => props.fontColor};
  font-size: 13px;
  font-family: ${FONTS.Karla};
  font-weight: ${WEIGHTS.regular};
  text-transform: uppercase;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  margin: 13px;
`;

interface Props {
  statusType: string | null | undefined;
  form?: boolean;
}

const OrderStatus: React.FC<Props> = ({ statusType, form }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'orderStatus' });
  let color = '';
  let fontColor = '';
  let text = '';

  if (statusType === orderStatus.confirmed) {
    text = 'confirmed';
    color = form ? COLORS.brightGreen2 : COLORS.lightGreen;
    fontColor = form ? COLORS.lightGreen : COLORS.brightGreen;
  } else if (statusType === orderStatus.waitConfirmation) {
    text = 'waiting';
    color = form ? COLORS.darkYellow : COLORS.lightYellow2;
    fontColor = form ? COLORS.lightYellow2 : COLORS.darkYellow;
  } else if (statusType === orderStatus.rejected) {
    text = 'rejected';
    color = form ? COLORS.red : COLORS.lightRed;
    fontColor = form ? COLORS.lightRed : COLORS.red;
  }

  return (
    <StyledStatus color={color} fontColor={fontColor}>
      {t(text)}
    </StyledStatus>
  );
};

export default OrderStatus;
