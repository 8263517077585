import { Dispatch, SetStateAction, useContext } from 'react';
import styled from 'styled-components';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';

import { SantasOrderContext } from '..';
import { COLORS } from '../../../styles/colors';
import { WEIGHTS } from '../../../styles/weights';
import { FONTS } from '../../../styles/fonts';

const StyledContainer = styled.nav`
  position: absolute;
  width: 100%;
  height: 200px;
  top: 0;
  background: ${COLORS.red};
  z-index: 3;
`;

const StyledRowContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  color: ${COLORS.white};
  padding-left: 20px;
  padding-right: 20px;
`;

const StyledTopText = styled.p`
  width: 100%;
  display: flex;
  justify-content: right;
  color: ${COLORS.white};
  font-family: 'Karla', sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin: 20px;
`;

const StyledText = styled.p<{
  fontLora: boolean;
  weight: number;
  fontSize: number;
  padding: number;
}>`
  color: ${COLORS.white};
  font-family: ${props => (props.fontLora ? FONTS.Lora : FONTS.Karla)};
  font-weight: ${props => props.weight};
  font-size: ${props => props.fontSize}px;
  padding-bottom: ${props => props.padding}px;
  letter-spacing: 0.36px;
`;

interface Props {
  setShowSelectedOrder: Dispatch<SetStateAction<boolean>>;
  setShowMoreOptions: Dispatch<SetStateAction<boolean>>;
}

const SantasSelectedOrderMainInfo: React.FC<Props> = ({
  setShowSelectedOrder,
  setShowMoreOptions,
}) => {
  const { selectedSantaOrder, setSelectedSantaOrder } = useContext(SantasOrderContext);

  return (
    <StyledContainer>
      <StyledRowContainer>
        <ArrowBackIosNewOutlinedIcon
          sx={{ fontSize: 22 }}
          onClick={() => [setShowSelectedOrder(false), setSelectedSantaOrder(null)]}
        />

        <StyledTopText>
          {selectedSantaOrder?.duration}&nbsp;min&nbsp;|&nbsp;
          {selectedSantaOrder?.language}&nbsp;|&nbsp;{selectedSantaOrder?.price}&nbsp;e
        </StyledTopText>

        <MoreHorizOutlinedIcon sx={{ fontSize: 28 }} onClick={() => setShowMoreOptions(true)} />
      </StyledRowContainer>
      <StyledRowContainer>
        <StyledText fontLora={true} weight={WEIGHTS.medium} fontSize={33} padding={5}>
          {selectedSantaOrder?.confirmedStartAt?.slice(0, 5) ||
            `${selectedSantaOrder?.wishEarliest.slice(
              0,
              5
            )} - ${selectedSantaOrder?.wishLatest.slice(0, 5)}`}
        </StyledText>
      </StyledRowContainer>
      <StyledRowContainer>
        <StyledText fontLora={true} weight={WEIGHTS.semibold} fontSize={18} padding={3}>
          {selectedSantaOrder?.streetAddress},&nbsp;{selectedSantaOrder?.postalCode}&nbsp;
          {selectedSantaOrder?.city}
        </StyledText>
      </StyledRowContainer>
      <StyledRowContainer>
        <StyledText fontLora={false} weight={WEIGHTS.regular} fontSize={16} padding={0}>
          {selectedSantaOrder?.visitName}
        </StyledText>
      </StyledRowContainer>
    </StyledContainer>
  );
};

export default SantasSelectedOrderMainInfo;
