import React from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { UserRole } from '../../graphql/generated';
import { verifyRoles } from '../../utils/verifyRoles';
import NavBar from '../nav/NavBar';

export interface User {
  roles: UserRole[];
  id: string;
  orgShortName: string;
  email: string;
  exp: number;
}

type Props = {
  children?: React.ReactNode;
  user: User | null;
  allowedRoles: UserRole[];
  redirectPath?: string;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  setSantaAction: React.Dispatch<React.SetStateAction<string>>;
  formDirty?: boolean;
  setClicked?: React.Dispatch<React.SetStateAction<boolean>>;
};

const RouteGuard: React.FC<Props> = ({
  user,
  children,
  allowedRoles,
  setUser,
  setSantaAction,
  redirectPath = '/',
  formDirty,
  setClicked,
}) => {
  const { orgName } = useParams();

  if (!verifyRoles(user, allowedRoles)) {
    return <Navigate to={!user && allowedRoles.length > 0 ? '/login' : '/'} replace />;
  }

  if (
    allowedRoles.length !== 0 &&
    orgName &&
    user?.orgShortName.toLowerCase() !== orgName.toLowerCase() &&
    !user?.roles.includes(UserRole.SUPERADMIN)
  ) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? (
    <>
      <NavBar
        user={user}
        setSantaAction={setSantaAction}
        setUser={setUser}
        allowedRoles={allowedRoles}
        formDirty={formDirty}
        setClicked={setClicked}
      />
      {children}
    </>
  ) : (
    <Outlet />
  );
};

export default RouteGuard;
