import {
  Dispatch,
  Fragment,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import styled from 'styled-components';
import { useFormik } from 'formik';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import InfoIcon from '@mui/icons-material/Info';
import Checkbox from '@mui/material/Checkbox';
import { useNavigate } from 'react-router-dom';
import { alpha } from '@mui/material';
import moment from 'moment';

import { COLORS } from '../../styles/colors';
import { FONTS } from '../../styles/fonts';
import { secondaryButtonTheme } from '../../styles/themes';
import { WEIGHTS } from '../../styles/weights';
import { StyledButton } from '../common/StyledButton';
import AlertSnackbar from '../alerts/AlertSnackbar';
import AlertDialog from '../alerts/AlertDialog';
import DraggableMap from '../map/DraggableMap';
import { OrganisationSeasonContext } from '../../App';
import { addOrder } from './utilities/addOrder';
import { geocoding } from './utilities/geocoding';
import FormTextField from './FormTextField';
import { NewOrderPdfDetails } from '../../types/newOrderPdfDetails';

const MAP_TOKEN = process.env.REACT_APP_MAP_TOKEN;

const OrderFormCardContainer = styled.div<{ cantOrder?: boolean }>`
  margin-left: calc(100% / 6);
  margin-right: calc(100% / 6);
  margin-bottom: 100px;
  margin-top: ${props => (props.cantOrder ? '100px' : '0px')};
  padding: 25px;
  padding-top: ${props => (props.cantOrder ? '60px' : '40px')};
  padding-bottom: ${props => (props.cantOrder ? '300px' : '50px')};
  color: ${COLORS.brightGreen};
  background-color: ${COLORS.white};
  @media (max-width: 768px) {
    margin-left: 0%;
    margin-right: 0%;
    margin-bottom: 0px;
    margin-top: ${props => (props.cantOrder ? '100px' : '0px')};
  }
`;

const OrderFormCardTopContainer = styled.div`
  width: 100%;
  display: flex;
  flex-dirextion: row;
  justify-content: space-between;
`;

const OrderFormCardHeader = styled.h2<{ fontSize: number; marginBottom?: boolean }>`
  font-family: ${FONTS.Lora};
  font-weight: ${WEIGHTS.medium};
  font-size: ${props => props.fontSize}px;
  letter-spacing: 0.33px;
  margin-top: 50px;
  margin-bottom: ${props => (props.marginBottom ? '40px' : '0px')};
`;

const TextFieldSetBox = styled.div<{ childrenStyle: boolean }>`
  width: 100%;
  background-color: ${props => (props.childrenStyle ? COLORS.lightGrey : COLORS.white)};
  border: ${props => (props.childrenStyle ? `1.5px solid ${COLORS.mediumGrey}` : '')};
  padding: ${props => (props.childrenStyle ? '10px' : '')};
`;

const FieldRow = styled.div<{ activeStep: number }>`
  display: flex;
  flex-wrap: wrap;
  row-gap: 25px;
  column-gap: 10px;
  margin-bottom: ${props => (props.activeStep > 0 ? '14px' : '28px')};
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FieldColumn = styled.div`
  flex: 1;
  flex-wrap: wrap;
`;

const CheckboxRow = styled.div`
  width: 100%;
  height: 25px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`;

const CheckboxText = styled.div`
  font-family: ${FONTS.Karla};
  font-weight: ${WEIGHTS.regular};
  font-size: 14px;
`;

const MapContainer = styled.div`
  width: 100%;
  height: 280px;
  margin-top: 20px;
  background-color: ${COLORS.lightGrey};
  border: 2px solid ${COLORS.mediumGrey};
`;

const MapInfoText = styled.p`
  font-family: ${FONTS.Karla};
  font-weight: ${WEIGHTS.regular};
  font-size: 14px;
`;

const ChildrenButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-dirextion: row;
  justify-content: space-between;
`;

const ChildrenButton = styled.button`
  width: 110px;
  height: 20px;
  font-family: ${FONTS.Karla};
  font-weight: ${WEIGHTS.bold};
  font-size: 14px;
  color: ${COLORS.brightGreen};
  border: ${COLORS.white};
  background-color: ${COLORS.white};
  text-transform: uppercase;
`;

const OrderFormCardBottomContainer = styled.div`
  width: 100%;
  display: flex;
  flex-dirextion: row;
  justify-content: right;
`;

const checkboxStyle = {
  color: COLORS.brightGreen,
  '&.Mui-checked': {
    color: COLORS.brightGreen,
  },
  '&.Mui-disabled': {
    color: alpha(COLORS.brightGreen2, 0.4),
  },
};

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const initialTextFields = {
  subscriberDetails: [
    [{ label: 'name', required: true }],
    [{ label: 'phone', required: true }],
    [{ label: 'email', required: true }],
  ],
  christmasEveVisitDetails: [
    [
      { label: 'doorname', required: true },
      { label: 'address', required: true },
    ],
    [
      { label: 'postcode', required: true },
      { label: 'city', required: true },
    ],
  ],
  timeWishDetails: [
    [
      { label: 'wishEarliest', required: true },
      { label: 'wishLatest', required: true },
    ],
  ],
  childrenDetails: [
    [
      { label: 'name', required: false, id: 0 },
      { label: 'age', required: false, id: 0 },
    ],
    [{ label: 'goodToKnow', required: false, id: 0 }],
  ],
  additionalDetails: [
    [{ label: 'routeInfo', required: false }],
    [{ label: 'doorCode', required: false }],
    [{ label: 'giftInfo', required: false }],
    [{ label: 'otherInfo', required: false }],
  ],
};

interface Props {
  activeStep: number;
  setActiveStep: Dispatch<SetStateAction<number>>;
  setPdfDetails: Dispatch<SetStateAction<NewOrderPdfDetails>>;
  setFormDirty: Dispatch<SetStateAction<boolean>>;
  homepageClicked: boolean;
  setHomepageClicked: Dispatch<SetStateAction<boolean>>;
}

const OrderFormCard: React.FC<Props> = ({
  activeStep,
  setActiveStep,
  setPdfDetails,
  setFormDirty,
  homepageClicked,
  setHomepageClicked,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'orderForm' });

  const { openSeason } = useContext(OrganisationSeasonContext);

  const navigate = useNavigate();
  const apolloClient = useApolloClient();

  const getTimeWishes = useCallback((start: string | undefined, end: string | undefined) => {
    const startTime = moment(start, 'HH:mm');
    const endTime = moment(end, 'HH:mm');
    const timeWishes: string[] = [];

    while (startTime <= endTime) {
      timeWishes.push(moment(startTime).format('HH:mm'));
      startTime.add(1, 'hour');
    }
    return timeWishes;
  }, []);

  const [childrenAmount, setChildrenAmount] = useState(1);
  const [showError, setShowError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [fieldVariant, setFieldVariant] = useState('outlined');
  const [textFields, setTextFields] = useState(initialTextFields);
  const [longLat, setLongLat] = useState({ long: 0, lat: 0 });
  const [timeWishes, setTimeWishes] = useState<string[]>(getTimeWishes('00:00', '23:00'));

  useEffect(() => {
    if (openSeason) {
      setTimeWishes(getTimeWishes(openSeason.minStartTime, openSeason.maxStartTime));
      if (openSeason) {
        formik.setFieldValue('wishEarliest', openSeason.minStartTime.slice(0, 5));
        formik.setFieldValue('wishLatest', openSeason.maxStartTime.slice(0, 5));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTimeWishes, openSeason]);

  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
      email: '',
      doorname: '',
      address: '',
      postcode: '',
      city: '',
      wishEarliest: openSeason!.minStartTime.slice(0, 5),
      wishLatest: openSeason!.maxStartTime.slice(0, 5),
      children: [{ id: 0, name: '', age: '', goodToKnow: '' }],
      meetOutside: false,
      routeInfo: '',
      doorCode: '',
      giftInfo: '',
      otherInfo: '',
      annualMessages: false,
    },
    validateOnChange: true,
    validateOnBlur: false,
    validationSchema: Yup.object({
      name: Yup.string()
        .matches(/^[aA-öÖ'`´(/)"-\s]+$/, t('errorAlphabets'))
        .min(2, t('errorName'))
        .max(50, t('errorName'))
        .required(t('errorName')),
      phone: Yup.string().matches(phoneRegExp, t('errorPhone')).required(t('errorPhone')),
      email: Yup.string().email(t('errorEmail')).required(t('errorEmail')),
      doorname: Yup.string()
        .matches(/^[a-öA-Ö0-9'`´(/)"-\s]+$/, t('errorAlphabetsAndNumbers'))
        .min(2, t('errorDoorname'))
        .max(50, t('errorDoorname'))
        .required(t('errorDoorname')),
      address: Yup.string()
        .matches(/^[a-öA-Ö0-9'.,`´(/)"-\s]+$/, t('errorAlphabetsAndNumbers'))
        .min(2, t('errorAddress'))
        .required(t('errorAddress')),
      postcode: Yup.number()
        .typeError(t('errorNumbers'))
        .positive(t('errorPostcode'))
        .integer(t('errorPostcode'))
        .required(t('errorPostcode')),
      city: Yup.string()
        .matches(/^[aA-öÖ'`´(/)"-\s]+$/, t('errorAlphabets'))
        .min(2, t('errorCity'))
        .required(t('errorCity')),
      wishEarliest: Yup.string().required(t('errorEarliest')),
      wishLatest: Yup.string().required(t('errorLatest')),
    }),
    onSubmit: async () => {
      if (activeStep === 0) {
        setActiveStep(prevState => prevState + 1);
        setFieldVariant('standard');
        setShowAlert(false);
      } else if (activeStep === 1) {
        setActiveStep(prevState => prevState + 1);
        try {
          await addOrder(formik.values, longLat, openSeason!, apolloClient);
          setPdfDetails({
            order: {
              name: formik.values.name,
              phone: formik.values.phone,
              email: formik.values.email,
              doorname: formik.values.doorname,
              address: formik.values.address,
              postcode: formik.values.postcode,
              city: formik.values.city,
              wishEarliest: formik.values.wishEarliest,
              wishLatest: formik.values.wishLatest,
              children: formik.values.children,
              meetOutside: formik.values.meetOutside,
              routeInfo: formik.values.routeInfo,
              doorCode: formik.values.doorCode,
              giftInfo: formik.values.giftInfo,
              otherInfo: formik.values.otherInfo,
              map: `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-l+306e57(${longLat.long},${longLat.lat})/${longLat.long},${longLat.lat},16/900x270?access_token=${MAP_TOKEN}`,
            },
          });
          setActiveStep(prevState => prevState + 1);
        } catch {
          setActiveStep(0);
          setShowAlert(true);
          setFieldVariant('outlined');
        }
      }
    },
  });

  useEffect(() => {
    if (formik.dirty && activeStep < 2) {
      setFormDirty(true);
    } else {
      setFormDirty(false);
    }
  }, [activeStep, formik.dirty, setFormDirty]);

  const addChildren = () => {
    formik.setFieldValue('children', [
      ...formik.values['children'],
      { id: formik.values['children'].length, name: '', age: '', goodToKnow: '' },
    ]);

    const fields = textFields;
    fields.childrenDetails.push(
      [
        { label: 'name', required: false, id: childrenAmount },
        { label: 'age', required: false, id: childrenAmount },
      ],
      [{ label: 'goodToKnow', required: false, id: childrenAmount }]
    );
    setTextFields(fields);

    setChildrenAmount(prevState => prevState + 1);
  };

  const removeChildren = () => {
    formik.setFieldValue('children', [
      ...formik.values['children'].slice(0, formik.values['children'].length - 1),
    ]);

    const fields = textFields;
    fields.childrenDetails.splice(fields.childrenDetails.length - 2, 2);
    setTextFields(fields);

    setChildrenAmount(prevState => prevState - 1);
  };

  useEffect(() => {
    if (
      formik.values['wishEarliest'] &&
      formik.values['wishLatest'] &&
      formik.values['wishEarliest'] >= formik.values['wishLatest']
    ) {
      formik.setFieldValue(
        'wishLatest',
        timeWishes[timeWishes.indexOf(formik.values['wishEarliest']) + 1]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.wishEarliest]);

  useEffect(() => {
    if (formik.values.address && formik.values.postcode && formik.values.city) {
      const fetchData = async () => {
        const result = await geocoding(
          `${formik.values.address} ${formik.values.city} ${formik.values.postcode}`
        );
        setLongLat({ long: result.geometry.coordinates[0], lat: result.geometry.coordinates[1] });
      };

      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.address, formik.values.city, formik.values.postcode]);

  const submitFormHandler = () => {
    scrollTo(0, 0);
    if (activeStep === 0) {
      setShowError(true);
      setShowAlert(true);
    }
    formik.handleSubmit();
  };

  const cancelFormHandler = () => {
    scrollTo(0, 0);
    if (activeStep === 0 && formik.dirty) {
      setShowDialog(true);
    } else if (activeStep === 0) {
      navigate(-1);
    } else if (activeStep === 1) {
      setFieldVariant('outlined');
      setActiveStep(prevState => prevState - 1);
    }
  };

  if (!openSeason) {
    return (
      <OrderFormCardContainer cantOrder={true}>
        <OrderFormCardHeader fontSize={20}>{t('cantOrder')}</OrderFormCardHeader>
      </OrderFormCardContainer>
    );
  }

  return (
    <OrderFormCardContainer onClick={() => showAlert && setShowAlert(false)}>
      {
        // Alert if data will be lost after cancel
        (showDialog || homepageClicked) && (
          <AlertDialog
            cancel={() => {
              setShowDialog(false);
              setHomepageClicked(false);
            }}
            ok={() => {
              setHomepageClicked(false);
              navigate(-1);
            }}
            alertHeader={'cancelSure'}
            alertText={'lostData'}
          />
        )
      }
      {
        // Alert if form values are invalid
        showAlert && formik.touched && (
          <AlertSnackbar
            alertType={'error'}
            alertText={'errorForm'}
            close={() => setShowAlert(false)}
            center={true}
          />
        )
      }
      <OrderFormCardTopContainer>
        <OrderFormCardHeader fontSize={33}>
          {activeStep === 0 ? t('orderForm') : t('orderDetails')}
        </OrderFormCardHeader>
        {activeStep === 1 && (
          <div>
            <StyledButton theme={secondaryButtonTheme} onClick={cancelFormHandler}>
              {t('back')}
            </StyledButton>
            <StyledButton type="submit" onClick={submitFormHandler}>
              {t('order')}
            </StyledButton>
          </div>
        )}
      </OrderFormCardTopContainer>

      {Object.keys(textFields).map(key => (
        <Fragment key={key}>
          <OrderFormCardHeader fontSize={22} marginBottom={true}>
            {t(key)}
          </OrderFormCardHeader>
          {key == 'additionalDetails' && (
            <CheckboxRow>
              <Checkbox
                id="meetOutside"
                name="meetOutside"
                disabled={activeStep > 0}
                onChange={() => formik.setFieldValue('meetOutside', !formik.values['meetOutside'])}
                sx={checkboxStyle}
              />
              <CheckboxText>{t('meetOutside')}</CheckboxText>
            </CheckboxRow>
          )}
          <TextFieldSetBox childrenStyle={key === 'childrenDetails' && activeStep > 0}>
            {textFields[key].map(
              (values: { label: string; required: boolean; id?: number }[], index: number) => (
                <FieldRow key={index} activeStep={activeStep}>
                  {values.map(field => (
                    <Fragment key={field.label + index}>
                      <FieldColumn>
                        <FormTextField
                          selected={field.label === 'wishEarliest' || field.label === 'wishLatest'}
                          fieldVariant={fieldVariant}
                          fieldLabel={field.label}
                          fieldId={field.id}
                          required={field.required}
                          activeStep={activeStep}
                          setFieldValue={formik.setFieldValue}
                          values={formik.values}
                          touched={formik.touched[field.label]}
                          errors={formik.errors[field.label]}
                          timeWishes={timeWishes}
                          fieldSetKey={key}
                          showError={showError}
                        />
                      </FieldColumn>
                      {field.label == 'otherInfo' && (
                        <>
                          <CheckboxRow key={'checkboxrowAnnualMessages' + field.label}>
                            <Checkbox
                              id="annualMessages"
                              name="annualMessages"
                              disabled={activeStep > 0}
                              onChange={() =>
                                formik.setFieldValue(
                                  'annualMessages',
                                  !formik.values['annualMessages']
                                )
                              }
                              sx={checkboxStyle}
                            />
                            <CheckboxText>{t('annualMessages')}</CheckboxText>
                          </CheckboxRow>
                        </>
                      )}
                    </Fragment>
                  ))}
                </FieldRow>
              )
            )}
          </TextFieldSetBox>
          {key == 'timeWishDetails' && activeStep === 3 && (
            <MapInfoText>{t('timeInfo')}</MapInfoText>
          )}
          {key == 'childrenDetails' && activeStep === 0 && (
            <ChildrenButtonContainer>
              <ChildrenButton onClick={addChildren}>
                <AddCircleOutlineOutlinedIcon
                  sx={{ fontSize: 15, marginBottom: -0.3, marginRight: 1 }}
                />
                {t('addNew')}
              </ChildrenButton>
              {childrenAmount > 1 && (
                <ChildrenButton onClick={removeChildren}>{t('remove')}</ChildrenButton>
              )}
            </ChildrenButtonContainer>
          )}
          {key == 'christmasEveVisitDetails' && (
            <>
              <MapContainer>
                {!formik.values['address'] ||
                !formik.values['postcode'] ||
                !formik.values['city'] ? (
                  <MapInfoText>
                    <InfoIcon
                      sx={{
                        color: COLORS.green2,
                        marginLeft: 3,
                        marginRight: 1.5,
                        marginTop: 2,
                        marginBottom: -0.7,
                      }}
                    />
                    {t('addChristamasEvesVisitDetails')}
                  </MapInfoText>
                ) : (
                  <DraggableMap
                    longLat={longLat}
                    setLongLat={setLongLat}
                    noButtons={activeStep !== 0}
                  />
                )}
              </MapContainer>
              {formik.values['address'] &&
                formik.values['postcode'] &&
                formik.values['city'] &&
                activeStep < 1 && <MapInfoText>{t('mapInfo')}</MapInfoText>}
            </>
          )}
        </Fragment>
      ))}

      {activeStep < 3 && (
        <OrderFormCardBottomContainer>
          <StyledButton theme={secondaryButtonTheme} onClick={cancelFormHandler}>
            {activeStep === 0 ? t('cancel') : t('back')}
          </StyledButton>
          <StyledButton type="submit" onClick={submitFormHandler}>
            {t('order')}
          </StyledButton>
        </OrderFormCardBottomContainer>
      )}
    </OrderFormCardContainer>
  );
};

export default OrderFormCard;
