import React, { useEffect, useState } from 'react';
import { matchPath, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import RouteGuard, { User } from './components/utilities/RouteGuard';
import {
  ChristmasStatus,
  Organisation,
  Season,
  useOrganisationSeasonsLazyQuery,
  useOrganisationWithShortNameLazyQuery,
  UserRole,
} from './graphql/generated';
import { Admin, Home, MapView, OrderForm, Schedules, GlobalHomepage } from './pages/index';
import { LoginHandler } from './pages/authentication/LoginHandler';
import SantasView from './pages/santasView';
import { getUser } from './utils/getUser';
import SuperAdmin from './pages/superAdmin';

const MainPage = styled.main`
  height: calc(100% - 45px);
  width: 100%;
`;

interface OrganisationSeasonContext {
  openSeason: Season | undefined;
  closedSeason: Season | undefined;
  organisation: Organisation;
  user: User | null;
}

export const OrganisationSeasonContext = React.createContext<OrganisationSeasonContext>(
  {} as OrganisationSeasonContext
);

const App: React.FC = () => {
  const [user, setUser] = useState<User | null>(getUser());
  const [santaAction, setSantaAction] = useState('');
  const [formDirty, setFormDirty] = useState(false);
  const [homepageClicked, setHomepageClicked] = useState(false);
  const location = useLocation();
  const match = matchPath({ path: '/:orgName/*' }, location.pathname);
  const orgName =
    match?.params.orgName !== 'login' && match?.params.orgName !== 'admin'
      ? match?.params.orgName
      : null;

  const [
    getOrganisation,
    { data: organisationData, variables: organisationVariables, loading: organisationLoading },
  ] = useOrganisationWithShortNameLazyQuery();
  const [getSeasons, { data: seasonsData }] = useOrganisationSeasonsLazyQuery();

  useEffect(() => {
    if (orgName) {
      getOrganisation({ variables: { shortName: orgName } });
    }
  }, [orgName, getOrganisation]);

  useEffect(() => {
    if (organisationData && organisationData.organisationWithShortName) {
      getSeasons({
        variables: { organisationId: organisationData.organisationWithShortName.id },
      });
    }
  }, [organisationData, getSeasons]);

  if ((orgName && organisationVariables?.shortName !== orgName) || organisationLoading) {
    return null;
  }

  if (orgName && !organisationLoading && !organisationData?.organisationWithShortName) {
    return <Navigate to="/" replace={true} />;
  }

  const seasons: Array<Season> | undefined = seasonsData?.organisationSeasons;
  const openSeason: Season | undefined = seasons?.find(
    season => season.christmasStatus === ChristmasStatus.Open
  );
  const closedSeason: Season | undefined =
    !openSeason && seasons ? [...seasons].sort((s1, s2) => s2.year - s1.year)[0] : undefined;

  if (organisationData) {
    return (
      <MainPage>
        <OrganisationSeasonContext.Provider
          value={{
            openSeason,
            closedSeason,
            organisation: organisationData.organisationWithShortName,
            user,
          }}>
          <Routes>
            <Route
              path="/:orgName/admin"
              element={
                <RouteGuard
                  setSantaAction={setSantaAction}
                  setUser={setUser}
                  user={user}
                  allowedRoles={[UserRole.ORG_ADMIN, UserRole.ORG_USER, UserRole.SUPERADMIN]}>
                  <Schedules />
                </RouteGuard>
              }
            />
            <Route
              path="/:orgName/admin/map"
              element={
                <RouteGuard
                  setSantaAction={setSantaAction}
                  setUser={setUser}
                  user={user}
                  allowedRoles={[UserRole.ORG_ADMIN, UserRole.ORG_USER, UserRole.SUPERADMIN]}>
                  <MapView />
                </RouteGuard>
              }
            />
            <Route
              path="/:orgName/admin/manage"
              element={
                <RouteGuard
                  setSantaAction={setSantaAction}
                  setUser={setUser}
                  allowedRoles={[UserRole.SUPERADMIN, UserRole.ORG_ADMIN]}
                  user={user}>
                  <Admin />
                </RouteGuard>
              }
            />
            <Route
              path="/:orgName/santa"
              element={
                <RouteGuard
                  setSantaAction={setSantaAction}
                  setUser={setUser}
                  allowedRoles={[
                    UserRole.SUPERADMIN,
                    UserRole.SANTA,
                    UserRole.ORG_ADMIN,
                    UserRole.ORG_USER,
                  ]}
                  user={user}>
                  <SantasView santaAction={santaAction} setSantaAction={setSantaAction} />
                </RouteGuard>
              }
            />
            <Route
              path="/:orgName/santa/:santaId"
              element={
                <RouteGuard
                  setSantaAction={setSantaAction}
                  setUser={setUser}
                  allowedRoles={[
                    UserRole.SUPERADMIN,
                    UserRole.SANTA,
                    UserRole.ORG_ADMIN,
                    UserRole.ORG_USER,
                  ]}
                  user={user}>
                  <SantasView santaAction={santaAction} setSantaAction={setSantaAction} />
                </RouteGuard>
              }
            />
            <Route
              path="/:orgName"
              element={
                <RouteGuard
                  setSantaAction={setSantaAction}
                  setUser={setUser}
                  allowedRoles={[]}
                  user={user}>
                  <Home />
                </RouteGuard>
              }
            />
            <Route
              path="/:orgName/order"
              element={
                <RouteGuard
                  setSantaAction={setSantaAction}
                  setUser={setUser}
                  allowedRoles={[]}
                  user={user}
                  formDirty={formDirty}
                  setClicked={setHomepageClicked}>
                  <OrderForm
                    setFormDirty={setFormDirty}
                    homepageClicked={homepageClicked}
                    setHomepageClicked={setHomepageClicked}
                  />
                </RouteGuard>
              }
            />
            <Route
              path="/"
              element={
                <RouteGuard
                  setSantaAction={setSantaAction}
                  setUser={setUser}
                  allowedRoles={[]}
                  user={user}>
                  <GlobalHomepage />
                </RouteGuard>
              }
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </OrganisationSeasonContext.Provider>
      </MainPage>
    );
  } else {
    return (
      <MainPage>
        <Routes>
          <Route path="/login" element={<LoginHandler setUser={setUser} />} />
          <Route path="/login/expired" element={<LoginHandler setUser={setUser} />} />
          <Route
            path="/admin"
            element={
              <RouteGuard
                setSantaAction={setSantaAction}
                setUser={setUser}
                allowedRoles={[UserRole.SUPERADMIN]}
                user={user}>
                <SuperAdmin />
              </RouteGuard>
            }
          />
          <Route
            path="/"
            element={
              <RouteGuard
                setSantaAction={setSantaAction}
                setUser={setUser}
                allowedRoles={[]}
                user={user}>
                <GlobalHomepage />
              </RouteGuard>
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </MainPage>
    );
  }
};

export default App;
