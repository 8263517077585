import { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import CarWithTreeImage from '../../../images/carWithTree.svg';
import { StyledButton } from '../../../components/common/StyledButton';
import {
  CommonContainer as Container,
  CommonTextContainer,
  CommonBoxHeader,
  CommonBoxText,
  CommonImageContainer,
  CommonButtonContainer,
} from './HomepageStyles';
import { OrganisationSeasonContext } from '../../../App';
import { FONTS } from '../../../styles/fonts';
import { FONTSIZE } from '../../../styles/fontsize';
import { WEIGHTS } from '../../../styles/weights';
import { COLORS } from '../../../styles/colors';
import { useNavigate, useParams } from 'react-router-dom';
import { ChristmasStatus } from '../../../graphql/generated';

const FirstBox: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'homepage' });
  const { organisation, openSeason, closedSeason } = useContext(OrganisationSeasonContext);
  const { orgName } = useParams();
  const navigate = useNavigate();

  const openForm = () => {
    navigate(`/${orgName}/order`);
  };

  return (
    <Container>
      <TextContainer>
        <BoxHeader>
          <img src={organisation.logoFile || ''} height={'50px'} />
        </BoxHeader>
        <BoxText>{organisation.frontpageDescription}</BoxText>
        {openSeason ? (
          <ButtonContainer>
            <StyledButton frontPage={true} onClick={openForm}>
              {t('orderSanta')}
            </StyledButton>
          </ButtonContainer>
        ) : (
          <CantOrderText>
            {closedSeason?.christmasStatus === ChristmasStatus.Wait
              ? t('seasonNotStarted')
              : t('cantOrder')}
          </CantOrderText>
        )}
      </TextContainer>
      <ImageContainer>
        <img src={CarWithTreeImage} width={'75%'} height={'auto'} />
      </ImageContainer>
    </Container>
  );
};

const TextContainer = styled(CommonTextContainer)`
  @media (min-width: 768px) {
    padding: 30px 60px 50px 60px;
  }
`;

const BoxHeader = styled(CommonBoxHeader)`
  margin-bottom: 30px;
`;

const BoxText = styled(CommonBoxText)`
  margin-bottom: 20px;
`;

const ImageContainer = styled(CommonImageContainer)`
  flex-direction: column;
  justify-content: flex-end;
  @media (max-width: 768px) {
    padding-left: 0;
    img {
      width: 40%;
    }
  }
`;

const ButtonContainer = styled(CommonButtonContainer)`
  font-size: ${FONTSIZE.content};
  padding-top: 10px;
`;

const CantOrderText = styled.div`
  margin-top: 40px;
  font-family: ${FONTS.Karla};
  font-weight: ${WEIGHTS.regular};
  color: ${COLORS.red};
  letter-spacing: 0.33px;
`;

export default FirstBox;
