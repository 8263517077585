import { DataGrid, GridColDef, GridRowId } from '@mui/x-data-grid';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import {
  Santa,
  Season,
  useRemoveSantaMutation,
  useSeasonSantasLazyQuery,
} from '../../graphql/generated';
import AlertDialog from '../alerts/AlertDialog';
import AlertSnackbar from '../alerts/AlertSnackbar';

const SantaTableWrapper = styled.div`
  display: flex;
  width: 100%;
`;

interface SantaTableProps {
  season: Season;
  setTypeCreate: Dispatch<SetStateAction<string>>;
  setFormOpen: Dispatch<SetStateAction<boolean>>;
  formClosed: boolean;
  setSelectedSanta: Dispatch<SetStateAction<Santa | null>>;
}

const SantaTable: React.FC<SantaTableProps> = ({
  season,
  setTypeCreate,
  setFormOpen,
  formClosed,
  setSelectedSanta,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin' });

  const [getSeasonSantas, { data: santasData, loading, refetch }] = useSeasonSantasLazyQuery();
  const [removeSanta] = useRemoveSantaMutation();
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const [santaId, setSantaId] = useState<GridRowId | string>('');

  useEffect(() => {
    if (!formClosed) {
      refetch();
    }
  }, [formClosed, refetch]);

  useEffect(() => {
    if (season) {
      getSeasonSantas({ variables: { seasonId: season.id } });
    }
  }, [season, getSeasonSantas]);

  const editSanta = (id: string | number) => {
    setSelectedSanta(santasData?.seasonSantas.find(santa => santa.id === id) || null);
    setTypeCreate('santaEdit');
    setFormOpen(true);
  };

  const deleteSanta = async id => {
    try {
      await removeSanta({ variables: { id: id } });
    } catch (error) {
      setShowAlert(true);
    }
    setSantaId('');
    setShowAlertDialog(false);
    await refetch();
  };

  const columns: GridColDef[] = [
    { field: 'position', headerName: t('position'), flex: 20 },
    { field: 'name', headerName: t('santasName') },
    { field: 'areaNamePublic', headerName: t('areaNamePublic'), flex: 20 },
    { field: 'areaNameInternal', headerName: t('areaNameInternal'), flex: 20 },
    {
      field: 'showOnFrontpage',
      headerName: t('showOnFrontpage'),
      flex: 20,
      align: 'center',
      renderCell: cellValues => <Icon name={cellValues.row.showOnFrontpage ? 'check' : 'close'} />,
    },
    { field: 'santaPhone', headerName: t('santaPhone'), flex: 20 },
    { field: 'santaEmail', headerName: t('santaEmail'), flex: 20 },
    { field: 'startTime', headerName: t('startTime'), flex: 20 },
    { field: 'endTime', headerName: t('endTime'), flex: 20 },
    {
      field: 'edit',
      headerName: t('edit'),
      flex: 10,
      align: 'center',
      renderCell: cellValues => (
        <Button
          icon
          basic
          onClick={() => {
            setShowAlert(false);
            editSanta(cellValues.id);
          }}>
          <Icon name="pencil" />
        </Button>
      ),
    },
    {
      field: 'delete',
      headerName: t('delete'),
      flex: 10,
      align: 'center',
      renderCell: cellValues => (
        <Button
          icon
          basic
          onClick={() => {
            setSantaId(cellValues.id);
            setShowAlertDialog(true);
          }}>
          <Icon name="close" />
        </Button>
      ),
    },
  ];

  const getRows = () => {
    const rows = santasData?.seasonSantas
      .map(santa => ({
        ...santa,
        startTime: santa.startTime.slice(0, 5),
        endTime: santa.endTime.slice(0, 5),
        editFunction: () => editSanta(santa.id),
      }))
      .sort((a, b) => a.position - b.position);
    return rows || [];
  };

  return (
    <SantaTableWrapper>
      {!loading && (
        <>
          {showAlertDialog && (
            <AlertDialog
              cancel={() => {
                setShowAlertDialog(false);
                setSantaId('');
              }}
              ok={() => {
                deleteSanta(santaId);
              }}
              alertHeader={'deleteSantaSure'}
              alertText={'lostSantaData'}
            />
          )}
          {showAlert && (
            <AlertSnackbar
              alertType={'error'}
              alertText={'deleteSantaError'}
              close={() => setShowAlert(false)}
            />
          )}
          <DataGrid
            sx={{
              '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                outline: 'none !important',
              },
            }}
            disableColumnMenu
            rows={getRows()}
            columns={columns}
            pageSize={25}
            rowsPerPageOptions={[25]}
            autoHeight
            disableSelectionOnClick
          />
        </>
      )}
    </SantaTableWrapper>
  );
};

export default SantaTable;
