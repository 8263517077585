import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import InfoIcon from '@mui/icons-material/Info';

import { OrderContext } from '../../pages/mapView';
import { COLORS } from '../../styles/colors';
import { FONTS } from '../../styles/fonts';
import { WEIGHTS } from '../../styles/weights';
import StyledCardText from '../common/StyledCardText';
import StyledHr from '../common/StyledHr';
import SimpleTextField from '../common/SimpleTextField';
import { StyledButton } from '../common/StyledButton';
import { secondaryButtonTheme } from '../../styles/themes';
import {
  useUpdateOrderMutation,
  useCreateEmailMutation,
  EmailType,
  useSeasonSantasQuery,
  useSeasonOrdersQuery,
  Order,
  Santa,
} from '../../graphql/generated';
import { orderStatus } from '../../types/order';
import AlertSnackbar from '../alerts/AlertSnackbar';
import { OrganisationSeasonContext } from '../../App';
import { generateTimeSlots } from '../../utils/timeUtil';
import { findOrderAtTimeSlot } from '../../utils/orderUtil';
import moment from 'moment';

const StyledConfirmOrderContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background: ${COLORS.white};
  padding-top: 15px;
  overflow-y: hidden;
`;

const StyledHeader = styled.div`
  font-family: ${FONTS.Lora};
  font-weight: ${WEIGHTS.medium};
  font-size: 20px;
  color: ${COLORS.brightGreen};
  display: flex;
  align-items: center;
  margin: 15px;
  margin-bottom: 40px;
`;

const StyledSubscriberHeader = styled.div`
  margin-top: 22px;
`;

const StyledTextfieldContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  column-gap: 10px;
  margin: 15px;
  margin-top: 25px;
  margin-bottom: 25px;
`;

const StyledTextfieldColumn = styled.div<{ size: number }>`
  display: flex;
  flex-direction: column;
  flex: ${props => props.size};
`;

const StyledTransferInfo = styled.div`
  height: 100px;
  font-family: ${FONTS.Karla};
  font-weight: ${WEIGHTS.regular};
  font-size: 16px;
  color: ${COLORS.brightGreen};
  background-color: ${COLORS.lightGrey};
  border: 1px solid ${COLORS.mediumGrey};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px;
  margin-top: 40px;
  padding: 25px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
`;

const regularCard = (data: string, color = COLORS.brightGreen) => (
  <StyledCardText
    display={'inline-block'}
    font={FONTS.Karla}
    weight={WEIGHTS.regular}
    size={14}
    color={color}>
    {data}
  </StyledCardText>
);

const mediumCard = (data: string, marginBottom = 0) => (
  <StyledCardText
    display={'inline-block'}
    font={FONTS.Lora}
    weight={WEIGHTS.medium}
    size={16}
    color={COLORS.brightGreen}
    marginBottom={marginBottom}>
    {data}
  </StyledCardText>
);

const MenuProps = {
  PaperProps: {
    sx: {
      '&::-webkit-scrollbar': {
        width: '0.3em',
      },
      '&.MuiMenu-paper.MuiPopover-paper': {
        maxHeight: '50vh',
      },
      '& .Mui-selected': {
        backgroundColor: COLORS.lightGreen,
        '&:hover': {
          backgroundColor: COLORS.lightGreen,
        },
      },
    },
  },
};

type timeSlotData = { timeSlot: string; address: string | undefined };

type santaData = {
  santa: Santa;
  textFieldValue: string;
  visitingHours: string[];
  freeTimes: number;
  firstSuitable: string;
};

interface Props {
  setChangeStatusWindowOpen: Dispatch<SetStateAction<boolean>>;
  typeOfChange: string | null;
  setShowAlert: Dispatch<SetStateAction<boolean>>;
  setAlertText: Dispatch<SetStateAction<string>>;
}

const EditOrderStatus: React.FC<Props> = ({
  setChangeStatusWindowOpen,
  typeOfChange,
  setShowAlert,
  setAlertText,
}) => {
  scrollTo(0, 0);
  const { t } = useTranslation('translation', { keyPrefix: 'confirmOrder' });
  const { organisation, openSeason } = useContext(OrganisationSeasonContext);

  const {
    data: dataSantas,
    loading: loadingSantas,
    error: errorSantas,
    refetch: refetchSantas,
  } = useSeasonSantasQuery({
    variables: { seasonId: openSeason?.id || '' },
  });

  const [updateOrderMutation] = useUpdateOrderMutation();
  const [createEmailMutation] = useCreateEmailMutation();

  const { selectedOrder } = useContext(OrderContext);
  const [selectedSanta, setSelectedSanta] = useState('');
  const [emptySantaSubmitted, setEmptySantaSubmitted] = useState(false);
  const [selectedTime, setSelectedTime] = useState('');
  const [emptyTimeSubmitted, setEmptyTimeSubmitted] = useState(false);
  const [message, setMessage] = useState('');
  const [santaData, setSantaData] = useState([] as santaData[]);
  const [santaTextFieldOpen, setSantaTextFielOpen] = useState(false);

  const { data: dataOrders, refetch: refetchOrders } = useSeasonOrdersQuery({
    variables: { seasonId: openSeason?.id || '' },
  });

  // Creates the list that includes all possible visit times.
  // If the time has already been reserved, the list item also has the address.
  const santasVisitingHours = useCallback(
    (santa: Santa): timeSlotData[] => {
      if (openSeason && dataOrders && santa) {
        return generateTimeSlots(santa.startTime, santa.endTime, openSeason.interval).map(
          timeSlot => {
            const order = findOrderAtTimeSlot(
              dataOrders.seasonOrders as Order[],
              timeSlot,
              santa.id
            );
            return {
              timeSlot,
              //street address for normal orders, visitname for breaks and undefined for no order.
              address: order
                ? order.orderStatus === orderStatus.closed
                  ? order.visitName
                  : order.streetAddress
                : undefined,
            };
          }
        );
      }
      return [];
    },
    [dataOrders, openSeason]
  );

  //return first suitable time slot or '' if none are found.
  const findFirstSuitable = useCallback(
    (timeSlots: timeSlotData[]): string =>
      timeSlots
        //A time slot is suitable if wishEarliest <= timeSlot < wishLatest
        .find(
          ({ timeSlot }) =>
            moment(timeSlot, 'HH:mm').isSame(moment(selectedOrder?.wishEarliest, 'HH:mm:ss')) ||
            (moment(timeSlot, 'HH:mm').isAfter(moment(selectedOrder?.wishEarliest, 'HH:mm:ss')) &&
              moment(timeSlot, 'HH:mm').isBefore(moment(selectedOrder?.wishLatest, 'HH:mm:ss')))
        )
        ?.timeSlot.trim() ?? '-',
    [selectedOrder]
  );

  useEffect(() => {
    if (dataSantas?.seasonSantas) {
      const data = dataSantas.seasonSantas.map(santa => {
        // timeSlotData = { timeSlot: string, address: string | undefined }[]
        const timeSlotData = santasVisitingHours(santa);
        const firstSuitable = findFirstSuitable(timeSlotData);
        return {
          santa,
          textFieldValue: `${santa.name} (${santa.areaNamePublic})`,
          visitingHours: timeSlotData.map(data =>
            `${data.timeSlot} ${data.address ? '(' + data.address + ')' : ''}`.trim()
          ),
          freeTimes: timeSlotData.filter(data => !data.address).length,
          firstSuitable,
        };
      });
      data.sort((a, b) => a.santa.position - b.santa.position);
      setSantaData(data);
    }
  }, [dataSantas?.seasonSantas, findFirstSuitable, santasVisitingHours]);

  useEffect(() => {
    if (typeOfChange === 'move') {
      const santa = dataSantas?.seasonSantas.find(item => item.id === selectedOrder?.santaId);
      setSelectedSanta(`${santa?.name} (${santa?.areaNamePublic})`);
      setSelectedTime(
        `${selectedOrder?.confirmedStartAt?.slice(0, 5)} (${selectedOrder?.streetAddress})`
      );
    }
  }, [dataSantas?.seasonSantas, selectedOrder, typeOfChange]);

  if (
    !selectedOrder ||
    (!loadingSantas &&
      typeOfChange !== 'reject' &&
      (errorSantas || !openSeason || santaData.length <= 0))
  ) {
    return (
      <div style={{ margin: '20px' }}>
        <ArrowBackOutlinedIcon
          sx={{ margin: 1, fontSize: '28px', cursor: 'pointer', color: COLORS.brightGreen }}
          onClick={() => setChangeStatusWindowOpen(false)}
        />
        <StyledCardText
          display={'inline-block'}
          font={FONTS.Karla}
          weight={WEIGHTS.regular}
          size={18}
          color={COLORS.brightGreen}>
          {t('noSantas')}
        </StyledCardText>
      </div>
    );
  }

  const handleSantaTextfieldClose = () => {
    setSantaTextFielOpen(false);
  };

  const handleSantaTextfieldOpen = () => {
    setSantaTextFielOpen(true);
  };

  const createEmail = async (emailType: EmailType) => {
    await createEmailMutation({
      variables: {
        input: {
          type: emailType,
          orderId: selectedOrder.id,
          seasonId: selectedOrder.seasonId,
          recipient: selectedOrder.email,
          organisationId: organisation.id,
          extraData: message,
        },
      },
    });
  };

  const confirmButtonHandler = async () => {
    if (typeOfChange === 'reject') {
      await updateOrderMutation({
        variables: {
          id: selectedOrder.id,
          updateInput: {
            santaId: null,
            confirmedStartAt: null,
            orderStatus: orderStatus.rejected,
          },
        },
      });
      createEmail(EmailType.OrderRejected);
    } else if (selectedTime === '') {
      setEmptyTimeSubmitted(true);
      // selectedSanta cannot be empty, if selectedTime is not
      if (selectedSanta === '') {
        setEmptySantaSubmitted(true);
      }
      return;
    } else {
      await updateOrderMutation({
        variables: {
          id: selectedOrder.id,
          updateInput: {
            santaId: santaData.find(item => item.textFieldValue === selectedSanta)?.santa.id,
            confirmedStartAt: selectedTime.slice(0, 5),
            orderStatus: orderStatus.confirmed,
          },
        },
      });
      if (selectedOrder.orderStatus !== orderStatus.confirmed) {
        createEmail(EmailType.OrderConfirmed);
      }
    }
    refetchSantas();
    refetchOrders();
    setChangeStatusWindowOpen(false);
    setShowAlert(true);
    setAlertText(`${typeOfChange}Success`);
    return;
  };

  return (
    <StyledConfirmOrderContainer>
      {typeOfChange !== 'reject' && (emptySantaSubmitted || emptyTimeSubmitted) && (
        <AlertSnackbar
          alertType={'info'}
          alertText={emptySantaSubmitted ? 'selectSanta' : 'selectTime'}
          close={() => [setEmptySantaSubmitted(false), setEmptyTimeSubmitted(false)]}
        />
      )}
      <StyledHeader>
        <ArrowBackOutlinedIcon
          sx={{ marginRight: 2, fontSize: '26px', cursor: 'pointer' }}
          onClick={() => setChangeStatusWindowOpen(false)}
        />
        {typeOfChange === 'confirm' && t('confirmOrder')}
        {typeOfChange === 'move' && t('moveOrder')}
        {typeOfChange === 'reject' && t('rejectOrder')}
      </StyledHeader>
      {mediumCard(
        `${selectedOrder.streetAddress}, ${selectedOrder.postalCode} ${selectedOrder.city}`,
        15
      )}
      {regularCard(selectedOrder.visitName)}

      {typeOfChange === 'move' && (
        <div>
          {regularCard(selectedOrder.phone)}
          {regularCard(
            `${t('confirmedTimeSanta')}: ${selectedOrder.confirmedStartAt?.slice(0, 5)} ${
              dataSantas?.seasonSantas.find(item => item.id === selectedOrder?.santaId)?.name
            }`
          )}
        </div>
      )}
      <div style={{ marginTop: '15px' }}>
        {regularCard(
          `${t('timeWish')}: ${selectedOrder.wishEarliest.slice(
            0,
            5
          )} - ${selectedOrder.wishLatest.slice(0, 5)}`,
          COLORS.darkYellow
        )}
      </div>

      {typeOfChange === 'reject' && (
        <>
          <StyledSubscriberHeader>{mediumCard(t('subscriber'), 5)}</StyledSubscriberHeader>
          {regularCard(selectedOrder.customerName)}
          {regularCard(selectedOrder.phone)}
          {regularCard(selectedOrder.email)}
        </>
      )}
      <StyledHr margin={true} />

      {typeOfChange !== 'reject' && (
        <StyledTextfieldContainer>
          <StyledTextfieldColumn size={2}>
            <SimpleTextField
              select
              required
              id={'selectedSanta'}
              label={`${t('santa')} ${t('and')} ${t('area')}`}
              onChange={event => {
                setSelectedSanta(event.target.value);
                setSelectedTime(
                  santaData.find(item => item.textFieldValue === event.target.value)
                    ?.firstSuitable || ''
                );
                setEmptySantaSubmitted(false);
              }}
              value={selectedSanta}
              error={emptySantaSubmitted}
              helperText={emptySantaSubmitted && 'Pakollinen tieto'}
              FormHelperTextProps={{ style: { margin: 0 } }}
              selectedItems={santaData.map(data => ({
                value: data.textFieldValue,
                disabled: data.freeTimes === 0,
                bold: false,
              }))}
              SelectProps={{
                onOpen: handleSantaTextfieldOpen,
                onClose: handleSantaTextfieldClose,
                MenuProps,
              }}
              showTimeInfo={santaTextFieldOpen}
              timeInfo={[`${t('freeTime')}: `, `${t('mostSuitableTime')}: `]}
              timeData={santaData.map(data => ({
                itemName: `${data.santa.name} (${data.santa.areaNamePublic})`,
                freeTimes: data.freeTimes,
                mostSuitable: data.firstSuitable,
              }))}
            />
          </StyledTextfieldColumn>
          <StyledTextfieldColumn size={1}>
            <SimpleTextField
              select
              required
              id={'selectedTime'}
              label={t('time')}
              onChange={event => {
                setSelectedTime(event.target.value);
                setEmptyTimeSubmitted(false);
              }}
              value={selectedTime}
              error={emptyTimeSubmitted}
              helperText={emptyTimeSubmitted && 'Pakollinen tieto'}
              FormHelperTextProps={{ style: { margin: 0 } }}
              selectedItems={
                santaData
                  .find(item => item.textFieldValue === selectedSanta)
                  ?.visitingHours.map(hour => ({
                    value: hour,
                    disabled: hour.includes('('),
                    bold:
                      hour >= selectedOrder.wishEarliest.slice(0, 5) &&
                      hour <= selectedOrder.wishLatest.slice(0, 5) &&
                      !hour.includes('('),
                  })) || []
              }
              SelectProps={{ MenuProps }}
            />
          </StyledTextfieldColumn>
        </StyledTextfieldContainer>
      )}

      {typeOfChange !== 'move' && (
        <>
          {mediumCard(t('messageCustomer'))}
          <StyledTextfieldContainer>
            <SimpleTextField
              id={'message'}
              label={t('message')}
              placeholder={
                typeOfChange === 'confirm' ? t('writeMessageConfirm') : t('writeMessageReject')
              }
              onChange={event => {
                setMessage(event.target.value);
              }}
              value={message}
              error={false}
              selectedItems={undefined}
              multiline
              rows={12}
              InputLabelProps={{
                style: {
                  fontSize: 18,
                  color: COLORS.green3,
                  fontFamily: FONTS.Karla,
                  fontWeight: WEIGHTS.regular,
                },
                shrink: true,
              }}
            />
          </StyledTextfieldContainer>
        </>
      )}

      {typeOfChange === 'move' && (
        <StyledTransferInfo>
          <InfoIcon sx={{ color: COLORS.green2, marginRight: 1.5, marginBottom: 2.5 }} />
          {t('transferInfo')}
        </StyledTransferInfo>
      )}

      <ButtonContainer>
        <StyledHr />
        <StyledButton float={'right'} type="submit" onClick={confirmButtonHandler}>
          {typeOfChange === 'confirm' && t('confirm')}
          {typeOfChange === 'move' && t('move')}
          {typeOfChange === 'reject' && t('reject')}
        </StyledButton>
        <StyledButton
          float={'right'}
          theme={secondaryButtonTheme}
          onClick={() => setChangeStatusWindowOpen(false)}>
          {t('cancel')}
        </StyledButton>
      </ButtonContainer>
    </StyledConfirmOrderContainer>
  );
};

export default EditOrderStatus;
