import moment, { now } from 'moment';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import {
  Order,
  Santa,
  Season,
  SeasonOrdersQuery,
  useCreateBreakMutation,
  useRemoveOrderMutation,
} from '../../graphql/generated';
import { COLORS } from '../../styles/colors';
import { orderStatus } from '../../types/order';
import DropDown from '../common/DropDown';
import { ApolloQueryResult } from '@apollo/client';
import { createOrder, updateRecord, createBreak } from '../../utils/orderUtil';

interface CellData {
  timeSlot: string;
  santa: Santa;
  order?: Order;
}

interface CellProps {
  cellData: CellData;
  refetchOrders: () => Promise<ApolloQueryResult<SeasonOrdersQuery>>;
  setSelectedOrder: (order: Order) => void;
  season: Season;
}

const Cell: React.FC<CellProps> = ({ setSelectedOrder, season, cellData, refetchOrders }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard' });
  const { order, santa, timeSlot } = cellData;
  const [createBreakService] = useCreateBreakMutation();
  const [removeOrderService] = useRemoveOrderMutation();

  if (
    moment(timeSlot, 'HH:mm').isBefore(moment(santa.startTime, 'HH:mm')) ||
    moment(timeSlot, 'HH:mm').isAfter(moment(santa.endTime, 'HH:mm'))
  ) {
    return <DisabledTableCell />;
  }

  const cellBuilder = () => {
    switch (order?.orderStatus) {
      case orderStatus.confirmed:
        return {
          dropDownFunctions: [],
          cellContent: (
            <div style={{ cursor: 'pointer' }} onClick={() => cellFunctionsHandler('Edit')}>
              <p>{order.visitName}</p> <p>{order?.streetAddress}</p>
            </div>
          ),
        };
      case orderStatus.closed:
        return {
          dropdownFunctions: [{ text: 'removeBreak', key: 'removeBreak' }],
          cellContent: <>{t('closed')}</>,
        };
      default:
        return {
          dropdownFunctions: [
            { text: 'assignBreak', key: 'assignBreak' },
            { text: 'addOrder', key: 'addOrder' },
          ],
          cellContent: <></>,
        };
    }
  };

  const cellFunctionsHandler = async (key: string) => {
    switch (key) {
      case 'removeBreak':
        await removeOrderService({ variables: { id: order!.id } });
        break;
      case 'assignBreak':
        await createBreakService({
          variables: { input: createBreak(santa, timeSlot, t('closed')) },
        });
        break;
      case 'addOrder':
        {
          const newOrder = updateRecord(createOrder(), {
            santaId: santa.id,
            seasonId: santa.seasonId,
            confirmedStartAt: timeSlot,
            createdAt: now().toString(),
            meetOutside: false,
            orderStatus: orderStatus.confirmed,
            price: season.price,
            duration: season.duration,
          });
          setSelectedOrder(newOrder);
        }
        break;
      case 'Edit':
        {
          setSelectedOrder(order!);
        }
        break;
      default:
        console.log('Selected function from dropdown menu not implemented');
        break;
    }
    await refetchOrders();
  };

  const { cellContent, dropdownFunctions } = cellBuilder();
  return (
    <StyledTableCell status={order && order.orderStatus ? order.orderStatus : ''}>
      {cellContent}
      {dropdownFunctions && (
        <div className="dropdown-wrapper">
          <DropDown
            optionArray={dropdownFunctions}
            onArrangeChange={cellFunctionsHandler}
            defaultIcon={<MoreHorizOutlinedIcon />}
          />
        </div>
      )}
    </StyledTableCell>
  );
};

const StyledTableCell = styled.td<{ status: string }>`
  height: 60px;
  width: 200px;
  position: relative;
  color: ${COLORS.brightGreen};

  p {
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  :hover {
    background-color: ${COLORS.lightGreen};
    & .dropdown-wrapper {
      display: inline-block;
    }
  }

  ${({ status }) => {
    if (status === orderStatus.closed) {
      return css`
        color: ${COLORS.darkRed};
        background-color: ${COLORS.lightRed};
        text-align: center;
        :hover {
          background-color: ${COLORS.lightRed};
        }
      `;
    }
    return css``;
  }}
`;

const DisabledTableCell = styled.td`
  height: 60px;
  background-color: ${COLORS.lightGrey2};
  width: 200px;
  position: relative;
`;

export default Cell;
