import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import Map, { Marker, NavigationControl } from 'react-map-gl';
import type { MarkerDragEvent } from 'react-map-gl';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Cursor } from '../../images/cursor.svg';
import { StyledButton } from '../common/StyledButton';
import { secondaryButtonTheme } from '../../styles/themes';

const MAP_TOKEN = process.env.REACT_APP_MAP_TOKEN;

const ButtonContainer = styled.div`
  padding: 5px;
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: right;
  align-items: flex-end;
`;

interface Props {
  longLat: { long: number; lat: number };
  setLongLat: Dispatch<SetStateAction<{ long: number; lat: number }>>;
  noButtons: boolean;
}

const DraggableMap: React.FC<Props> = ({ longLat, setLongLat, noButtons }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'draggableMap' });

  const [map, setMap] = useState<mapboxgl.Map>();
  const [dragMapPin, setDragMapPin] = useState(false);
  const [oldLongLat, setOldLongLat] = useState({ long: 0, lat: 0 });

  const onMarkerDragStart = useCallback((event: MarkerDragEvent) => {
    setLongLat({
      long: event.lngLat.lng,
      lat: event.lngLat.lat,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onMarkerDrag = useCallback((event: MarkerDragEvent) => {
    setLongLat({
      long: event.lngLat.lng,
      lat: event.lngLat.lat,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onMarkerDragEnd = useCallback((event: MarkerDragEvent) => {
    setLongLat({
      long: event.lngLat.lng,
      lat: event.lngLat.lat,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    map && map.flyTo({ center: [longLat.long, longLat.lat], duration: 3000, zoom: 16 });
  }, [longLat.lat, longLat.long, map]);

  const primaryClickHandler = () => {
    setOldLongLat(longLat);
    setDragMapPin(prevState => !prevState);
  };

  const secondaryClickHandler = () => {
    setDragMapPin(false);
    setLongLat(oldLongLat);
  };

  return (
    <Map
      ref={ref => ref && setMap(ref?.getMap())}
      initialViewState={{
        longitude: longLat.long,
        latitude: longLat.lat,
        zoom: 16,
      }}
      mapStyle="mapbox://styles/mapbox/streets-v11"
      attributionControl={false}
      mapboxAccessToken={MAP_TOKEN}
      boxZoom={dragMapPin}
      scrollZoom={dragMapPin}
      dragPan={dragMapPin}
      dragRotate={dragMapPin}
      keyboard={dragMapPin}
      doubleClickZoom={dragMapPin}
      touchZoomRotate={dragMapPin}>
      <Marker
        longitude={longLat.long}
        latitude={longLat.lat}
        anchor="bottom"
        draggable={dragMapPin}
        onDragStart={onMarkerDragStart}
        onDrag={onMarkerDrag}
        onDragEnd={onMarkerDragEnd}>
        <Cursor />
      </Marker>

      {!noButtons && (
        <ButtonContainer>
          {dragMapPin && (
            <StyledButton onClick={secondaryClickHandler} theme={secondaryButtonTheme}>
              {t('cancel')}
            </StyledButton>
          )}
          <StyledButton onClick={primaryClickHandler}>
            {dragMapPin ? t('save') : t('edit')}
          </StyledButton>
        </ButtonContainer>
      )}

      <NavigationControl />
    </Map>
  );
};

export default DraggableMap;
