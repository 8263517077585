import { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownItem, DropdownMenu } from 'semantic-ui-react';
import styled from 'styled-components';
import { OrganisationSeasonContext } from '../../App';
import { Season, useOrganisationSeasonsQuery } from '../../graphql/generated';
import { COLORS } from '../../styles/colors';
import { FONTS } from '../../styles/fonts';
import { WEIGHTS } from '../../styles/weights';
import { StyledButton } from '../common/StyledButton';
import SeasonStatus from './SeasonStatus';
import { StyledInfoCard, StyledInfoCardHeader } from './StyledInfoCard';

interface SeasonCardProps {
  selectedSeason: Season | undefined;
  setSelectedSeason: Dispatch<SetStateAction<Season | undefined>>;
  setTypeCreate: Dispatch<SetStateAction<string>>;
  setFormOpen: Dispatch<SetStateAction<boolean>>;
  formClosed: boolean;
}

const StyledSeasonInfo = styled(StyledInfoCard)`
  align-self: start;
`;

const ContentWrapper = styled.div`
  p {
    row-gap: 8px;
  }
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const CardFooterWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-top: 10px;
`;

const StyledDropdown = styled(Dropdown)`
  font-family: ${FONTS.Karla} !important;
  font-size: 13px;
  font-weight: ${WEIGHTS.regular};
  color: ${COLORS.brightGreen} !important;
`;

const DropDownWrapper = styled.div`
  display: flex;
  background-color: ${COLORS.white};
  border-radius: 25px;
  align-items: flex-end;
`;

const SeasonCard: React.FC<SeasonCardProps> = ({
  selectedSeason,
  setSelectedSeason,
  setTypeCreate,
  setFormOpen,
  formClosed,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin' });
  const { organisation, openSeason, closedSeason } = useContext(OrganisationSeasonContext);
  const { data: seasonsData, refetch: seasonsRefetch } = useOrganisationSeasonsQuery({
    variables: { organisationId: organisation.id },
  });

  useEffect(() => {
    if (openSeason || closedSeason) {
      setSelectedSeason(openSeason || closedSeason);
    }
  }, [seasonsData, openSeason, setSelectedSeason, closedSeason]);

  useEffect(() => {
    if (!formClosed) {
      seasonsRefetch();
    }
  }, [formClosed, seasonsRefetch]);

  return (
    <StyledSeasonInfo>
      <StyledInfoCardHeader>
        <h1>{t('seasons')}</h1>
      </StyledInfoCardHeader>
      <RowWrapper>
        <DropDownWrapper>
          <StyledDropdown
            item
            simple
            text={`${t('season')}: ${selectedSeason?.year || t('noSeasonSelected')}`}>
            <DropdownMenu>
              {seasonsData?.organisationSeasons.map(season => (
                <DropdownItem key={season.id} onClick={() => setSelectedSeason(season)}>
                  {season.year}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </StyledDropdown>
        </DropDownWrapper>
        {selectedSeason && <SeasonStatus seasonStatus={selectedSeason.christmasStatus!} />}
      </RowWrapper>
      {selectedSeason && (
        <ContentWrapper>
          <p>{`${t('year')} ${selectedSeason.year}`}</p>
          <p>{`${t('price')} ${selectedSeason.price} €`}</p>
          <p>{`${t('duration')} ${selectedSeason.duration} min`}</p>
          <p>{`${t('interval')} ${selectedSeason.interval} min`}</p>
          <p>{`${t('minStartTime')} ${selectedSeason.minStartTime.slice(0, 5)}`}</p>
          <p>{`${t('maxStartTime')} ${selectedSeason.maxStartTime.slice(0, 5)}`}</p>
        </ContentWrapper>
      )}
      <CardFooterWrapper>
        <StyledButton
          onClick={() => {
            setTypeCreate('seasonEdit');
            setFormOpen(true);
          }}>
          {t('edit')}
        </StyledButton>
        <StyledButton
          onClick={() => {
            setTypeCreate('season');
            setFormOpen(true);
          }}>
          {t('createSeason')}
        </StyledButton>
      </CardFooterWrapper>
    </StyledSeasonInfo>
  );
};

export default SeasonCard;
