import { Dispatch, SetStateAction, useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import DirectionsOutlinedIcon from '@mui/icons-material/DirectionsOutlined';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import DirectionsCarFilledOutlinedIcon from '@mui/icons-material/DirectionsCarFilledOutlined';
import DoorFrontOutlinedIcon from '@mui/icons-material/DoorFrontOutlined';
import ChildCareOutlinedIcon from '@mui/icons-material/ChildCareOutlined';
import CardGiftcardOutlinedIcon from '@mui/icons-material/CardGiftcardOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';

import { useOrderChildQuery } from '../../../graphql/generated';
import { COLORS } from '../../../styles/colors';
import ExpandableItem from '../../../components/common/ExpandableItem';
import StyledCardText from '../../../components/common/StyledCardText';
import { SantasOrderContext } from '..';
import { WEIGHTS } from '../../../styles/weights';
import { FONTS } from '../../../styles/fonts';

const StyledDetailContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 200px;
  bottom: 0;
  background: ${COLORS.white};
  z-index: 2;
`;

const StyledDetailRow = styled.div<{ last?: boolean }>`
  display: flex;
  align-items: center;
  background: ${COLORS.white};
  border-top: 1px solid ${COLORS.grey};
  border-bottom: ${props => (props.last ? `1px solid ${COLORS.grey}` : '')};
`;

const StyledIconContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
  margin: 15px;
`;

const StyledDetailHeader = styled.span`
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  font-size: 13px;
  font-family: ${FONTS.Karla};
  font-weight: ${WEIGHTS.regular};
  color: ${COLORS.brightGreen};
  margin-left: 15px;
`;

const StyledDetailTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 38px;
  padding-bottom: 10px;
`;

const iconStyle = { marginLeft: 2, color: COLORS.brightGreen, fontSize: 20 };

// Contains detail headers, icons, and params to search the data
const details = [
  {
    header: 'routeInfo',
    icon: <DirectionsCarFilledOutlinedIcon sx={iconStyle} />,
    dataKeys: ['routeInfo'],
  },
  {
    header: 'doorCode',
    icon: <DoorFrontOutlinedIcon sx={iconStyle} />,
    dataKeys: ['doorCode', 'visitName'],
  },
  {
    header: 'children',
    icon: <ChildCareOutlinedIcon sx={iconStyle} />,
    dataKeys: ['name', 'age', 'description'],
  },
  {
    header: 'giftInfo',
    icon: <CardGiftcardOutlinedIcon sx={iconStyle} />,
    dataKeys: ['giftInfo'],
  },
  {
    header: 'additional',
    icon: <InfoOutlinedIcon sx={iconStyle} />,
    dataKeys: ['meetOutside', 'otherInfo'],
  },
];

const OrderCardDetails: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'santasSelectedOrder' });

  const { selectedSantaOrder } = useContext(SantasOrderContext);

  const { data, loading, error } = useOrderChildQuery({
    variables: { orderId: selectedSantaOrder?.id || '' },
  });

  if (loading || error) {
    return <div>{t('loading')}..</div>;
  }

  const childrenDetailHandler = (dataKeys: string[]) =>
    data?.orderChildren.map(child =>
      dataKeys.map((dataKey, index) => (
        <StyledCardText
          key={index}
          font={FONTS.Karla}
          weight={WEIGHTS.regular}
          size={12}
          color={COLORS.brightGreen}
          marginBottom={dataKey === 'description' ? 13 : 0}>
          {t(dataKey)}:&nbsp;{child[dataKey]}
        </StyledCardText>
      ))
    );

  return (
    <StyledDetailContainer>
      <StyledDetailRow>
        <DirectionsOutlinedIcon sx={iconStyle} />
        <StyledDetailHeader>
          {selectedSantaOrder?.streetAddress},&nbsp;{selectedSantaOrder?.postalCode}&nbsp;
          {selectedSantaOrder?.city}
        </StyledDetailHeader>
      </StyledDetailRow>
      <StyledDetailRow>
        <PhoneIphoneOutlinedIcon sx={iconStyle} />
        <StyledDetailHeader>{selectedSantaOrder?.phone}</StyledDetailHeader>
      </StyledDetailRow>
      {details.map((detail, index) => (
        <ExpandableItem
          key={index}
          render={(xprops: { open: boolean; setOpen: Dispatch<SetStateAction<boolean>> }) => (
            <>
              <StyledDetailRow
                last={detail.header === 'additional' && !xprops.open}
                key={index}
                onClick={() => xprops.setOpen(!xprops.open)}>
                {detail.icon}
                <StyledDetailHeader>{t(detail.header)}</StyledDetailHeader>
                <StyledIconContainer>
                  {xprops.open ? (
                    <ExpandLessOutlinedIcon sx={iconStyle} />
                  ) : (
                    <ExpandMoreOutlinedIcon sx={iconStyle} />
                  )}
                </StyledIconContainer>
              </StyledDetailRow>
              {xprops.open && (
                <StyledDetailTextContainer>
                  <>
                    {detail.header === 'children'
                      ? childrenDetailHandler(detail.dataKeys)
                      : detail.dataKeys.map((dataKey, index) => (
                          <StyledCardText
                            key={index}
                            font={FONTS.Karla}
                            weight={WEIGHTS.regular}
                            size={12}
                            color={COLORS.brightGreen}>
                            {t(dataKey)}:&nbsp;
                            {(selectedSantaOrder &&
                              typeof selectedSantaOrder[dataKey] === 'boolean' &&
                              t(selectedSantaOrder[dataKey].toString())) ||
                              (selectedSantaOrder && selectedSantaOrder[dataKey])}
                          </StyledCardText>
                        ))}
                  </>
                </StyledDetailTextContainer>
              )}
            </>
          )}
        />
      ))}
    </StyledDetailContainer>
  );
};

export default OrderCardDetails;
