import { ApolloClient, gql } from '@apollo/client';
import { Season } from '../../../graphql/generated';

export const addOrder = async (
  values: {
    name: string;
    phone: string;
    email: string;
    doorname: string;
    address: string;
    postcode: string;
    city: string;
    wishEarliest: string;
    wishLatest: string;
    children: {
      id: number;
      name: string;
      age: string;
      goodToKnow: string;
    }[];
    meetOutside: boolean;
    routeInfo: string;
    doorCode: string;
    giftInfo: string;
    otherInfo: string;
    annualMessages: boolean;
  },
  longLat: { long: number; lat: number },
  openSeason: Season | undefined,
  apolloClient: ApolloClient<object>
) => {
  const createCustomQuery = () => {
    // Constructs a "fragment" that can be added to createOrder mutation, to create orderChildren
    const buildChildrenQuery = () => {
      const childrenDetails = values.children;
      let createChildrenQuery = '';
      for (let i = 0; i < childrenDetails.length; i++) {
        const child = childrenDetails[i];
        if (child.name) {
          createChildrenQuery += `child${i}: createOrderChildren(
              childInput: { position: ${child.id}, name: """${child.name}""", age: ${
            Number(child.age) || null
          }, description: """${child.goodToKnow}""" }
              ) {
                name
                age
                description
              }`;
        }
      }
      return createChildrenQuery;
    };

    return `
        createOrder(
          orderInput: {
            seasonId: """${openSeason?.id}"""
            wishEarliest: """${values.wishEarliest.replace(':', '')}"""
            wishLatest: """${values.wishLatest.replace(':', '')}"""
            customerName: """${values.name}"""
            streetAddress: """${values.address}"""
            city: """${values.city}"""
            email: """${values.email}"""
            postalCode: """${values.postcode}"""
            visitName: """${values.doorname}"""
            meetOutside: ${values.meetOutside}
            lonlat: """${longLat.long},${longLat.lat}"""
            giftInfo: """${values.giftInfo}"""
            routeInfo: """${values.routeInfo}"""
            otherInfo: """${values.otherInfo}"""
            phone: """${values.phone}"""
            doorCode: """${values.doorCode}"""
            newsletterIsSubscribed: ${values.annualMessages}
            duration: ${openSeason?.duration}
            price: ${openSeason?.price}
          }
        ) {
            id
            customerName
            ${buildChildrenQuery()}
          }
      
      `;
  };
  // Creates a custom query dynamically to include children
  const result = await apolloClient.mutate({
    mutation: gql`
      mutation createOrderWithChildren {
        ${createCustomQuery()}
      }
    `,
  });
  return result;
};
