import { COLORS } from './colors';
import { WEIGHTS } from './weights';

export const secondaryButtonTheme = {
  fontWeight: WEIGHTS.semibold,
  color: COLORS.red,
  background: COLORS.white,
  hoverBackground: COLORS.lightRed,
  hoverBorder: `1px solid ${COLORS.brightRed}`,
  focusBackground: COLORS.white,
  focusBorder: `1px solid ${COLORS.brightRed}`,
  activeBackground: COLORS.lightRed,
  activeBorder: `1px solid ${COLORS.red}`,
};
