import { useTranslation } from 'react-i18next';
import { FormikErrors } from 'formik';
import { StandardTextFieldProps } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';

import SimpleTextField from '../common/SimpleTextField';
import { COLORS } from '../../styles/colors';
import { WEIGHTS } from '../../styles/weights';
import { FONTS } from '../../styles/fonts';

interface formikValues {
  name: string;
  phone: string;
  email: string;
  doorname: string;
  address: string;
  postcode: string;
  city: string;
  wishEarliest: string;
  wishLatest: string;
  children: { id: number; name: string; age: string; goodToKnow: string }[];
  meetOutside: boolean;
  routeInfo: string;
  doorCode: string;
  giftInfo: string;
  otherInfo: string;
  annualMessages: boolean;
}

const propsSelected = {
  MenuProps: {
    PaperProps: {
      sx: {
        '& .Mui-selected': {
          backgroundColor: COLORS.lightGreen,
          '&:hover': {
            backgroundColor: COLORS.lightGreen,
          },
        },
      },
    },
  },
};

interface Props {
  selected: boolean;
  fieldVariant: string;
  fieldLabel: string;
  fieldId: number | undefined;
  required: boolean;
  activeStep: number;
  setFieldValue: (
    field: string,
    value: string | boolean | number,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<formikValues>>;
  values: formikValues;
  touched: boolean | undefined;
  errors: string | undefined;
  timeWishes: string[];
  fieldSetKey: string;
  showError: boolean;
}

const FormTextField: React.FC<Props> = ({
  selected,
  fieldVariant,
  fieldLabel,
  fieldId,
  required,
  activeStep,
  setFieldValue,
  values,
  touched,
  errors,
  timeWishes,
  fieldSetKey,
  showError,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'orderForm' });

  const useStandardLabel: boolean =
    ((fieldLabel === 'wishEarliest' || fieldLabel === 'wishEarliest') && activeStep > 0) ||
    (fieldLabel !== 'goodToKnow' &&
      fieldLabel !== 'routeInfo' &&
      fieldLabel !== 'otherInfo' &&
      fieldLabel !== 'giftInfo' &&
      activeStep > 0);

  const useMultiline: boolean =
    fieldLabel === 'goodToKnow' ||
    fieldLabel === 'routeInfo' ||
    fieldLabel === 'otherInfo' ||
    fieldLabel === 'giftInfo';

  const selectedItemsEarliest = timeWishes.slice(0, timeWishes.length - 1).map(time => ({
    value: time,
    disabled: false,
    bold: false,
  }));

  const selectedItemsLatest = timeWishes.slice(1, timeWishes.length).map(time => ({
    value: time,
    disabled: time <= values['wishEarliest'] || false,
    bold: false,
  }));

  const showHelperText = () => {
    if (fieldLabel === 'goodToKnow' && activeStep === 0) {
      return t('additionalChildrenInfo');
    } else if (fieldSetKey !== 'childrenDetails' && showError && errors !== undefined) {
      return errors;
    } else return '';
  };

  const defaultValueHandler = () => {
    if (fieldSetKey === 'childrenDetails' && fieldId !== undefined) {
      return values['children'][fieldId][fieldLabel];
    } else if (fieldLabel !== 'wishEarliest' && fieldLabel !== 'wishLatest') {
      return values[fieldLabel];
    }
  };

  const handleOnChange = useDebouncedCallback((event: string, label: string) => {
    setFieldValue(label, event);
  }, 1000);

  return (
    <SimpleTextField
      select={selected}
      variant={fieldVariant as StandardTextFieldProps['variant']}
      required={required}
      disabled={activeStep > 0}
      id={fieldLabel}
      label={useStandardLabel ? '' : activeStep > 0 ? `${t(fieldLabel)}:` : t(fieldLabel)}
      standardLabel={useStandardLabel ? t(fieldLabel) : ''}
      onChange={event => {
        fieldSetKey === 'childrenDetails'
          ? handleOnChange(event.target.value, `children[${fieldId}].${fieldLabel}`)
          : handleOnChange(event.target.value, fieldLabel);
      }}
      defaultValue={defaultValueHandler()}
      error={fieldSetKey !== 'childrenDetails' && touched !== undefined && errors !== undefined}
      SelectProps={propsSelected}
      selectedItems={
        fieldLabel === 'wishEarliest'
          ? selectedItemsEarliest
          : fieldLabel === 'wishLatest'
          ? selectedItemsLatest
          : undefined
      }
      {...(fieldLabel === 'wishEarliest' || fieldLabel === 'wishLatest'
        ? { value: values[fieldLabel] }
        : {})}
      multiline={useMultiline}
      rows={3}
      helperText={showHelperText()}
      FormHelperTextProps={{
        style: {
          marginTop: 0,
          color: fieldLabel === 'goodToKnow' ? COLORS.brightGreen : COLORS.red,
          fontFamily: FONTS.Karla,
          fontWeight: WEIGHTS.regular,
          fontSize: 13,
        },
      }}
    />
  );
};

export default FormTextField;
