import { useFormik } from 'formik';
import { Dispatch, Fragment, SetStateAction, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as Yup from 'yup';
import Checkbox from '@mui/material/Checkbox';
import { alpha } from '@mui/material';
import 'yup-phone';
import moment from 'moment';

import { OrganisationSeasonContext } from '../../App';
import {
  ChristmasStatus,
  Santa,
  Season,
  useCreateSantaMutation,
  useCreateSeasonMutation,
  useCreateUserMutation,
  useOrganisationSeasonsQuery,
  useUpdateSantaMutation,
  useUpdateSeasonMutation,
  useOrganisationUsersQuery,
  UserEntity,
  useUpdateUserMutation,
  UserRole,
  useSeasonSantasLazyQuery,
} from '../../graphql/generated';
import { COLORS } from '../../styles/colors';
import { FONTS } from '../../styles/fonts';
import { secondaryButtonTheme } from '../../styles/themes';
import { WEIGHTS } from '../../styles/weights';
import SimpleTextField, { SelectedItem } from '../common/SimpleTextField';
import { StyledButton } from '../common/StyledButton';
import {
  genSeasonVariables,
  genSantaVariables,
  genSeasonEditVariables,
  genSantaEditVariables,
  genUserVariables,
  genUserEditVariables,
} from './utilities/generateVariables';
import { santaFields, seasonFields, userEditFields, userFields } from './utilities/fields';

const StyledBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
`;

const StyledFormBox = styled.div<{ heightType: string }>`
  height: ${props =>
    props.heightType === 'santa' || props.heightType === 'santaEdit' ? '80' : '65'}%;
  min-width: 45%;
  border: 2px solid ${COLORS.grey};
  border-radius: 10px;
  background-color: ${COLORS.white};
  color: ${COLORS.brightGreen};
  padding: 10px;
  overflow-y: auto;
`;

const StyledFormHeader = styled.h1`
  font-family: ${FONTS.Lora};
  font-weight: ${WEIGHTS.medium};
  font-size: 24px;
  letter-spacing: 0.33px;
  margin: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const StyledFieldRow = styled.div`
  display: flex;
  column-gap: 13px;
  min-height: 65px;
  padding-left: 20px;
  padding-right: 20px;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  float: right;
`;

const StyledErrorMessage = styled.div`
  font-family: ${FONTS.Lora};
  font-weight: ${WEIGHTS.medium};
  color: ${COLORS.red};
  font-size: 14px;
  margin-right: 15px;
  display: flex;
  align-items: center;
`;

const StyledSubHeader = styled.div`
  font-size: 16px;
  margin-right: 15px;
  letter-spacing: 0.33px;
  margin-left: 20px;
  margin-bottom: 20px;
  display: inline-block;
`;

const CheckboxText = styled.div`
  font-family: ${FONTS.Karla};
  font-weight: ${WEIGHTS.regular};
  font-size: 14px;
`;

const CheckboxContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
`;

const checkboxStyle = {
  color: COLORS.brightGreen,
  '&.Mui-checked': {
    color: COLORS.brightGreen,
  },
  '&.Mui-disabled': {
    color: alpha(COLORS.brightGreen2, 0.4),
  },
};

const propsSelected = {
  MenuProps: {
    PaperProps: {
      sx: {
        '&::-webkit-scrollbar': {
          width: '0.3em',
        },
        '&.MuiMenu-paper.MuiPopover-paper': {
          maxHeight: '17vh',
        },
        '& .Mui-selected': {
          backgroundColor: COLORS.lightGreen,
          '&:hover': {
            backgroundColor: COLORS.lightGreen,
          },
        },
      },
    },
  },
};

const propsHelper = {
  style: {
    marginTop: 0,
    marginLeft: 2,
    color: COLORS.red,
    fontFamily: FONTS.Karla,
    fontWeight: WEIGHTS.regular,
  },
};

const propsHelperChristmasStatus = {
  style: {
    marginTop: 0,
    paddingBottom: 17,
    marginLeft: 2,
    color: COLORS.brightGreen,
    fontFamily: FONTS.Karla,
    fontWeight: WEIGHTS.regular,
  },
};

interface Props {
  type: string;
  setFormOpen: Dispatch<SetStateAction<boolean>>;
  selectedSeason: Season | undefined;
  selectedSanta: Santa | null;
  selectedUser: UserEntity | null;
  setShowAlert: Dispatch<SetStateAction<boolean>>;
}

const CreateNew: React.FC<Props> = ({
  type,
  setFormOpen,
  selectedSeason,
  selectedSanta,
  selectedUser,
  setShowAlert,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'adminCreateNew' });

  const { organisation } = useContext(OrganisationSeasonContext);

  const [fieldDetails, setFieldDetails] = useState([
    [{ label: '', required: false, disabled: false, type: '' }],
  ]);
  const [error, setError] = useState('');
  const [header, setHeader] = useState('');
  const [christmasStatusItems, setChristmasStatusItems] = useState([
    { value: ChristmasStatus.Wait, disabled: false, bold: false },
    { value: ChristmasStatus.Open, disabled: false, bold: false },
    { value: ChristmasStatus.Closed, disabled: false, bold: false },
    { value: ChristmasStatus.Archived, disabled: false, bold: false },
  ]);

  const [createSeason] = useCreateSeasonMutation();
  const [createSanta] = useCreateSantaMutation();
  const [updateSeasonMutation] = useUpdateSeasonMutation();
  const [updateSantaMutation] = useUpdateSantaMutation();
  const [createUserMutation] = useCreateUserMutation();
  const [updateUserMutation] = useUpdateUserMutation();

  const { data: seasonsData, refetch: seasonsRefetch } = useOrganisationSeasonsQuery({
    variables: { organisationId: organisation.id },
  });

  const { data: usersData } = useOrganisationUsersQuery({
    variables: { organisationId: organisation.id },
  });

  const [getSeasonSantas, { data: santasData }] = useSeasonSantasLazyQuery();

  useEffect(() => {
    if (selectedSeason) {
      getSeasonSantas({ variables: { seasonId: selectedSeason.id } });
    }
  }, [selectedSeason, getSeasonSantas]);

  useEffect(() => {
    const openSeasonExists =
      seasonsData!.organisationSeasons.some(
        season =>
          season.christmasStatus === ChristmasStatus.Open && season.id !== selectedSeason?.id
      ) ||
      (seasonsData!.organisationSeasons.some(
        season => season.christmasStatus === ChristmasStatus.Open
      ) &&
        type === 'season');

    setChristmasStatusItems([
      { value: ChristmasStatus.Wait, disabled: false, bold: false },
      {
        value: ChristmasStatus.Open,
        disabled: openSeasonExists,
        bold: false,
      },
      { value: ChristmasStatus.Closed, disabled: false, bold: false },
      { value: ChristmasStatus.Archived, disabled: false, bold: false },
    ]);
  }, [organisation.id, seasonsData, selectedSeason?.id, type]);

  useEffect(() => {
    if (type === 'season') {
      formik.setValues({
        year: '',
        price: '',
        duration: '',
        interval: '',
        christmasStatus: ChristmasStatus.Wait,
        minStartTime: '',
        maxStartTime: '',
      });
      setFieldDetails(seasonFields);
      setHeader(t('createNew'));
    } else if (type === 'seasonEdit') {
      formik.setValues({
        year: selectedSeason?.year,
        price: selectedSeason?.price,
        duration: selectedSeason?.duration,
        interval: selectedSeason?.interval,
        christmasStatus: selectedSeason?.christmasStatus || 'Wait',
        minStartTime: selectedSeason?.minStartTime,
        maxStartTime: selectedSeason?.maxStartTime,
      });
      setFieldDetails(seasonFields);
      setHeader(t('edit'));
    } else if (type === 'santa') {
      formik.setValues({
        name: '',
        santaPhone: '',
        santaEmail: '',
        position: '',
        startTime: '',
        endTime: '',
        areaNameInternal: '',
        areaNamePublic: '',
        showOnFrontPage: true,
        driverName: '',
        driverPhone: '',
        driverEmail: '',
        userEmail: null,
      });
      setFieldDetails(santaFields);
      setHeader(t('createNew'));
    } else if (type === 'santaEdit') {
      formik.setValues({
        name: selectedSanta?.name,
        santaPhone: selectedSanta?.santaPhone,
        santaEmail: selectedSanta?.santaEmail,
        position: selectedSanta?.position,
        startTime: selectedSanta?.startTime,
        endTime: selectedSanta?.endTime,
        areaNameInternal: selectedSanta?.areaNameInternal,
        areaNamePublic: selectedSanta?.areaNamePublic,
        showOnFrontPage: selectedSanta?.showOnFrontpage ? true : false,
        driverName: selectedSanta?.driverName,
        driverPhone: selectedSanta?.driverPhone,
        driverEmail: selectedSanta?.driverEmail,
        userEmail:
          usersData?.organisationUsers.find(user => user.id === selectedSanta?.userId)?.email ||
          null,
      });
      setFieldDetails(santaFields);
      setHeader(t('edit'));
    } else if (type === 'user') {
      formik.setValues({
        email: '',
        givenName: '',
        familyName: '',
        phone: '',
        roles: '',
      });
      setFieldDetails(userFields);
      setHeader(t('createNew'));
    } else if (type === 'userEdit') {
      formik.setValues({
        givenName: selectedUser?.given_name,
        familyName: selectedUser?.family_name,
        phone: selectedUser?.phoneNumber,
        roles: selectedUser?.roles,
      });
      setFieldDetails(userEditFields);
      setHeader(t('edit'));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSeason, type, usersData]);

  const validationHandler = () => {
    if (type === 'season' || type === 'seasonEdit') {
      return Yup.object({
        year: Yup.number().required(t('required')),
        price: Yup.number().required(t('required')),
        duration: Yup.number().required(t('required')),
        interval: Yup.number().required(t('required')),
        minStartTime: Yup.string().required(t('required')),
        maxStartTime: Yup.string().required(t('required')),
      });
    } else if (type === 'santa' || type === 'santaEdit') {
      return Yup.object({
        name: Yup.string().required(t('required')),
        santaPhone: Yup.string().required(t('required')),
        santaEmail: Yup.string().email(t('errorEmail')).nullable(true),
        position: Yup.number().required(t('required')),
        startTime: Yup.string()
          .required(t('required'))
          .test(
            'is-greater',
            t('errorSeasonMinTime', {
              param: moment(selectedSeason?.minStartTime, 'HH:mm:ss').format('HH:mm'),
            }),
            function (value) {
              return moment(value, 'HH:mm').isSameOrAfter(
                moment(selectedSeason?.minStartTime, 'HH:mm')
              );
            }
          )
          .test(
            'is-smaller',
            t('errorSeasonMaxTime', {
              param: moment(selectedSeason?.maxStartTime, 'HH:mm:ss').format('HH:mm'),
            }),
            function (value) {
              return moment(value, 'HH:mm').isSameOrBefore(
                moment(selectedSeason?.maxStartTime, 'HH:mm')
              );
            }
          ),
        endTime: Yup.string()
          .required(t('required'))
          .test(
            'is-smaller',
            t('errorSeasonMaxTime', {
              param: moment(selectedSeason?.maxStartTime, 'HH:mm:ss').format('HH:mm'),
            }),
            function (value) {
              return moment(value, 'HH:mm').isSameOrBefore(
                moment(selectedSeason?.maxStartTime, 'HH:mm')
              );
            }
          )
          .test('is-smaller', t('errorStartTime'), function (value) {
            return moment(value, 'HH:mm').isSameOrAfter(
              moment(formik.values['startTime'], 'HH:mm')
            );
          }),
        areaNamePublic: Yup.string().required(t('required')),
        driverPhone: Yup.string().nullable(true),
        driverEmail: Yup.string().email(t('errorEmail')).nullable(true),
      });
    } else if (type === 'user' || type === 'userEdit') {
      return Yup.object({
        givenName: Yup.string().required(t('required')),
        familyName: Yup.string().required(t('required')),
        phone: Yup.string().phone('FI', false, t('errorPhone')).required(t('required')),
        email: Yup.string().email(t('errorEmail')).nullable(true),
      });
    }
    return;
  };

  const formik = useFormik({
    initialValues: {},
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationHandler(),
    onSubmit: async () => {
      try {
        if (type === 'season') {
          await createSeason(genSeasonVariables(formik.values, organisation.id));
        } else if (type === 'santa') {
          await createSanta(
            genSantaVariables(formik.values, selectedSeason?.id, usersData?.organisationUsers)
          );
        } else if (type === 'seasonEdit') {
          await updateSeasonMutation(genSeasonEditVariables(formik.values, selectedSeason?.id));
        } else if (type === 'santaEdit') {
          await updateSantaMutation(
            genSantaEditVariables(
              formik.values,
              selectedSanta?.id,
              selectedSanta?.seasonId,
              usersData?.organisationUsers
            )
          );
        } else if (type === 'user') {
          await createUserMutation(genUserVariables(formik.values, organisation.id));
        } else if (type === 'userEdit') {
          await updateUserMutation(genUserEditVariables(formik.values, selectedUser?.id));
        }
        setFormOpen(false);
        setShowAlert(true);
        seasonsRefetch();
      } catch (error) {
        setError(t('errorDatabase'));
      }
    },
  });

  const selectedValues = (): SelectedItem[] | undefined => {
    if (type === 'season' || type === 'seasonEdit') {
      return christmasStatusItems.map(status => ({
        ...status,
        value: t(status.value),
        initValue: status.value,
      }));
    } else if (type === 'user' || type === 'userEdit') {
      return [
        { value: t(UserRole.ORG_USER), initValue: UserRole.ORG_USER, disabled: false, bold: false },
        { value: t(UserRole.SANTA), initValue: UserRole.SANTA, disabled: false, bold: false },
        {
          value: t(UserRole.ORG_ADMIN),
          initValue: UserRole.ORG_ADMIN,
          disabled: false,
          bold: false,
        },
      ];
    } else {
      const items: SelectedItem[] | undefined = usersData?.organisationUsers.map(user => {
        const userIsAlreadySanta =
          (santasData?.seasonSantas.map(santa => santa.userId).includes(user.id) &&
            selectedSanta?.userId !== user.id) ||
          false;

        return {
          value: user.email,
          initValue: user.email,
          disabled: userIsAlreadySanta,
          bold: false,
        };
      });

      items?.unshift({ value: t('noUser'), initValue: null, disabled: false, bold: false });

      return items;
    }
  };

  const createNewData = async () => {
    formik.handleSubmit();
  };

  const defaultValueHandler = (fieldType: string, fieldLabel: string) => {
    if (fieldType !== 'selected') {
      return formik.values[fieldLabel];
    } else if (type === 'seasonEdit') {
      return selectedSeason?.christmasStatus || 'Wait';
    } else if (type === 'santaEdit') {
      return (
        usersData?.organisationUsers.find(user => user.id === selectedSanta?.userId)?.email || ''
      );
    } else if (type === 'season') {
      return ChristmasStatus.Wait;
    } else if (type === 'santa') {
      return t('noUser');
    } else if (type === 'userEdit') {
      return selectedUser?.roles[0];
    }
    return '';
  };

  return (
    <StyledBackground>
      <StyledFormBox heightType={type}>
        <StyledFormHeader>
          {header} {t(type)}
        </StyledFormHeader>
        {fieldDetails.map((rowFields, index) => (
          <Fragment key={index}>
            {rowFields.some(item => item.label === 'driverName') && (
              <StyledSubHeader>{t('driverInfo')}</StyledSubHeader>
            )}
            {rowFields.some(item => item.label === 'user') && (
              <StyledSubHeader>{t('userInfo')}</StyledSubHeader>
            )}
            <StyledFieldRow>
              {rowFields.map(field =>
                field.label === 'showOnFrontPage' ? (
                  <CheckboxContainer key={field.label}>
                    <Checkbox
                      id={field.label}
                      name={field.label}
                      defaultChecked={
                        type === 'santaEdit' ? Boolean(selectedSanta?.showOnFrontpage) : true
                      }
                      onChange={() =>
                        formik.setFieldValue(field.label, !formik.values[field.label])
                      }
                      sx={checkboxStyle}
                    />
                    <CheckboxText>{t(field.label)}</CheckboxText>
                  </CheckboxContainer>
                ) : (
                  <SimpleTextField
                    key={field.label}
                    required={field.required}
                    type={field.type}
                    select={field.type === 'selected'}
                    disabled={field.disabled}
                    id={field.label}
                    label={t(field.label)}
                    onBlur={event => {
                      formik.setFieldValue(field.label, event.target.value);
                    }}
                    error={formik.errors[field.label]}
                    helperText={
                      field.label === 'christmasStatus'
                        ? t('openSeasonInfo')
                        : formik.errors[field.label]
                    }
                    defaultValue={
                      field.type === 'selected'
                        ? defaultValueHandler(field.type, field.label)
                        : formik.values[field.label]
                    }
                    selectedItems={field.type === 'selected' ? selectedValues() : undefined}
                    SelectProps={propsSelected}
                    FormHelperTextProps={
                      field.label === 'christmasStatus' ? propsHelperChristmasStatus : propsHelper
                    }
                  />
                )
              )}
            </StyledFieldRow>
          </Fragment>
        ))}
        <StyledButtonContainer>
          {error !== '' && <StyledErrorMessage>{error}</StyledErrorMessage>}
          <StyledButton theme={secondaryButtonTheme} onClick={() => setFormOpen(false)}>
            {t('cancel')}
          </StyledButton>
          <StyledButton type="submit" onClick={createNewData}>
            {type === 'season' || type === 'santa' ? t('create') : t('save')}
          </StyledButton>
        </StyledButtonContainer>
      </StyledFormBox>
    </StyledBackground>
  );
};

export default CreateNew;
