import styled from 'styled-components';

import { COLORS } from '../../styles/colors';

const StyledCardRow = styled.div<{ selected?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  overflow-x: auto;
  background: ${props => (props.selected ? COLORS.lightGrey : COLORS.white)};
  border-bottom: 1px solid ${COLORS.grey};
  min-height: 40px;
`;

export default StyledCardRow;
