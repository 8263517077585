import { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { COLORS } from '../../styles/colors';
import { WEIGHTS } from '../../styles/weights';
import { FONTS } from '../../styles/fonts';
import { FONTSIZE } from '../../styles/fontsize';
import { StyledButton } from './StyledButton';
import { OrganisationSeasonContext } from '../../App';

const Container = styled.div`
  width: 100%;
  background-color: ${COLORS.green1};
  padding-left: calc(100% / 10);
  padding-right: calc(100% / 10);
  display: flex;
  justifycontent: flex-end;
  flex-direction: column;
  @media (max-width: 768px) {
    padding-left: 5%;
    padding-right: 5%;
    flex-direction: column;
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10%;
  @media (max-width: 768px) {
    flex-direction: column;
    padding-top: 30px;
  }
`;

const BottomColumn = styled.div`
  padding-top: 35px;
  padding-bottom: 20px;
  font-family: ${FONTS.Karla};
  font-weight: ${WEIGHTS.regular};
  font-size: ${FONTSIZE.content};
  color: ${COLORS.lightYellow};
  @media (max-width: 768px) {
    padding-bottom: 15px;
    padding-top: 0px;
  }
`;

const ButtonContainer = styled.div`
  padding-bottom: 40px;
`;

const Link = styled.a`
  color: ${COLORS.lightYellow};
  &:hover {
    color: ${COLORS.lightYellow};
  }
`;

const Footer: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'footer' });
  const { orgName } = useParams();
  const { organisation, openSeason } = useContext(OrganisationSeasonContext);
  const navigate = useNavigate();
  const location = useLocation();

  const openForm = () => {
    navigate(`/${orgName}/order`);
  };

  const splitAddress = organisation.address?.split(' ');

  return (
    <Container>
      <ColumnContainer>
        <BottomColumn>
          {organisation.name}
          <br />
          {splitAddress?.slice(0, splitAddress?.length - 2).join(' ')}
          <br />
          {splitAddress?.slice(splitAddress?.length - 2, splitAddress?.length).join(' ')}
        </BottomColumn>

        <BottomColumn>
          {organisation.websiteURL && (
            <Link
              href={`https://${organisation.websiteURL.replace('https://', '')}`}
              target="_blank"
              rel="noreferrer">
              {organisation.websiteURL}
            </Link>
          )}
          <br />
          {organisation.email}
          <br />
          {t('phone')}: {organisation.phone}
        </BottomColumn>
      </ColumnContainer>
      {location.pathname !== `/${orgName}/order` && openSeason && (
        <ButtonContainer>
          <StyledButton frontPage={true} onClick={openForm}>
            {t('orderSanta')}
          </StyledButton>
        </ButtonContainer>
      )}
    </Container>
  );
};

export default Footer;
