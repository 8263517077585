import styled from 'styled-components';

import { COLORS } from '../../styles/colors';
import { FONTS } from '../../styles/fonts';
import { FONTSIZE } from '../../styles/fontsize';
import { WEIGHTS } from '../../styles/weights';

export const StyledButton = styled.button<{
  float?: string;
  frontPage?: boolean;
  globalFrontPage?: boolean;
}>`
  width: ${props => (props.frontPage || props.globalFrontPage ? 'fit-content' : '120px')};
  height: 25px;
  font-family: ${FONTS.Karla};
  font-weight: ${props => props.theme.fontWeight};
  color: ${props => props.theme.color};
  border: 1px solid ${COLORS.red};
  background: ${props => props.theme.background};
  border: ${props => props.theme.border};
  font-size: ${props => (props.frontPage || props.globalFrontPage ? FONTSIZE.content : '15px')};
  letter-spacing: 0.51px;
  text-transform: uppercase;
  margin: ${props => (props.frontPage ? '0px' : '10px')};
  margin-top: 15px;
  z-index: 2;
  float: ${props => props.float};
  cursor: pointer;
  &:hover {
    background: ${props => props.theme.hoverBackground} padding-box;
    border: ${props => props.theme.hoverBorder};
  }
  &:focus {
    background: ${props => props.theme.focusBackground} padding-box;
    border: ${props => props.theme.focusBorder};
  }
  &:active {
    background: ${props => props.theme.activeBackground} padding-box;
    border: ${props => props.theme.activeBorder};
  }
`;

StyledButton.defaultProps = {
  theme: {
    fontWeight: WEIGHTS.medium,
    color: COLORS.white,
    background: COLORS.red,
    border: `1px solid ${COLORS.red}`,
    hoverBackground: COLORS.brightRed,
    hoverBorder: `1px solid ${COLORS.brightRed}`,
    focusBackground: COLORS.brightRed,
    focusBorder: `1px solid ${COLORS.brightRed}`,
    activeBackground: COLORS.darkRed,
    activeBorder: `1px solid ${COLORS.darkRed}`,
  },
};
