import styled from 'styled-components';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../styles/colors';
import { secondaryButtonTheme } from '../../styles/themes';
import { StyledButton } from '../common/StyledButton';
import { FONTS } from '../../styles/fonts';
import { WEIGHTS } from '../../styles/weights';
import StyledHr from '../common/StyledHr';

const StyledAlertBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
`;

const StyledAlertBox = styled.div`
  height: 200px;
  width: 445px;
  border: 2px solid ${COLORS.grey};
  border-radius: 20px;
  background-color: ${COLORS.white};
  box-shadow: 0px 3px 6px #00000029;
  padding: 10px;
`;

const StyledAlertRow = styled.div<{ place: string; height: number }>`
  display: flex;
  justify-content: ${props => props.place};
  height: ${props => props.height}px;
`;

const StyledAlertText = styled.p<{ font: string; weight: number; size: number }>`
  font-size: ${props => props.size}px;
  font-family: ${props => props.font};
  font-weight: ${props => props.weight};
  color: ${COLORS.brightGreen};
  margin-left: 10px;
  margin-bottom: 20px;
`;

interface Props {
  cancel: () => void;
  ok: () => void;
  alertHeader: string;
  alertText: string;
}

const AlertDialog: React.FC<Props> = ({ cancel, ok, alertHeader, alertText }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'alertDialog' });
  return (
    <StyledAlertBackground>
      <StyledAlertBox>
        <StyledAlertRow place={'right'} height={20}>
          <CloseOutlinedIcon
            style={{ fill: COLORS.brightGreen2 }}
            sx={{ fontSize: 18, cursor: 'pointer' }}
            onClick={cancel}
          />
        </StyledAlertRow>
        <StyledAlertRow place={'left'} height={50}>
          <StyledAlertText font={FONTS.Lora} weight={WEIGHTS.medium} size={16}>
            {t(alertHeader)}
          </StyledAlertText>
        </StyledAlertRow>
        <StyledAlertRow place={'left'} height={60}>
          <StyledAlertText font={FONTS.Karla} weight={WEIGHTS.regular} size={12}>
            {t(alertText)}
          </StyledAlertText>
        </StyledAlertRow>
        <StyledHr />
        <StyledAlertRow place={'right'} height={40}>
          <StyledButton theme={secondaryButtonTheme} onClick={cancel}>
            {t('cancel')}
          </StyledButton>
          <StyledButton type="submit" onClick={ok}>
            {t('ok')}
          </StyledButton>
        </StyledAlertRow>
      </StyledAlertBox>
    </StyledAlertBackground>
  );
};

export default AlertDialog;
