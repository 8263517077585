import { gql, useApolloClient } from '@apollo/client';
import mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';

import {
  Order,
  Santa,
  Season,
  useCreateOrderChildMutation,
  useRemoveOrderChildrenMutation,
  useSantaQuery,
  useUpdateOrderMutation,
} from '../../graphql/generated';
import { COLORS } from '../../styles/colors';
import { FONTS } from '../../styles/fonts';
import { secondaryButtonTheme } from '../../styles/themes';
import { WEIGHTS } from '../../styles/weights';
import { orderStatus } from '../../types/order';
import OrderStatus from '../common/OrderStatus';
import AlertDialog from '../alerts/AlertDialog';
import AlertSnackbar from '../alerts/AlertSnackbar';
import ExpandableItem from '../common/ExpandableItem';
import DropDown from '../common/DropDown';
import moment from 'moment';
import { StyledButton } from '../common/StyledButton';
import FieldCard from './FieldCard';
import { saveOrderPdf } from '../utilities/saveOrderPdf';
import { useNavigate, useParams } from 'react-router-dom';

const MAP_TOKEN = process.env.REACT_APP_MAP_TOKEN;

const StyledFormContainer = styled.div`
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  background: ${COLORS.lightGrey};
  overflow-x: auto;
  z-index: 3;
`;

const StyledFormRow = styled.div`
  display: flex;
  margin: 10px;
`;

const StyledFormColumn = styled.div<{ size: number }>`
  flex: ${props => props.size};
  margin: 10px;
`;

const StyledCloseIcon = styled(CloseOutlinedIcon)`
  cursor: pointer;
  color: ${COLORS.brightGreen};
  margin-top: 18px;
  margin-left: 5px;
`;

const StyledFormHeader = styled.h1`
  font-family: ${FONTS.Lora};
  font-weight: ${WEIGHTS.medium};
  font-size: 26px;
  color: ${COLORS.brightGreen};
  letter-spacing: 0.51px;
  padding-top: 13px;
`;

const StyledFormText = styled.span<{ marginSize: number; weight: number }>`
  font-size: 12px;
  font-family: ${FONTS.Karla};
  font-weight: ${props => props.weight};
  color: ${COLORS.brightGreen};
  margin-left: ${props => props.marginSize}px;
`;

const iconStyle = { fontSize: 20, marginBottom: -0.6, color: COLORS.red };

interface Props {
  closeForm: () => void;
  setChangeStatusWindowOpen?: Dispatch<SetStateAction<boolean>>;
  setTypeOfChange?: Dispatch<SetStateAction<string>>;
  setShowDialog?: Dispatch<SetStateAction<boolean>>;
  selectedOrder?: Order | null;
  season?: Season | null;
  santa?: Santa | null;
}

const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

const initialChildrenArray: {
  name: string;
  age: string | null | undefined;
  goodToKnow: string | null | undefined;
}[] = [
  { name: '', age: '', goodToKnow: '' },
  { name: '', age: '', goodToKnow: '' },
];

const FormModal: React.FC<Props> = ({
  closeForm,
  setChangeStatusWindowOpen,
  setTypeOfChange,
  setShowDialog: setShowMapViewDialog,
  selectedOrder,
  season,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'form' });

  const navigate = useNavigate();
  const { orgName } = useParams();

  const functions = [
    { text: 'Print', key: 'print' },
    { text: 'Delete', key: 'delete' },
  ];

  if (selectedOrder?.orderStatus === 'CONFIRMED') {
    functions.unshift({ text: 'Move', key: 'move' });
  }
  if (selectedOrder?.orderStatus === 'WAIT CONFIRMATION') {
    functions.unshift({ text: 'Reject', key: 'reject' });
    functions.unshift({ text: 'Confirm', key: 'confirm' });
  }

  if (selectedOrder?.orderStatus === 'REJECTED') {
    functions.unshift({ text: 'Confirm', key: 'confirm' });
  }

  const getTimeWishes = useCallback((start: string | undefined, end: string | undefined) => {
    const startTime = moment(start, 'HH:mm');
    const endTime = moment(end, 'HH:mm');
    const timeWishes: string[] = [];

    while (startTime <= endTime) {
      timeWishes.push(moment(startTime).format('HH:mm'));
      startTime.add(1, 'hour');
    }
    return timeWishes;
  }, []);

  const [updateOrderMutation] = useUpdateOrderMutation();
  const [removeOrderChildrenMutation] = useRemoveOrderChildrenMutation();
  const [createOrderChildMutation] = useCreateOrderChildMutation();
  const apolloClient = useApolloClient();

  const [showError, setShowError] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [childrenArray, setChildrenArray] = useState(initialChildrenArray);
  const [timeWishes, setTimeWishes] = useState<string[]>(getTimeWishes('00:00', '23:00'));

  const { data: santaData } = useSantaQuery({
    variables: { id: selectedOrder?.santaId || '' },
  });

  useEffect(() => {
    if (season) {
      setTimeWishes(getTimeWishes(season.minStartTime, season.maxStartTime));
      if (season && !selectedOrder) {
        formik.setFieldValue('wishEarliest', season.minStartTime.slice(0, 5));
        formik.setFieldValue('wishLatest', season.maxStartTime.slice(0, 5));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTimeWishes, season]);

  // Add new childfield to form
  const addChildHandler = () => {
    formik.setFieldValue('children', [
      ...formik.values.children,
      { name: '', age: '', goodToKnow: '' },
    ]);
  };

  // Remove childfield from form
  const removeChildHandler = () => {
    formik.setFieldValue('children', [
      ...formik.values.children.slice(0, formik.values.children.length - 1),
    ]);
  };

  const geocoding = (address: string) => {
    const geocodingClient = mbxGeocoding({
      accessToken: MAP_TOKEN,
    });

    // geocoding with countries
    return geocodingClient
      .forwardGeocode({
        query: address,
        countries: ['fi'],
        limit: 1,
      })
      .send()
      .then(response => {
        const match = response.body;
        const coordinates = match.features[0].geometry.coordinates;

        return {
          geometry: {
            coordinates: coordinates,
          },
        };
      });
  };

  const addOrder = async () => {
    const coordinates = await geocoding(
      `${formik.values.address} ${formik.values.city} ${formik.values.postcode}`
    ).then(result => result.geometry.coordinates);

    const createCustomQuery = () => {
      // Constructs a "fragment" that can be added to createOrder mutation, to create orderChildren
      // TODO? Requires child to have all fields filled to be included in Order
      const buildChildrenQuery = () => {
        const childrenDetails = formik.values.children;
        let createChildrenQuery = '';
        for (let i = 0; i < childrenDetails.length; i++) {
          const child = childrenDetails[i];
          if (child.name) {
            createChildrenQuery += `child${i}: createOrderChildren(
              childInput: { position: ${i}, name: "${child.name}", age: ${
              child.age || null
            }, description: "${child.goodToKnow}" }
              ) {
                name
                age
                description
              }`;
          }
        }
        return createChildrenQuery;
      };

      return `
        createOrder(
          orderInput: {
            seasonId: "${season?.id}"
            wishEarliest: "${formik.values.wishEarliest.replace(':', '')}"
            wishLatest: "${formik.values.wishLatest.replace(':', '')}"
            customerName: "${formik.values.name}"
            streetAddress: "${formik.values.address}"
            city: "${formik.values.city}"
            email: "${formik.values.email}"
            postalCode: "${formik.values.postcode}"
            visitName: "${formik.values.doorname}"
            meetOutside: ${formik.values.meetOutside}
            lonlat: "${coordinates[0]},${coordinates[1]}"
            giftInfo: "${formik.values.giftInfo}"
            routeInfo: "${formik.values.routeInfo}"
            otherInfo: "${formik.values.otherInfo}"
            doorCode: "${formik.values.doorCode}"
            phone: "${formik.values.phone}"
            newsletterIsSubscribed: ${formik.values.annualMessages}
            duration: ${season?.duration}
            price: ${season?.price}
            santaId: "${selectedOrder ? selectedOrder.santaId : null}"
            orderStatus: "${selectedOrder ? orderStatus.confirmed : orderStatus.waitConfirmation}"
            confirmedStartAt: "${
              selectedOrder
                ? selectedOrder.confirmedStartAt
                : formik.values.confirmedStartAt.replace(':', '')
            }"
          }
        ) {
            id
            customerName
            ${buildChildrenQuery()}
          }
      
      `;
    };

    // Creates a custom query dynamically to include children, a bit unorthodox? maybe, works fine tho
    const result = await apolloClient.mutate({
      mutation: gql`
      mutation createOrderWithChildren {
        ${createCustomQuery()}
      }
    `,
    });
    return result;
  };

  const updateOrder = async () => {
    const coordinates = await geocoding(
      `${formik.values.address} ${formik.values.city} ${formik.values.postcode}`
    ).then(result => result.geometry.coordinates);

    if (selectedOrder) {
      await removeOrderChildrenMutation({ variables: { orderId: selectedOrder.id } });
      for (let i = 0; i < formik.values.children.length; i++) {
        const child = formik.values.children[i];

        if (child.name !== '') {
          await createOrderChildMutation({
            variables: {
              orderId: selectedOrder.id,
              input: {
                age: Number(child.age) || null,
                description: child.goodToKnow,
                name: child.name,
                position: i,
              },
            },
          });
        }
      }
      await updateOrderMutation({
        variables: {
          id: selectedOrder.id,
          updateInput: {
            city: formik.values.city,
            streetAddress: formik.values.address,
            customerName: formik.values.name,
            postalCode: formik.values.postcode,
            confirmedStartAt:
              formik.values.confirmedStartAt === ''
                ? null
                : formik.values.confirmedStartAt.replace(':', ''),
            wishEarliest: formik.values.wishEarliest.replace(':', ''),
            wishLatest: formik.values.wishLatest.replace(':', ''),
            phone: formik.values.phone,
            email: formik.values.email,
            giftInfo: formik.values.giftInfo,
            lonlat: `${coordinates[0]},${coordinates[1]}`,
            otherInfo: formik.values.otherInfo,
            routeInfo: formik.values.routeInfo,
            visitName: formik.values.doorname,
            meetOutside: formik.values.meetOutside,
            doorCode: formik.values.doorCode,
            newsletterIsSubscribed: formik.values.annualMessages,
            orderStatus: selectedOrder.orderStatus,
          },
        },
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      name: selectedOrder?.customerName || '',
      phone: selectedOrder?.phone || '',
      email: selectedOrder?.email || '',
      doorname: selectedOrder?.visitName || '',
      address: selectedOrder?.streetAddress || '',
      postcode: selectedOrder?.postalCode || '',
      city: selectedOrder?.city || '',
      children: [
        { name: '', age: '', goodToKnow: '' },
        { name: '', age: '', goodToKnow: '' },
      ],
      confirmedStartAt: selectedOrder?.confirmedStartAt?.slice(0, 5) || '',
      wishEarliest: selectedOrder?.wishEarliest?.slice(0, 5) || '00:00',
      wishLatest: selectedOrder?.wishLatest?.slice(0, 5) || '23:00',
      meetOutside: selectedOrder?.meetOutside || false,
      doorCode: selectedOrder?.doorCode || '',
      giftInfo: selectedOrder?.giftInfo || '',
      otherInfo: selectedOrder?.otherInfo || '',
      routeInfo: selectedOrder?.routeInfo || '',
      annualMessages: selectedOrder?.newsletterIsSubscribed || false,
    },
    validateOnChange: true,
    validateOnBlur: false,
    validationSchema: Yup.object({
      name: Yup.string()
        .matches(/^[aA-öÖ'`´(/)"-\s]+$/, t('errorAlphabets'))
        .min(2, t('errorShortName'))
        .max(50, t('errorLongName'))
        .required(t('errorNoName')),
      phone: Yup.string().matches(phoneRegExp, t('errorPhone')).required(t('errorNoPhone')),
      email: Yup.string().email(t('errorEmail')).required(t('errorNoEmail')),
      doorname: Yup.string()
        .matches(/^[a-öA-Ö0-9'`´(/)"-\s]+$/, t('errorAlphabetsAndNumbers'))
        .min(2, t('errorShortDoorname'))
        .max(50, t('errorLongDoorname'))
        .required(t('errorNoDoorname')),
      address: Yup.string()
        .matches(/^[a-öA-Ö0-9'`´(/)"-\s]+$/, t('errorAlphabetsAndNumbers'))
        .min(2, t('errorShortAddress'))
        .required(t('errorNoAddress')),
      postcode: Yup.number()
        .typeError(t('errorNumbers'))
        .positive(t('errorPostcode'))
        .integer('errorPostcode')
        .required(t('errorNoPostcode')),
      city: Yup.string()
        .matches(/^[aA-öÖ'`´(/)"-\s]+$/, t('errorAlphabets'))
        .min(2, t('errorShortCity'))
        .required(t('errorNoCity')),
      wishEarliest: Yup.string().required(t('errorNoWishEarliest')),
      wishLatest: Yup.string().required(t('errorNoWishLatest')),
    }),
    onSubmit: values => {
      if (Object.values(formik.errors).every(x => x === null || x === '')) {
        if (selectedOrder && selectedOrder.id && selectedOrder.id.trim() !== '') {
          updateOrder().then(closeForm);
        } else {
          addOrder().then(closeForm);
        }
      } else {
        console.log('Error, something went wrong with submitting', values);
      }
    },
  });

  useEffect(() => {
    if (childrenArray.length < 2) {
      formik.setFieldValue('children', [...childrenArray, { name: '', age: '', goodToKnow: '' }]);
    } else {
      formik.setFieldValue('children', childrenArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childrenArray]);

  useEffect(() => {
    if (selectedOrder && selectedOrder.orderChildren?.length > 0) {
      setChildrenArray(
        selectedOrder.orderChildren.map(child => ({
          name: child.name,
          age: String(child.age),
          goodToKnow: child.description,
        }))
      );
    }
  }, [selectedOrder]);

  // wishLatest can't be smaller than wishEarliest
  useEffect(() => {
    if (
      formik.values['wishEarliest'] &&
      formik.values['wishLatest'] &&
      formik.values['wishEarliest'] >= formik.values['wishLatest']
    ) {
      formik.setFieldValue(
        'wishLatest',
        timeWishes[timeWishes.indexOf(formik.values['wishEarliest']) + 1]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.wishEarliest]);

  const submitForm = () => {
    setShowError(true);
    formik.handleSubmit();
  };

  const cancelButtonHandler = () => {
    if (formik.dirty && formik.values.children !== childrenArray) {
      setShowDialog(true);
    } else {
      closeForm();
    }
  };

  // Form line formation
  const fieldCardHandler = (valuesArray: string[][], header: string, childrenField: boolean) => {
    const cardValues = valuesArray.map(function (values) {
      return values.map(function (value) {
        return {
          label: value,
          formikValue: !childrenField ? formik.values[value] : formik.values.children,
          formOnChange: formik.setFieldValue,
        };
      });
    });

    return (
      <StyledFormColumn size={1} onClick={() => setShowError(false)}>
        <FieldCard
          cardValues={cardValues}
          headerText={header}
          childAmount={childrenField ? formik.values.children.length : undefined}
          addChild={childrenField ? addChildHandler : undefined}
          removeChild={childrenField ? removeChildHandler : undefined}
          timeWishes={timeWishes}
          errors={formik.errors}
          lonlat={selectedOrder?.lonlat}
        />
      </StyledFormColumn>
    );
  };

  const timeTextHandler = () => {
    if (selectedOrder?.orderStatus === 'CONFIRMED') {
      return selectedOrder.confirmedStartAt?.slice(0, 5);
    } else
      return `${selectedOrder?.wishEarliest.slice(0, 5)} - ${selectedOrder?.wishLatest.slice(
        0,
        5
      )}`;
  };

  const toggleFunctionsHandler = async (text: string, key: string) => {
    if (
      (key === 'confirm' || key === 'move' || key === 'reject') &&
      setChangeStatusWindowOpen &&
      setTypeOfChange
    ) {
      closeForm();
      setTypeOfChange(key);
      setChangeStatusWindowOpen(true);
    } else if (key === 'confirm' || key === 'move' || key === 'reject') {
      closeForm();
      navigate(`/${orgName}/admin/map`, {
        state: { changeType: key, setStatusWindow: 'true', orderSelected: selectedOrder },
      });
    } else if (key === 'delete' && setShowMapViewDialog !== undefined) {
      closeForm();
      setShowMapViewDialog(true);
    } else if (key === 'delete') {
      closeForm();
      navigate(`/${orgName}/admin/map`, {
        state: { showAlertDialog: true, orderSelected: selectedOrder },
      });
    } else if (key === 'print') {
      saveOrderPdf(
        formik.values,
        undefined,
        {
          name: santaData?.santa?.name,
          area: santaData?.santa.areaNameInternal || santaData?.santa.areaNamePublic,
        },
        selectedOrder?.lonlat,
        selectedOrder?.confirmedStartAt !== null,
        `pukkipalvelu_${formik.values.name.replace(' ', '')}_${
          santaData?.santa?.areaNamePublic || 'notConfirmed'
        }`
      );
    }
    return;
  };

  return (
    <StyledFormContainer>
      {
        // Alert if data will be lost after cancel
        showDialog && (
          <AlertDialog
            cancel={() => setShowDialog(false)}
            ok={closeForm}
            alertHeader={'cancelSure'}
            alertText={'lostData'}
          />
        )
      }
      {
        // Alert if form values are invalid
        !Object.values(formik.errors).every(x => x === null || x === '') &&
          showError &&
          formik.touched && (
            <AlertSnackbar
              alertType={'error'}
              alertText={'error'}
              close={() => setShowError(false)}
            />
          )
      }
      <StyledFormRow>
        <StyledFormColumn size={1}>
          <StyledCloseIcon sx={{ fontSize: 26 }} onClick={cancelButtonHandler} />
        </StyledFormColumn>

        <StyledFormColumn size={10}>
          <StyledFormHeader>
            {selectedOrder
              ? `${selectedOrder.streetAddress}, ${selectedOrder.postalCode} ${selectedOrder.city}`
              : t('orderSanta')}
          </StyledFormHeader>
        </StyledFormColumn>

        <StyledFormColumn size={20}>
          {selectedOrder ? (
            <ExpandableItem
              render={(xprops: { open: boolean; setOpen: Dispatch<SetStateAction<boolean>> }) => (
                <StyledButton
                  theme={secondaryButtonTheme}
                  onClick={() => [xprops.setOpen(!xprops.open)]}>
                  {t('functions')}
                  {xprops.open ? (
                    <ExpandLessOutlinedIcon sx={iconStyle} />
                  ) : (
                    <ExpandMoreOutlinedIcon sx={iconStyle} />
                  )}
                  <DropDown
                    openOptions={xprops.open}
                    optionArray={functions}
                    onArrangeChange={toggleFunctionsHandler}
                  />
                </StyledButton>
              )}
            />
          ) : (
            <StyledButton theme={secondaryButtonTheme} onClick={cancelButtonHandler}>
              {t('cancel')}
            </StyledButton>
          )}

          <StyledButton type="submit" onClick={submitForm}>
            {t('save')}
          </StyledButton>
        </StyledFormColumn>
      </StyledFormRow>
      {selectedOrder && (
        <>
          <StyledFormRow>
            <StyledFormText marginSize={20} weight={WEIGHTS.regular}>
              {t('received')}:&nbsp;
              {moment(Number(selectedOrder.createdAt)).format('DD.MM.YYYY HH:mm:ss')}
            </StyledFormText>
          </StyledFormRow>
          <StyledFormRow>
            <OrderStatus statusType={selectedOrder.orderStatus} form={true} />
            <StyledFormText marginSize={0} weight={WEIGHTS.extrabold}>
              {selectedOrder.orderStatus === orderStatus.confirmed &&
                `${santaData?.santa?.name} | `}
              {selectedOrder.orderStatus !== orderStatus.confirmed && t('at') + ': '}
              {timeTextHandler()}
            </StyledFormText>
          </StyledFormRow>
        </>
      )}

      <StyledFormRow>
        {fieldCardHandler([['name'], ['phone'], ['email']], 'subscriberInfo', false)}

        {fieldCardHandler(
          [['doorname'], ['address'], ['postcode', 'city']],
          'destinationInfo',
          false
        )}
        {fieldCardHandler([], 'mapInfo', false)}
      </StyledFormRow>
      <StyledFormRow>
        {fieldCardHandler([['name', 'age'], ['goodToKnow']], 'children', true)}
        {fieldCardHandler(
          [
            ['doorCode'],
            ['giftInfo'],
            ['routeInfo'],
            ['otherInfo'],
            ['meetOutside', 'annualMessages'],
          ],
          'additionalDetails',
          false
        )}
      </StyledFormRow>
    </StyledFormContainer>
  );
};

export default FormModal;
