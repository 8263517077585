import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { COLORS } from '../../../styles/colors';
import { FONTS } from '../../../styles/fonts';
import { WEIGHTS } from '../../../styles/weights';
import { FONTSIZE } from '../../../styles/fontsize';
import SantaWithTree from '../../../images/santaWithTree.svg';
import {
  CommonContainer as Container,
  CommonTextContainer,
  CommonBoxHeader,
  CommonImageContainer,
} from './HomepageStyles';
import { OrganisationSeasonContext } from '../../../App';
import { useSeasonSchedule } from '../../../hooks/useSeasonSchedule';

const ThirdBox: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'homepage' });

  const { openSeason } = useContext(OrganisationSeasonContext);
  const schedule = useSeasonSchedule(openSeason);

  const frontpageValue = (freeTimes: number) => {
    if (freeTimes <= 0) {
      return 'notAvailable';
    } else if (freeTimes < 3) {
      return 'hurryUp';
    } else return 'goodAvailability';
  };

  return (
    <Container>
      <TextContainer>
        <BoxHeader>{t('statusAndAreas')}</BoxHeader>
        <StyledTable>
          <tbody>
            <tr>
              <th>{t('area')}</th>
              <th>{t('freeTimes')}</th>
            </tr>
            {openSeason && schedule && schedule.areas ? (
              schedule.areas.map((area, i) => {
                if (!schedule.santas[i].showOnFrontpage) {
                  return null;
                }
                return (
                  <tr key={area.name + area.freeTimes}>
                    <td>{area.name}</td>
                    <td>{t(frontpageValue(area.freeTimes))}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td></td>
                <td></td>
              </tr>
            )}
          </tbody>
        </StyledTable>
        <BoxTextDisabled>{t('otherAreas')}</BoxTextDisabled>
      </TextContainer>
      <ImageContainer>
        <img src={SantaWithTree} height={'auto'} width={'75%'} />
      </ImageContainer>
    </Container>
  );
};

const BoxTextDisabled = styled.div`
  margin-top: 10px;
  font-family: ${FONTS.Karla};
  font-weight: ${WEIGHTS.regular};
  color: ${COLORS.green};
  font-size: ${FONTSIZE.content};
`;

const StyledTable = styled.table`
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
  font-family: ${FONTS.Karla};
  text-align: center;

  td {
    padding: 2%;
    font-weight: ${WEIGHTS.regular};
    font-size: ${FONTSIZE.content};
    border: 1px solid ${COLORS.mediumGrey};
  }

  th {
    padding: 2%;
    font-weight: ${WEIGHTS.semibold};
    font-size: ${FONTSIZE.subheading};
    border: 1px solid ${COLORS.mediumGrey};
    background: ${COLORS.green2};
    color: ${COLORS.lightYellow};
    text-transform: uppercase;
    letter-spacing: 0.63px;
  }
`;

const TextContainer = styled(CommonTextContainer)`
  @media (min-width: 768px) {
    padding: 50px 60px 50px 60px;
    align-items: flex-start;
  }
  align-items: center;
`;

const BoxHeader = styled(CommonBoxHeader)`
  margin-bottom: 20px;
`;

const ImageContainer = styled(CommonImageContainer)`
  @media (min-width: 768px) {
    margin-bottom: -20px;
  }
`;
export default ThirdBox;
