import { Dispatch, JSXElementConstructor, ReactElement, SetStateAction, useState } from 'react';

interface Props {
  render: (xprops: {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
  }) => ReactElement<boolean, string | JSXElementConstructor<boolean>>;
}

const ExpandableItem: React.FC<Props> = ({ render }) => {
  const [open, setOpen] = useState(false);

  return render({ open, setOpen });
};

export default ExpandableItem;
