import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Season, useSeasonSantasLazyQuery } from '../../graphql/generated';
import { StyledButton } from '../common/StyledButton';
import { StyledInfoCard, StyledInfoCardHeader } from './StyledInfoCard';

interface SantasCardProps {
  season: Season | undefined;
  setShowSantasTable: Dispatch<SetStateAction<boolean>>;
  formClosed: boolean;
}

const StyledSantasCardHeader = styled(StyledInfoCardHeader)`
  margin-bottom: 16px;
  h1 {
    margin-bottom: 0;
  }
`;

const NoSantasWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const SantasCard: React.FC<SantasCardProps> = ({ season, setShowSantasTable, formClosed }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin' });
  const [getSeasonSantas, { data: santasData, refetch, called }] = useSeasonSantasLazyQuery();

  useEffect(() => {
    if (season) {
      getSeasonSantas({ variables: { seasonId: season.id } });
    }
  }, [getSeasonSantas, season]);

  const columns: GridColDef[] = [
    { field: 'name', headerName: t('santasName'), flex: 1 },
    { field: 'areaNameInternal', headerName: t('areaNameInternal'), flex: 1 },
  ];

  const getRows = () => {
    const rows = santasData?.seasonSantas.map(santa => ({
      id: santa.id,
      name: santa.name,
      areaNameInternal: santa.areaNameInternal || santa.areaNamePublic,
    }));
    return rows || [];
  };

  useEffect(() => {
    if (!formClosed && called) {
      refetch();
    }
  }, [formClosed, refetch, called]);

  return (
    <StyledInfoCard>
      <StyledSantasCardHeader>
        <h1>{t('santas')}</h1>
        <StyledButton onClick={() => setShowSantasTable(true)}>{t('manage')}</StyledButton>
      </StyledSantasCardHeader>

      {santasData && santasData.seasonSantas.length > 0 ? (
        <DataGrid
          sx={{
            '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
              outline: 'none !important',
            },
          }}
          disableColumnMenu
          rows={getRows()}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          autoHeight
          disableSelectionOnClick
        />
      ) : (
        <NoSantasWrapper>
          {season ? <p>{t('noSantas')}</p> : <p>{t('noSeasonSelected')}</p>}
        </NoSantasWrapper>
      )}
    </StyledInfoCard>
  );
};

export default SantasCard;
