import { Dispatch, SetStateAction, useContext } from 'react';
import styled from 'styled-components';

import { SantasOrderContext } from '..';
import { COLORS } from '../../../styles/colors';
import ContactInfoCard from '../../../components/mapView/ContactInfoCard';

const StyledListContainer = styled.div`
  width: 100%;
  height: calc(100vh - 45px);
`;

const StyledOrderRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  background: ${COLORS.white};
  border-bottom: 1px solid ${COLORS.grey};
  min-height: 100px;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 10px;
`;

interface Props {
  setShowMoreOptions: Dispatch<SetStateAction<boolean>>;
  setShowSelectedOrder: Dispatch<SetStateAction<boolean>>;
}

const SantasList: React.FC<Props> = ({ setShowMoreOptions, setShowSelectedOrder }) => {
  const { santaOrders, setSelectedSantaOrder } = useContext(SantasOrderContext);

  return (
    <StyledListContainer>
      {santaOrders.map((order, index) => (
        <StyledOrderRow key={index} onClick={() => setSelectedSantaOrder(order)}>
          <ContactInfoCard
            key={index}
            order={order}
            place={'santaview'}
            setShowMoreOptions={setShowMoreOptions}
            setSelectedSantaOrder={setSelectedSantaOrder}
            hideClose={true}
            setShowSelectedOrder={setShowSelectedOrder}
          />
        </StyledOrderRow>
      ))}
    </StyledListContainer>
  );
};

export default SantasList;
