import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { Dispatch, SetStateAction, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Order, useSantaQuery } from '../../graphql/generated';
import { COLORS } from '../../styles/colors';
import StyledCardButton from '../common/StyledCardButton';
import StyledCardText from '../common/StyledCardText';
import DropDown from '../common/DropDown';
import { OrderContext } from '../../pages/mapView';
import { WEIGHTS } from '../../styles/weights';
import { FONTS } from '../../styles/fonts';
import { orderStatus } from '../../types/order';
import { saveOrderPdf } from '../utilities/saveOrderPdf';

const StyledIconContainer = styled.div`
  display: flex;
  justify-content: right;
  margin-right: 10px;
  margin-bottom: 25px;
`;

interface Props {
  order: Order;
  toggleFunctionsOpen: (arg0: boolean) => void;
  setFormOpen: Dispatch<SetStateAction<boolean>>;
  setChangeStatusWindowOpen: Dispatch<SetStateAction<boolean>>;
  setTypeOfChange: Dispatch<SetStateAction<string>>;
  setShowAlert: Dispatch<SetStateAction<boolean>>;
  setShowDialog: Dispatch<SetStateAction<boolean>>;
}

const TimeInfoCard: React.FC<Props> = ({
  order,
  toggleFunctionsOpen: functionsOpen,
  setFormOpen,
  setChangeStatusWindowOpen,
  setTypeOfChange,
  setShowAlert,
  setShowDialog,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'orderCards' });

  const { selectedOrder, setSelectedOrder } = useContext(OrderContext);

  //TODO causes error in backend if queried with empty santaId, eg. orders waiting confirmation
  const { data } = useSantaQuery({
    variables: { id: order.santaId || '' },
  });

  const functions = [
    { text: 'Edit', key: 'edit' },
    { text: 'Print', key: 'print' },
    { text: 'Delete', key: 'delete' },
  ];

  if (order.orderStatus === orderStatus.confirmed) {
    functions.splice(1, 0, { text: 'Move', key: 'move' });
  }

  if (order.orderStatus === orderStatus.waitConfirmation) {
    functions.splice(1, 0, { text: 'Reject', key: 'reject' });
    functions.splice(1, 0, { text: 'Confirm', key: 'confirm' });
  }

  if (order.orderStatus === orderStatus.rejected) {
    functions.splice(1, 0, { text: 'Confirm', key: 'confirm' });
  }

  const openFunctionHandler = (text: string, key: string) => {
    setSelectedOrder(order);
    if (key === 'edit') {
      setFormOpen(true);
    } else if (key === 'confirm' || key === 'move' || key === 'reject') {
      setTypeOfChange(key);
      setChangeStatusWindowOpen(true);
    } else if (key === 'delete') {
      setShowDialog(true);
    } else if (key === 'print') {
      saveOrderPdf(
        undefined,
        order,
        { name: data?.santa?.name, area: data?.santa?.areaNamePublic },
        order.lonlat,
        order.confirmedStartAt !== null,
        `pukkipalvelu_${order.customerName.replace(' ', '')}_${
          data?.santa?.areaNamePublic || 'notConfirmed'
        }`
      );
    }
  };

  return (
    <>
      <StyledIconContainer>
        <DropDown
          defaultIcon={<MoreHorizOutlinedIcon onClick={() => setShowAlert(false)} />}
          optionArray={functions}
          onArrangeChange={openFunctionHandler}
          onOpen={() => functionsOpen(true)}
          onClose={() => functionsOpen(false)}
        />
      </StyledIconContainer>
      <StyledCardText
        font={FONTS.Karla}
        weight={WEIGHTS.regular}
        size={12}
        color={order.orderStatus === orderStatus.waitConfirmation ? COLORS.darkYellow : COLORS.red}>
        {order.orderStatus === orderStatus.waitConfirmation && `${t('timeWish')}:`}
        {order.orderStatus === orderStatus.rejected && `${t('rejectedTimeWish')}:`}
      </StyledCardText>
      <StyledCardText
        font={FONTS.Lora}
        weight={WEIGHTS.medium}
        size={14}
        color={COLORS.brightGreen}>
        <AccessTimeOutlinedIcon sx={{ fontSize: 20, marginBottom: -0.5, marginRight: 0.5 }} />
        {order.orderStatus === orderStatus.confirmed && order.confirmedStartAt?.slice(0, 5)}
        {order.orderStatus !== orderStatus.confirmed &&
          `${order.wishEarliest.slice(0, 5)} - ${order.wishLatest.slice(0, 5)}`}
      </StyledCardText>
      {order.orderStatus === orderStatus.confirmed && (
        <StyledCardText
          font={FONTS.Karla}
          weight={WEIGHTS.regular}
          size={12}
          color={COLORS.brightGreen}>
          {data?.santa.name}
          <br />
          {data?.santa.areaNamePublic}
        </StyledCardText>
      )}
      {order.orderStatus === orderStatus.waitConfirmation && (
        <StyledCardButton
          selected={order.id === selectedOrder?.id}
          confirm={true}
          onClick={() => [setTypeOfChange('confirm'), setChangeStatusWindowOpen(true)]}>
          {t('confirm')}
        </StyledCardButton>
      )}
    </>
  );
};

export default TimeInfoCard;
