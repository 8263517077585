import { User } from '../components/utilities/RouteGuard';
import { UserRole } from '../graphql/generated';

export const verifyRoles = (user: User | null, roles: UserRole[]) => {
  if (!roles || roles.length === 0) {
    return true;
  }
  if (!user) {
    return false;
  }
  return !!roles.find(role => user.roles.includes(role));
};
