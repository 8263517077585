import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ChristmasStatus } from '../../graphql/generated';

import { COLORS } from '../../styles/colors';
import { FONTS } from '../../styles/fonts';
import { WEIGHTS } from '../../styles/weights';

const StyledStatus = styled.p<{ color: string; fontColor: string }>`
  display: flex;
  height: 20px;
  background: ${props => props.color};
  color: ${props => props.fontColor} !important;
  font-size: 13px;
  font-family: ${FONTS.Karla} !important;
  font-weight: ${WEIGHTS.regular};
  text-transform: uppercase;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  margin: 13px;
  padding-left: 13px;
  padding-right: 13px;
`;

interface Props {
  seasonStatus: ChristmasStatus;
}

const SeasonStatus: React.FC<Props> = ({ seasonStatus }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'seasonStatus' });
  const text = seasonStatus;
  let color = '';
  let fontColor = '';

  if (seasonStatus === ChristmasStatus.Open) {
    color = COLORS.lightGreen;
    fontColor = COLORS.brightGreen;
  } else if (seasonStatus === ChristmasStatus.Wait) {
    color = COLORS.lightYellow2;
    fontColor = COLORS.darkYellow;
  } else if (seasonStatus === ChristmasStatus.Closed) {
    color = COLORS.lightRed;
    fontColor = COLORS.red;
  } else {
    color = COLORS.grey;
    fontColor = '#000000';
  }

  return (
    <StyledStatus color={color} fontColor={fontColor}>
      {t(text)}
    </StyledStatus>
  );
};

export default SeasonStatus;
