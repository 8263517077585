import { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import EuroOutlinedIcon from '@mui/icons-material/EuroOutlined';

import House from '../../../images/house.svg';
import { COLORS } from '../../../styles/colors';
import { FONTS } from '../../../styles/fonts';
import { WEIGHTS } from '../../../styles/weights';
import {
  CommonContainer as Container,
  CommonTextContainer,
  CommonBoxHeader,
  CommonBoxText,
  CommonImageContainer as ImageContainer,
} from './HomepageStyles';
import { OrganisationSeasonContext } from '../../../App';

const iconStyle = { fontSize: 20, color: COLORS.red, marginRight: 2 };
const questions = [
  {
    question: 'howLong',
    answer: 'answerHowLong',
    icon: <AccessTimeOutlinedIcon sx={iconStyle} />,
    first: true,
  },
  {
    question: 'howMuch',
    answer: 'answerHowMuch',
    icon: <EuroOutlinedIcon sx={iconStyle} />,
  },
];

const SecondBox: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'homepage' });
  const { openSeason, closedSeason } = useContext(OrganisationSeasonContext);

  const answerTextHandler = (answer: string) => {
    switch (answer) {
      case 'answerHowLong': {
        return openSeason?.duration || closedSeason?.duration || '-';
      }
      case 'answerHowMuch': {
        return openSeason?.price || closedSeason?.price || '-';
      }
      default:
        return '';
    }
  };

  return (
    <Container>
      <ImageContainer>
        <img src={House} height={'auto'} width={'45%'} />
      </ImageContainer>

      <TextContainer>
        <BoxHeader>{t('frequentlyAsked')}</BoxHeader>
        {questions.map(item => (
          <Fragment key={item.question}>
            <Question first={item.first}>
              <BoxText>
                {item.icon}
                {t(item.question)}
              </BoxText>
            </Question>
            <Answer>
              <BoxText>{t(item.answer, { param: answerTextHandler(item.answer) })}</BoxText>
            </Answer>
          </Fragment>
        ))}
      </TextContainer>
    </Container>
  );
};

const Question = styled.div<{ first?: boolean }>`
  width: 100%;
  justify-content: space-between;
  border-top: ${props => (props.first ? `1px solid ${COLORS.mediumGrey}` : '')};
  font-family: ${FONTS.Karla};
  font-weight: ${WEIGHTS.regular};
  padding-top: 8px;
  padding-bottom: 10px;
  padding-left: 20px;
`;

const Answer = styled.div`
  width: 100%;
  padding: 3px 20px 10px 20px;
  border-bottom: 1px solid ${COLORS.mediumGrey};
`;

const TextContainer = styled(CommonTextContainer)`
  @media (min-width: 768px) {
    padding: 50px 60px 30px 0px;
  }
`;

const BoxHeader = styled(CommonBoxHeader)`
  margin-bottom: 30px;
`;

const BoxText = styled(CommonBoxText)`
  align-items: 'center';
`;

export default SecondBox;
