import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { MapComponent } from '../../components/map/Map';
import { Order, useSeasonOrdersQuery } from '../../graphql/generated';

import { useLocation } from 'react-router-dom';
import AlertDialog from '../../components/alerts/AlertDialog';
import AlertSnackbar from '../../components/alerts/AlertSnackbar';
import FormModal from '../../components/form/FormModal';
import EditOrderStatus from '../../components/mapView/EditOrderStatus';
import OrderCardList from '../../components/mapView/OrderCardList';
import { useUpdateOrderMutation } from '../../graphql/generated';
import { orderStatus } from '../../types/order';
import { OrganisationSeasonContext } from '../../App';

interface LocationState {
  changeType: string | null;
  setStatusWindow: boolean | null;
  orderSelected: Order | null;
  showAlertDialog: boolean | null;
}

interface IOrderContext {
  orders: Order[];
  selectedOrder: Order | null;
  setSelectedOrder: (order: Order | null) => void;
  filterOptions: { text: string; key: string }[];
  filters: string[];
  setFilters: (filters: string[]) => void;
}

export const OrderContext = React.createContext<IOrderContext>({} as IOrderContext);

const filterOptions = [
  { text: 'all', key: 'ALL' },
  { text: 'confirmed', key: 'CONFIRMED' },
  { text: 'waiting', key: 'WAIT CONFIRMATION' },
  { text: 'rejected', key: 'REJECTED' },
];

const StyledMapViewContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledMapViewColumn = styled.div<{ size: number }>`
  flex: ${props => props.size};
`;

interface ShowOnlyWaitingOrdersProp {
  state: { showOnlyWaitingOrdersProp: boolean } | undefined;
}

const MapView: React.FC = () => {
  const { openSeason, closedSeason } = useContext(OrganisationSeasonContext);

  const location = useLocation().state as LocationState;

  const { data, refetch } = useSeasonOrdersQuery({
    variables: { seasonId: openSeason?.id || closedSeason?.id || '' },
  });
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(location?.orderSelected || null);
  const [filters, setFilters] = useState(filterOptions.map(option => option.key));
  const [formState, setFormState] = useState(false);
  const [typeOfChange, setTypeOfChange] = useState(location?.changeType || '');
  const [changeStatusWindowOpen, setChangeStatusWindowOpen] = useState(
    location?.setStatusWindow || false
  );
  const [showAlert, setShowAlert] = useState(false);
  const [showDialog, setShowDialog] = useState(location?.showAlertDialog || false);
  const [alertText, setAlertText] = useState('');

  const { state } = useLocation() as ShowOnlyWaitingOrdersProp;
  const showOnlyWaitingOrders = state?.showOnlyWaitingOrdersProp;

  const [updateOrderMutation] = useUpdateOrderMutation();

  // Fix this once the database is fixed, only query these statuses
  const orders = (data?.seasonOrders.flatMap(order =>
    order.orderStatus !== 'CONFIRMED' &&
    order.orderStatus !== 'WAIT CONFIRMATION' &&
    order.orderStatus !== 'REJECTED'
      ? []
      : order
  ) || []) as Order[];

  const closeFormHandler = () => {
    refetch();
    setFormState(prevState => !prevState);
  };

  const deleteOrderHandler = async () => {
    await updateOrderMutation({
      variables: {
        id: selectedOrder?.id || '',
        updateInput: {
          orderStatus: orderStatus.deleted,
        },
      },
    });
    setShowAlert(true);
    setAlertText('deleted');
  };

  const setFilterToWaitingOnly = () => {
    setFilters([orderStatus.waitConfirmation]);
  };

  useEffect(() => {
    // Update selectedOrder if order details are changed (update the map popup)
    setSelectedOrder(orders.find(order => order.id === selectedOrder?.id) || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, changeStatusWindowOpen, data]);

  useEffect(() => {
    if (showOnlyWaitingOrders) {
      setFilterToWaitingOnly();
    }
  }, [showOnlyWaitingOrders]);

  return (
    <StyledMapViewContainer onClick={() => [setShowAlert(false), setShowDialog(false)]}>
      {showDialog && (
        <AlertDialog
          cancel={() => setShowDialog(false)}
          ok={deleteOrderHandler}
          alertHeader={'deleteSure'}
          alertText={'deleteOrder'}
        />
      )}
      {showAlert && (
        <AlertSnackbar
          alertType={'success'}
          alertText={alertText}
          close={() => setShowAlert(false)}
        />
      )}
      <OrderContext.Provider
        value={{
          orders,
          selectedOrder,
          setSelectedOrder,
          filterOptions,
          filters,
          setFilters,
        }}>
        <StyledMapViewColumn size={2}>
          {changeStatusWindowOpen ? (
            <EditOrderStatus
              setChangeStatusWindowOpen={setChangeStatusWindowOpen}
              typeOfChange={typeOfChange}
              setShowAlert={setShowAlert}
              setAlertText={setAlertText}
            />
          ) : (
            <OrderCardList
              setFormOpen={setFormState}
              setChangeStatusWindowOpen={setChangeStatusWindowOpen}
              setTypeOfChange={setTypeOfChange}
              setShowAlert={setShowAlert}
              setShowDialog={setShowDialog}
            />
          )}
        </StyledMapViewColumn>
        <StyledMapViewColumn size={5}>
          <MapComponent
            setFormOpen={setFormState}
            setShowDialog={setShowDialog}
            setChangeStatusWindowOpen={setChangeStatusWindowOpen}
            setTypeOfChange={setTypeOfChange}
          />
        </StyledMapViewColumn>
        {formState && (
          <FormModal
            closeForm={closeFormHandler}
            setChangeStatusWindowOpen={setChangeStatusWindowOpen}
            setTypeOfChange={setTypeOfChange}
            selectedOrder={selectedOrder}
            setShowDialog={setShowDialog}
          />
        )}
      </OrderContext.Provider>
    </StyledMapViewContainer>
  );
};

export default MapView;
