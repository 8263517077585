export const seasonFields = [
  [{ label: 'year', required: true, disabled: false, type: 'number' }],
  [{ label: 'price', required: true, disabled: false, type: 'number' }],
  [{ label: 'duration', required: true, disabled: false, type: 'number' }],
  [{ label: 'interval', required: true, disabled: false, type: 'number' }],
  [{ label: 'christmasStatus', required: false, disabled: false, type: 'selected' }],
  [
    { label: 'minStartTime', required: true, disabled: false, type: 'time' },
    { label: 'maxStartTime', required: true, disabled: false, type: 'time' },
  ],
];

export const santaFields = [
  [{ label: 'name', required: true, disabled: false, type: '' }],
  [
    { label: 'santaPhone', required: true, disabled: false, type: '' },
    { label: 'santaEmail', required: false, disabled: false, type: '' },
  ],
  [
    { label: 'position', required: true, disabled: false, type: 'number' },
    { label: 'showOnFrontPage', required: false, disabled: false, type: 'selected' },
  ],
  [
    { label: 'startTime', required: true, disabled: false, type: 'time' },
    { label: 'endTime', required: true, disabled: false, type: 'time' },
  ],
  [
    { label: 'areaNamePublic', required: true, disabled: false, type: '' },
    { label: 'areaNameInternal', required: false, disabled: false, type: '' },
  ],
  [{ label: 'userEmail', required: false, disabled: false, type: 'selected' }],
  [{ label: 'driverName', required: false, disabled: false, type: '' }],
  [
    { label: 'driverPhone', required: false, disabled: false, type: '' },
    { label: 'driverEmail', required: false, disabled: false, type: '' },
  ],
];

export const organisationFields = [
  [{ label: 'name', required: true, disabled: false, type: '' }],
  [{ label: 'email', required: true, disabled: false, type: '' }],
  [{ label: 'frontpageTitle', required: true, disabled: false, type: '' }],
  [{ label: 'frontpageSubtitle', required: true, disabled: false, type: '' }],
  [{ label: 'frontpageDescription', required: true, disabled: false, type: '' }],
  [{ label: 'websiteURL', required: false, disabled: false, type: '' }],
  [{ label: 'phone', required: false, disabled: false, type: '' }],
  [{ label: 'address', required: false, disabled: false, type: '' }],
  [{ label: 'vatId', required: false, disabled: false, type: '' }],
  [{ label: 'logoFile', required: false, disabled: false, type: '' }],
];

export const userFields = [
  [{ label: 'email', required: true, disabled: false, type: '' }],
  [{ label: 'givenName', required: true, disabled: false, type: '' }],
  [{ label: 'familyName', required: true, disabled: false, type: '' }],
  [{ label: 'phone', required: true, disabled: false, type: '' }],
  [{ label: 'roles', required: true, disabled: false, type: 'selected' }],
];

export const userEditFields = [
  [{ label: 'givenName', required: true, disabled: false, type: '' }],
  [{ label: 'familyName', required: true, disabled: false, type: '' }],
  [{ label: 'phone', required: true, disabled: false, type: '' }],
  [{ label: 'roles', required: true, disabled: false, type: 'selected' }],
];
