import { Dispatch, SetStateAction, useContext } from 'react';
import styled from 'styled-components';
import DirectionsOutlinedIcon from '@mui/icons-material/DirectionsOutlined';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../../styles/colors';
import { SantasOrderContext } from '..';
import { FONTS } from '../../../styles/fonts';
import { WEIGHTS } from '../../../styles/weights';
import { saveOrderPdf } from '../../../components/utilities/saveOrderPdf';

const StyledBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
`;

const StyledOptionContainer = styled.div`
  position: absolute;
  bottom: 0;
  height: 200px;
  width: 100%;
  background: ${COLORS.white};
  z-index: 4;
`;

const StyledOption = styled.div`
  position: sticky;
  bottom: 0;
  height: 50px;
  color: ${COLORS.brightGreen};
  border: 0.1px solid ${COLORS.grey};
  font-family: ${FONTS.Karla};
  font-weight: ${WEIGHTS.regular};
  font-size: 13px;
  display: flex;
  align-items: center;
  padding-left: 30px;
`;

const iconStyle = { marginRight: 1.5 };

const options = [
  { text: 'route', icon: <DirectionsOutlinedIcon sx={iconStyle} /> },
  { text: 'call', icon: <PhoneIphoneOutlinedIcon sx={iconStyle} /> },
  { text: 'print', icon: <LocalPrintshopOutlinedIcon sx={iconStyle} /> },
  { text: 'close', icon: <CloseOutlinedIcon sx={iconStyle} /> },
];

interface Props {
  setShowMoreOptions: Dispatch<SetStateAction<boolean>>;
}

const MoreOptionsList: React.FC<Props> = ({ setShowMoreOptions }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'moreOptionsList' });

  const { santaData, selectedSantaOrder } = useContext(SantasOrderContext);

  document.body.style.overflow = 'hidden';

  const clickHandler = (text: string) => {
    switch (text) {
      case 'route': {
        const lonlat = selectedSantaOrder?.lonlat.split(',');
        if (lonlat !== undefined) {
          return window.open(`https://maps.google.com?q=+${lonlat[1]}+,+${lonlat[0]}`);
        }
        return;
      }
      case 'call': {
        return window.open(`tel:${selectedSantaOrder?.phone}`);
      }
      case 'print': {
        if (selectedSantaOrder !== null) {
          saveOrderPdf(
            undefined,
            selectedSantaOrder,
            { name: santaData?.name, area: santaData?.areaNamePublic },
            selectedSantaOrder.lonlat,
            true,
            `pukkipalvelu_${selectedSantaOrder.customerName.replace(' ', '')}`
          );
        }
        return;
      }
      case 'close': {
        document.body.style.overflow = 'unset';
        return setShowMoreOptions(false);
      }
      default:
        return;
    }
  };

  return (
    <StyledBackground>
      <StyledOptionContainer>
        {options.map((option, index) => (
          <StyledOption key={index} onClick={() => clickHandler(option.text)}>
            {option.icon}
            {t(option.text)}
          </StyledOption>
        ))}
      </StyledOptionContainer>
    </StyledBackground>
  );
};

export default MoreOptionsList;
