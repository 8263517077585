import mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';

const MAP_TOKEN = process.env.REACT_APP_MAP_TOKEN;

export const geocoding = async (address: string) => {
  const geocodingClient = mbxGeocoding({
    accessToken: MAP_TOKEN,
  });

  // geocoding with countries
  const response = await geocodingClient
    .forwardGeocode({
      query: address,
      countries: ['fi'],
      limit: 1,
    })
    .send();

  const match = response.body;
  const coordinates = match.features[0].geometry.coordinates;

  return {
    geometry: {
      coordinates: coordinates,
    },
  };
};
