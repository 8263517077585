import styled from 'styled-components';

import { COLORS } from '../../styles/colors';
import { FONTS } from '../../styles/fonts';
import { WEIGHTS } from '../../styles/weights';

const StyledButton = styled.button<{ selected?: boolean; confirm?: boolean }>`
  width: 80px;
  font-family: ${FONTS.Karla};
  font-weight: ${WEIGHTS.bold};
  font-size: 12px;
  color: ${COLORS.brightGreen};
  border: ${props => (props.confirm ? `1px solid ${COLORS.brightGreen}` : COLORS.white)};
  background: ${props => (props.selected ? COLORS.lightGrey : COLORS.white)};
  text-transform: uppercase;
  margin-top: ${props => (props.confirm ? '15px' : '5px')};
  border-radius: 2px;
  margin-bottom: 10px;
  margin-left: ${props => (props.confirm ? '20px' : '5px')};
  padding: 2px;
  cursor: pointer;
  &:hover {
    background: ${props => (props.confirm ? COLORS.brightGreen : '')};
    color: ${props => (props.confirm ? COLORS.white : COLORS.brightGreen)};
  }
`;

export default StyledButton;
