import moment from 'moment';

export const generateTimeSlots = (startTime: string, endTime: string, interval: number) => {
  let timeSlots: string[] = [startTime.slice(0, 5)];
  const format = 'HH:mm';

  while (moment(timeSlots.at(-1), format).isBefore(moment(endTime, format))) {
    timeSlots = [
      ...timeSlots,
      moment(timeSlots.at(-1), format).add(interval, 'minute').format(format),
    ];
  }

  return timeSlots;
};
