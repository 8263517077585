import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SimpleTextField from '../../../../components/common/SimpleTextField';
import { CommonBoxHeader, CommonBoxText as BoxText } from '../HomepageStyles';

const FeedBackInput: React.FC<{
  orgName: string;
  input: string;
  onInputChange: (value: string) => void;
  error: string;
}> = ({ orgName, input, onInputChange, error }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'homepage' });

  return (
    <>
      <BoxHeader>{t('giveFeedback')}</BoxHeader>
      <BoxText>
        {orgName} {t('feedbackInfo')}
      </BoxText>
      <SimpleTextField
        id={'feedBackBox'}
        onChange={event => onInputChange(event.target.value)}
        error={error !== ''}
        helperText={error}
        value={input}
        selectedItems={undefined}
        multiline={true}
        rows={4}
      />
    </>
  );
};

const BoxHeader = styled(CommonBoxHeader)`
  margin-bottom: 15px;
`;

export default FeedBackInput;
