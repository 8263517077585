import { Dispatch, SetStateAction, useContext, useState } from 'react';
import styled from 'styled-components';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stack from '@mui/material/Stack';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import Check from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';

import { COLORS } from '../../styles/colors';
import { FONTS } from '../../styles/fonts';
import { WEIGHTS } from '../../styles/weights';
import OrderFormCard from '../../components/form/OrderFormCard';
import OrderAcceptedBox from '../../components/form/OrderAcceptedBox';
import { ReactComponent as LightsImage } from '../../images/lights.svg';
import Footer from '../../components/common/Footer';
import { OrganisationSeasonContext } from '../../App';
import { NewOrderPdfDetails } from '../../types/newOrderPdfDetails';
import NoDataContainer from '../../components/common/NoDataContainer';

const OrderFormContainer = styled.div`
  width: 100%;
  top: 45px;
  background-color: ${COLORS.green2};
  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const OrderFormTopContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 260px;
  margin-right: 70px;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    margin-left: 4%;
    margin-right: 0%;
  }
`;

const OrderFormHeader = styled.h1`
  flex: 2;
  font-family: ${FONTS.Lora};
  font-weight: ${WEIGHTS.bold};
  font-size: 42px;
  color: ${COLORS.white};
  letter-spacing: 2.4px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: left;
  @media (max-width: 768px) {
    font-size: 26px;
  }
`;

const OrderFormImage = styled.div`
  flex: 1;
  @media (max-width: 768px) {
    margin-left: -4%;
  }
`;

const OrderFormStepper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 80px;
`;

const QontoConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 9,
    left: 'calc(-50% + 10px)',
    right: 'calc(50% + 10px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: COLORS.white,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: COLORS.white,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: COLORS.white,
    borderTopWidth: 3,
  },
}));

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(({ ownerState }) => ({
  color: COLORS.white,
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: COLORS.white,
  }),
  '& .QontoStepIcon-completedIcon': {
    color: COLORS.green2,
    zIndex: 1,
    fontSize: 16,
    borderRadius: '50%',
    backgroundColor: COLORS.white,
  },
  '& .QontoStepIcon-circle': {
    width: 16,
    height: 16,
    borderRadius: '50%',
    border: `1.5px solid ${COLORS.white}`,
    backgroundColor: COLORS.green2,
  },
}));

const OrderFormStepperLabel = styled.div<{ active: boolean }>`
  font-family: ${FONTS.Karla};
  font-weight: ${props => (props.active ? WEIGHTS.extrabold : WEIGHTS.regular)};
  font-size: 14px;
  color: ${COLORS.white};
`;

const steps = ['1. Fill in the fields', '2. Recheck the order', '3. Order has been sent'];

const pdfDetailsInit: NewOrderPdfDetails = {} as NewOrderPdfDetails;

interface Props {
  setFormDirty: Dispatch<SetStateAction<boolean>>;
  homepageClicked: boolean;
  setHomepageClicked: Dispatch<SetStateAction<boolean>>;
}

const OrderForm: React.FC<Props> = ({ setFormDirty, homepageClicked, setHomepageClicked }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'orderForm' });
  const { openSeason } = useContext(OrganisationSeasonContext);

  const [activeStep, setActiveStep] = useState(0);
  const [pdfDetails, setPdfDetails] = useState(pdfDetailsInit);

  const QontoStepIcon = (props: StepIconProps) => {
    const { active, completed, className } = props;

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  };

  if (!openSeason) {
    return (
      <NoDataContainer>
        {t('noOpenSeason')}
        <br />
        {t('noOpenSeasonInfo')}
      </NoDataContainer>
    );
  }

  return (
    <OrderFormContainer>
      <OrderFormTopContainer>
        <OrderFormHeader>
          {activeStep !== 3 ? t('orderSanta') : t('thankYouForOrder')}
        </OrderFormHeader>
        <OrderFormImage>
          <LightsImage />
        </OrderFormImage>
      </OrderFormTopContainer>

      {openSeason && (
        <OrderFormStepper>
          <Stack
            sx={{
              width: '40%',
              '@media (max-width: 768px)': {
                width: '98%',
              },
            }}>
            <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={QontoStepIcon}>
                    <OrderFormStepperLabel active={index === activeStep}>
                      {t(label)}
                    </OrderFormStepperLabel>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Stack>
        </OrderFormStepper>
      )}

      {activeStep === 3 && <OrderAcceptedBox pdfDetails={pdfDetails} />}

      <OrderFormCard
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        setPdfDetails={setPdfDetails}
        setFormDirty={setFormDirty}
        homepageClicked={homepageClicked}
        setHomepageClicked={setHomepageClicked}
      />
      <Footer />
    </OrderFormContainer>
  );
};

export default OrderForm;
