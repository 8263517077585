import styled from 'styled-components';

const StyledCardText = styled.span<{
  font: string;
  weight: number;
  size: number;
  color: string;
  marginBottom?: number;
  display?: string;
  multiline?: boolean;
}>`
  display: ${props => props.display};
  font-size: ${props => props.size}px;
  font-family: ${props => props.font};
  font-weight: ${props => props.weight};
  color: ${props => props.color};
  letter-spacing: 0.32px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: ${props => props.marginBottom}px;
  white-space: ${props => (props.multiline ? 'pre-line' : '')};
`;

export default StyledCardText;
