import styled from 'styled-components';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { COLORS } from '../../styles/colors';
import { FONTS } from '../../styles/fonts';
import { WEIGHTS } from '../../styles/weights';
import { ReactComponent as LightsImage } from '../../images/lights.svg';
import CarWithGift from '../../images/carWithGift.svg';
import { useTranslation } from 'react-i18next';
import { useOrganisationsInfoQuery, useSeasonsQuery } from '../../graphql/generated';
import { StyledButton } from '../../components/common/StyledButton';
import SantaWithTree from '../../images/santaWithTree.svg';

const HomePageContainer = styled.div`
  width: 100%;
  height: calc(100% - 45px);
  position: absolute;
  top: 45px;
  background-color: ${COLORS.green2};
  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const HomePageTopContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 10%;
  margin-right: 4%;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    margin-left: 4%;
  }
`;

const HomePageHeaderContainer = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
`;

const HomePageHeader = styled.h1<{ subtitle?: boolean }>`
  flex: ${props => (props.subtitle ? '1' : '5')};
  font-family: ${FONTS.Lora};
  font-weight: ${WEIGHTS.medium};
  font-size: ${props => (props.subtitle ? '17px' : '42px')};
  color: ${COLORS.white};
  letter-spacing: ${props => (props.subtitle ? '0.36px' : '2.4px')};
  text-transform: ${props => (props.subtitle ? '' : 'uppercase')};
  display: flex;
  align-items: end;
  margin-bottom: 80px;
  @media (max-width: 768px) {
    font-size: ${props => (props.subtitle ? '16px' : '26px')};
    margin-bottom: 20px;
  }
`;

const OrganisationTextContainer = styled.div`
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const HomePageImage = styled.div`
  flex: 1;
  @media (max-width: 768px) {
    margin-left: -4%;
  }
`;

const OrganisationText = styled.div<{ address: boolean }>`
  font-size: ${props => (props.address ? '16px' : '19px')};
  margin-bottom: ${props => (props.address ? '0px' : '12px')};
  font-weight: ${props => (props.address ? WEIGHTS.regular : WEIGHTS.semibold)};
  @media (max-width: 768px) {
    text-align: left;
  }
`;

const HomePageOrganisationContainer = styled.div`
  min-height: 80px;
  background-color: ${COLORS.white};
  font-family: ${FONTS.Karla};
  color: ${COLORS.green2};
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 30px;
  margin-top: 30px;
  padding: 15px;
  padding-right: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    padding-right: 25px;
    padding-left: 25px;
    margin-left: 4%;
    margin-right: 4%;
    flex-direction: column;
  }
`;

const ImageContainerCar = styled.div`
  align-items: center;
  margin-top: 80px;
  margin-bottom: 30px;
  margin-left: 10%;
  @media (max-width: 768px) {
    margin-top: 50px;
    img {
      width: 30%;
    }
  }
`;

const ImageContainerSanta = styled.div`
  flex: 1;
  display: flex;
  justify-content: right;
  margin-right: 10%;
  margin-bottom: -4%;
  margin-top: -3%;
  @media (max-width: 768px) {
    img {
      width: 35%;
      margin-right: -15%;
    }
  }
`;

const GlobalHomepage: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'glabalHomepage' });
  const navigate = useNavigate();

  const { data: organisations } = useOrganisationsInfoQuery();
  const { data: seasons } = useSeasonsQuery();

  const splitAddressHandler = (address: string | undefined | null) => {
    const splitAddress = address?.split(' ');
    return <>{splitAddress?.slice(splitAddress?.length - 1, splitAddress?.length)}</>;
  };

  // Organisations are organized by the city
  const comparePostalcode = (address: string | undefined | null) => {
    const splitAddress = address?.split(' ');
    return splitAddress?.slice(splitAddress?.length - 1, splitAddress?.length).join(' ') || '';
  };

  // Organisation is only displayed on the page if it has this year's season
  const findCurrentSeason = (organisationId: string) => {
    const currentSeason = moment().format('YYYY');

    return seasons?.seasons.some(
      season => season.organisationId === organisationId && season.year === Number(currentSeason)
    );
  };

  const clickOrganisationHandler = (organisationShortName: string) => {
    navigate(`/${organisationShortName}`);
  };

  return (
    <>
      <HomePageContainer>
        <HomePageTopContainer>
          <HomePageHeaderContainer>
            <HomePageHeader>{t('welcome')}</HomePageHeader>
            <HomePageHeader subtitle={true}>{t('info')}</HomePageHeader>
          </HomePageHeaderContainer>
          <HomePageImage>
            <LightsImage />
          </HomePageImage>
        </HomePageTopContainer>
        <ImageContainerSanta>
          <img src={SantaWithTree} width={'20%'} height={'auto'} />
        </ImageContainerSanta>
        {organisations?.organisationInfo
          .slice()
          .sort((a, b) =>
            comparePostalcode(a.address)
              ?.toUpperCase()
              .localeCompare(comparePostalcode(b.address)?.toUpperCase(), 'fi', {
                sensitivity: 'base',
              })
          )
          .map(
            organisation =>
              findCurrentSeason(organisation.id) && (
                <HomePageOrganisationContainer key={organisation.id}>
                  <OrganisationTextContainer>
                    <OrganisationText address={false}>{organisation.name}</OrganisationText>
                    <OrganisationText address={true}>
                      {splitAddressHandler(organisation?.address)}
                    </OrganisationText>
                  </OrganisationTextContainer>

                  <StyledButton
                    globalFrontPage={true}
                    onClick={() => clickOrganisationHandler(organisation.shortName)}>
                    {t('move')}
                  </StyledButton>
                </HomePageOrganisationContainer>
              )
          )}
        <ImageContainerCar>
          <img src={CarWithGift} width={'14%'} />
        </ImageContainerCar>
      </HomePageContainer>
    </>
  );
};

export default GlobalHomepage;
