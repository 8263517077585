import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Checkbox from '@mui/material/Checkbox';
import { CommonBoxText as BoxText } from '../HomepageStyles';
import { COLORS } from '../../../../styles/colors';

const FeedbackCheckbox: React.FC<{
  feedbackWanted: boolean;
  setFeedbackWanted: (value: boolean) => void;
}> = ({ feedbackWanted, setFeedbackWanted }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'homepage' });

  return (
    <CheckboxRow>
      <Checkbox
        checked={feedbackWanted}
        id="feedBackBox"
        onChange={() => setFeedbackWanted(!feedbackWanted)}
        sx={{
          color: COLORS.brightGreen,
          '&.Mui-checked': {
            color: COLORS.brightGreen,
          },
        }}
      />
      <BoxText>{t('takeContact')}</BoxText>
    </CheckboxRow>
  );
};

const CheckboxRow = styled.div`
  width: 100%;
  height: 20px;
  margin-left: -10px;
  margin-top: 15px;
  margin-bottom: 13px;
  display: flex;
  align-items: center;
`;

export default FeedbackCheckbox;
