import * as React from 'react';
import { useContext } from 'react';
import styled from 'styled-components';
import { Order } from '../../graphql/generated';
import { OrderContext } from '../../pages/mapView';
import { SantasOrderContext } from '../../pages/santasView';
import { COLORS } from '../../styles/colors';
import { orderStatus } from '../../types/order';

interface PinProps {
  order: Order;
}

const getFillColor = (status, isSelected: boolean) => {
  if (isSelected) {
    switch (status) {
      case orderStatus.waitConfirmation:
        return COLORS.darkYellow;
      case orderStatus.confirmed:
        return COLORS.brightGreen;
      default:
        return COLORS.red;
    }
  }

  switch (status) {
    case orderStatus.waitConfirmation:
      return COLORS.lightYellow2;
    case orderStatus.confirmed:
      return COLORS.lightGreen;
    default:
      return COLORS.redStatus;
  }
};

const getTextColor = (isSelected: boolean) => (isSelected ? COLORS.white : COLORS.brightGreen);

const getPinText = (order: Order) => {
  if (order.confirmedStartAt) {
    return order.confirmedStartAt.slice(0, 5);
  } else {
    return `${order.wishEarliest.slice(0, 5)}-${order.wishLatest.slice(0, 5)}`;
  }
};

const Pin: React.FC<PinProps> = ({ order }) => {
  const { selectedOrder } = useContext(OrderContext);
  const { selectedSantaOrder } = useContext(SantasOrderContext);
  const isSelected = order.id === (selectedOrder?.id || selectedSantaOrder?.id);

  return (
    <PinWrapper
      fillColor={getFillColor(order.orderStatus, isSelected)}
      textColor={getTextColor(isSelected)}>
      <h4>{getPinText(order)}</h4>
    </PinWrapper>
  );
};

const PinWrapper = styled.div<{ fillColor: string; textColor: string }>`
  background-color: ${({ fillColor: color }) => color};
  padding: 6px 6px;
  display: inline-block;
  border-radius: 100px;
  position: relative;
  border: solid 2px #345f5a;

  &::after {
    content: '';
    position: absolute;
    bottom: -7px;
    transform: translateX(50%);
    right: 50%;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid ${({ fillColor: color }) => color};
  }

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    bottom: -10px;
    transform: translateX(50%);
    right: 50%;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid #345f5a;
  }

  & > h4 {
    margin: 0;
    color: ${({ textColor: color }) => color};
  }
`;

export default React.memo(Pin);
