import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import {
  Organisation,
  useOrganisationUsersQuery,
  useRemoveUserMutation,
  UserEntity,
} from '../../graphql/generated';
import AlertSnackbar from '../alerts/AlertSnackbar';

const UserTableWrapper = styled.div`
  display: flex;
  width: 100%;
`;

interface UserTableProps {
  organisation: Organisation;
  setTypeCreate: Dispatch<SetStateAction<string>>;
  setFormOpen: Dispatch<SetStateAction<boolean>>;
  formClosed: boolean;
  setSelectedUser: Dispatch<SetStateAction<UserEntity | null>>;
}

const UsersTable: React.FC<UserTableProps> = ({
  organisation,
  setTypeCreate,
  setFormOpen,
  formClosed,
  setSelectedUser,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin' });
  const [removeUser] = useRemoveUserMutation();

  const {
    data: usersData,
    loading,
    refetch,
  } = useOrganisationUsersQuery({
    variables: { organisationId: organisation.id },
  });
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (!formClosed) {
      refetch();
    }
  }, [formClosed, refetch]);

  const editUser = (id: string | number) => {
    setSelectedUser(usersData?.organisationUsers.find(user => user.id === id) || null);
    setTypeCreate('userEdit');
    setFormOpen(true);
  };

  const deleteUser = async (id: string) => {
    try {
      await removeUser({ variables: { id } });
      refetch();
    } catch (err) {
      setShowAlert(true);
    }
  };

  const columns: GridColDef[] = [
    { field: 'email', headerName: t('userName'), flex: 5 },
    { field: 'given_name', headerName: t('givenName'), flex: 2 },
    { field: 'family_name', headerName: t('familyName'), flex: 2 },
    { field: 'roles', headerName: t('userRoles'), flex: 5 },
    {
      field: 'edit',
      headerName: t('edit'),
      flex: 2,
      align: 'center',
      renderCell: cellValues => (
        <Button
          icon
          basic
          onClick={() => {
            setShowAlert(false);
            editUser(cellValues.row.id);
          }}>
          <Icon name="pencil" />
        </Button>
      ),
    },
    {
      field: 'delete',
      headerName: t('delete'),
      flex: 2,
      align: 'center',
      renderCell: cellValues => (
        <Button
          icon
          basic
          onClick={() => {
            deleteUser(cellValues.row.id);
          }}>
          <Icon name="close" />
        </Button>
      ),
    },
  ];

  const getRows = () => {
    const rows = usersData?.organisationUsers.map(user => ({
      ...user,
      editFunction: () => editUser(user.id),
    }));
    return rows || [];
  };

  return (
    <UserTableWrapper>
      {!loading && (
        <>
          {showAlert && (
            <AlertSnackbar
              alertType={'error'}
              alertText={'deleteSantaError'}
              close={() => setShowAlert(false)}
            />
          )}
          <DataGrid<UserEntity & { editFunction: () => void }>
            sx={{
              '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                outline: 'none !important',
              },
            }}
            disableColumnMenu
            rows={getRows()}
            columns={columns}
            pageSize={25}
            rowsPerPageOptions={[25]}
            autoHeight
            disableSelectionOnClick
          />
        </>
      )}
    </UserTableWrapper>
  );
};

export default UsersTable;
