import moment from 'moment';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { NavLink, useParams } from 'react-router-dom';

import { Area, Order, Santa, Season, SeasonOrdersQuery } from '../../graphql/generated';
import { COLORS } from '../../styles/colors';
import { FONTS } from '../../styles/fonts';
import { WEIGHTS } from '../../styles/weights';
import { orderStatus } from '../../types/order';
import TableRow from './TableRow';
import { findOrderAtTimeSlot, findOrdersByStatus } from '../../utils/orderUtil';
import { generateTimeSlots } from '../../utils/timeUtil';
import { ApolloQueryResult } from '@apollo/client';
import { useSeasonSchedule } from '../../hooks/useSeasonSchedule';
import TableHeader from './TableHeader';
import OverviewRow from './OverviewRow';
interface TimeCellData {
  timeSlot: string;
  santa: Santa;
  order?: Order;
}
interface TimeTableProps {
  setSelectedOrder: (order: Order) => void;
  santas: Santa[];
  orders: Order[];
  refetchOrders: () => Promise<ApolloQueryResult<SeasonOrdersQuery>>;
  season: Season;
}

const TimeTable: React.FC<TimeTableProps> = ({
  setSelectedOrder,
  santas,
  orders,
  refetchOrders,
  season,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'dashboard' });
  const { orgName } = useParams();
  const { areas, santas: santasSlots } = useSeasonSchedule(season, orders);

  const seasonTimeSlots = generateTimeSlots(
    season!.minStartTime,
    moment(season!.maxStartTime, 'HH:mm:ss').format('HH:mm:ss'),
    season!.interval
  );

  const santasSchedule = (santa: Santa) =>
    seasonTimeSlots.map(timeSlot => {
      const order = findOrderAtTimeSlot(orders, timeSlot, santa.id);
      return { timeSlot, santa, order };
    });

  const timeTableData: TimeCellData[] = santas?.flatMap(santa => santasSchedule(santa));

  const totalFreeSlots = areas.reduce((sum: number, area: Area) => sum + area.freeTimes, 0);
  const totalOrders = findOrdersByStatus(orders, orderStatus.confirmed).length;
  const unprocessedOrders = findOrdersByStatus(orders, orderStatus.waitConfirmation).length;

  return (
    <>
      <SeasonStatusWrapper>
        <h2>{`${t('season')}: ${season.year}`} </h2>
        <StyledOrderInfoText>
          {`${t('orders')} ${totalOrders} ${t('pcs')} | ${t('freeSlots')} ${totalFreeSlots} ${t(
            'pcs'
          )} | `}
          <StyledNavLink to={`/${orgName}/admin/map`} state={{ showOnlyWaitingOrdersProp: true }}>
            {`${t('unprocessedOrders')} ${unprocessedOrders} ${t('pcs')}`}
          </StyledNavLink>
        </StyledOrderInfoText>
      </SeasonStatusWrapper>
      <StyledTimeTable>
        <table>
          <TableHeader
            santas={santas}
            season={season}
            santasSlots={santasSlots}
            santasSchedule={santasSchedule}
          />
          <tbody>
            <OverviewRow santas={santas} slots={santasSlots} />
            {seasonTimeSlots.map(e => (
              <TableRow
                key={e}
                time={e}
                refetchOrders={refetchOrders}
                timeTableData={timeTableData}
                setSelectedOrder={setSelectedOrder}
                season={season as Season}
              />
            ))}
          </tbody>
        </table>
      </StyledTimeTable>
    </>
  );
};

const StyledTimeTable = styled.div`
  overflow-x: auto;

  table {
    margin: 32px;
    border-collapse: separate;
    border-spacing: 0;
    border-radius: 1em;
    table-layout: fixed;
  }

  th,
  td {
    border: 1px solid ${COLORS.mediumGrey};
    font-family: ${FONTS.Karla};
    font-weight: lighter;
    padding: 8px;
  }

  table th:last-child {
    border-top-right-radius: 1em;
  }

  .santaCell,
  .cornerCell {
    padding: 12px;
    text-align: left;
    background-color: ${COLORS.green2};
    color: #ffebd0;

    & .dropdown-wrapper-santa {
      display: none;
    }

    :hover,
    :active {
      & .dropdown-wrapper-santa {
        display: inline-block;
        float: right;
        margin-right: 30px;
      }
    }
  }

  .cornerCell {
    border-top-left-radius: 1em;
  }

  p {
    margin: 0;
  }

  .areaText {
    color: #ffffff;
    margin: 0;
  }

  .overviewRow {
    background-color: ${COLORS.green3};
    color: #ffffff;
  }

  .timeCell {
    color: ${COLORS.brightGreen};
    min-width: 72px;
  }

  .dropdown-wrapper {
    display: none;
    position: absolute;
    bottom: 4px;
    right: 18px;
    height: 25px;
    width: 21px;
  }
`;

const SeasonStatusWrapper = styled.div`
  display: flex;
  margin-left: 40px;
  margin-bottom: -24px;
  align-items: center;
  color: ${COLORS.brightGreen};
  h2 {
    font-family: ${FONTS.Lora};
    font-weight: ${WEIGHTS.medium};
    font-size: 18px;
    letter-spacing: 0.51px;
    padding-top: 13px;
  }
  p {
    font-family: ${FONTS.Karla};
    font-weight: lighter;
    padding: 8px;
  }
`;

const StyledNavLink = styled(NavLink)`
  color: ${COLORS.green2};
  &:hover,
  &:focus,
  &:active {
    color: ${COLORS.green2};
    text-decoration: underline;
  }
`;
const StyledOrderInfoText = styled.p`
  font-family: ${FONTS.Karla};
  color: ${COLORS.green2};
`;

export default TimeTable;
