import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { Dispatch, SetStateAction, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Order, useSantaQuery } from '../../graphql/generated';
import { OrderContext } from '../../pages/mapView';
import { COLORS } from '../../styles/colors';
import { FONTS } from '../../styles/fonts';
import { WEIGHTS } from '../../styles/weights';
import StyledCardButton from '../common/StyledCardButton';
import StyledCardText from '../common/StyledCardText';
import DropDown from '../common/DropDown';
import ExpandableItem from '../common/ExpandableItem';
import OrderStatus from '../common/OrderStatus';
import { orderStatus } from '../../types/order';
import { saveOrderPdf } from '../utilities/saveOrderPdf';
import moment from 'moment';

interface Props {
  order: Order;
  detailsHandler?: (isOpen: boolean) => void;
  place: string;
  close?: () => void;
  setFormOpen?: Dispatch<SetStateAction<boolean>>;
  setShowMoreOptions?: Dispatch<SetStateAction<boolean>>;
  setSelectedSantaOrder?: (order: Order | null) => void;
  hideClose?: boolean;
  setShowSelectedOrder?: Dispatch<SetStateAction<boolean>>;
  setShowDialog?: Dispatch<SetStateAction<boolean>>;
  setChangeStatusWindowOpen?: Dispatch<SetStateAction<boolean>>;
  setTypeOfChange?: Dispatch<SetStateAction<string>>;
}

const StyledInfoCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const StyledIconContainer = styled.div`
  display: flex;
  justify-content: right;
  padding-right: 10px;
  margin-bottom: -20px;
  z-index: 3;
`;

const ReceivedText = styled.div`
  width: 55%;
  font-size: 12px;
  font-family: ${FONTS.Karla};
  font-weight: ${WEIGHTS.regular};
  color: ${COLORS.brightGreen};
`;

const iconStyle = { fontSize: 20, marginBottom: -0.6 };

const ContactInfoCard: React.FC<Props> = ({
  order,
  detailsHandler,
  place,
  close,
  setFormOpen,
  setShowMoreOptions,
  setSelectedSantaOrder,
  hideClose,
  setShowSelectedOrder,
  setShowDialog,
  setChangeStatusWindowOpen,
  setTypeOfChange,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'orderCards' });

  const { selectedOrder, setSelectedOrder } = useContext(OrderContext);

  const { data } = useSantaQuery({
    variables: { id: order.santaId || '' },
  });

  const functions = [
    { text: 'Edit', key: 'edit' },
    { text: 'Print', key: 'print' },
    { text: 'Delete', key: 'delete' },
  ];

  if (order.orderStatus === orderStatus.confirmed) {
    functions.splice(1, 0, { text: 'Move', key: 'move' });
  }

  if (order.orderStatus === orderStatus.waitConfirmation) {
    functions.splice(1, 0, { text: 'Reject', key: 'reject' });
    functions.splice(1, 0, { text: 'Confirm', key: 'confirm' });
  }

  if (order.orderStatus === orderStatus.rejected) {
    functions.splice(1, 0, { text: 'Confirm', key: 'confirm' });
  }

  const mapViewTimeHandler = () => {
    switch (order.orderStatus) {
      case orderStatus.confirmed: {
        return `${order.confirmedStartAt?.slice(0, 5)}`;
      }
      case orderStatus.waitConfirmation: {
        return `${t('timeWish')}: ${order.wishEarliest.slice(0, 5)} - ${order.wishLatest.slice(
          0,
          5
        )}`;
      }
      case orderStatus.cancelled: {
        return `${t('rejectedTimeWish')}: ${order.wishEarliest.slice(
          0,
          5
        )} - ${order.wishLatest.slice(0, 5)}`;
      }
      default:
        return null;
    }
  };

  const openFunctionHandler = (text: string, key: string) => {
    setSelectedOrder(order);
    if (key === 'edit' && setFormOpen !== undefined) {
      setFormOpen(true);
    } else if (key === 'print') {
      saveOrderPdf(
        undefined,
        order,
        { name: data?.santa?.name, area: data?.santa?.areaNamePublic },
        order.lonlat,
        order.confirmedStartAt !== null,
        `pukkipalvelu_${order.customerName.replace(' ', '')}_${
          data?.santa?.areaNamePublic || 'notConfirmed'
        }`
      );
    } else if (key === 'delete' && setShowDialog !== undefined) {
      setShowDialog(true);
    } else if (
      (key === 'confirm' || key === 'move' || key === 'reject') &&
      setTypeOfChange !== undefined &&
      setChangeStatusWindowOpen !== undefined
    ) {
      setTypeOfChange(key);
      setChangeStatusWindowOpen(true);
    }
  };

  const clickMoreHandler = event => {
    event.stopPropagation();
    if (setShowMoreOptions) {
      setShowMoreOptions(true);
      if (setSelectedSantaOrder) {
        setSelectedSantaOrder(order);
      }
    }
  };

  const clickCloseHandler = event => {
    event.stopPropagation();
    if (close) {
      close();
    }
  };

  const clickCardHandler = () => {
    if (place === 'santaview' && setShowSelectedOrder !== undefined) {
      setShowSelectedOrder(true);
    }
  };

  return (
    <div onClick={clickCardHandler}>
      {place === 'santaview' && (
        <StyledIconContainer>
          {setShowMoreOptions !== undefined && (
            <MoreHorizOutlinedIcon
              sx={{ marginRight: 0.5, color: COLORS.brightGreen2 }}
              onClick={clickMoreHandler}
            />
          )}
          {!hideClose && (
            <CloseOutlinedIcon sx={{ color: COLORS.brightGreen2 }} onClick={clickCloseHandler} />
          )}
        </StyledIconContainer>
      )}
      <StyledInfoCardHeader>
        {place === 'santaview' ? (
          <StyledCardText
            font={FONTS.Lora}
            weight={WEIGHTS.medium}
            size={14}
            color={COLORS.brightGreen}>
            <AccessTimeOutlinedIcon sx={{ fontSize: 20, marginBottom: -0.5, marginRight: 0.5 }} />
            {order.confirmedStartAt?.slice(0, 5) ||
              `${order.wishEarliest.slice(0, 5)} - ${order.wishLatest.slice(0, 5)}`}
          </StyledCardText>
        ) : (
          <>
            <OrderStatus statusType={order.orderStatus} />
            {order.orderStatus === orderStatus.waitConfirmation && (
              <ReceivedText>
                {t('received', { param: moment().diff(moment(Number(order.createdAt)), 'days') })}
              </ReceivedText>
            )}
          </>
        )}
        {place === 'map' && (
          <StyledIconContainer>
            <DropDown
              defaultIcon={<MoreHorizOutlinedIcon />}
              optionArray={functions}
              onArrangeChange={openFunctionHandler}
              openOnFocus={false}
            />
          </StyledIconContainer>
        )}
      </StyledInfoCardHeader>
      <div>
        <StyledCardText
          display={'inline-block'}
          font={FONTS.Lora}
          weight={WEIGHTS.medium}
          size={13}
          color={COLORS.brightGreen}
          marginBottom={5}>
          {order.streetAddress}, {order.postalCode} {order.city}
        </StyledCardText>
      </div>
      <div>
        <StyledCardText
          display={'inline-block'}
          font={FONTS.Karla}
          weight={WEIGHTS.regular}
          size={12}
          color={COLORS.brightGreen}>
          {order.visitName} {place === 'santaview' && `| ${order.phone}`}
        </StyledCardText>
      </div>
      {place !== 'santaview' && (
        <div>
          <StyledCardText
            display={'inline-block'}
            font={FONTS.Karla}
            weight={place === 'list' ? WEIGHTS.regular : WEIGHTS.extrabold}
            size={12}
            color={
              place === 'list'
                ? COLORS.brightGreen
                : order.orderStatus === orderStatus.confirmed
                ? COLORS.brightGreen
                : order.orderStatus === orderStatus.waitConfirmation
                ? COLORS.darkYellow
                : COLORS.red
            }>
            {place === 'list' ? order.phone : mapViewTimeHandler()}
          </StyledCardText>
        </div>
      )}
      {place !== 'santaview' && place !== 'list' && (
        <div>
          <StyledCardText
            display={'inline-block'}
            font={FONTS.Karla}
            weight={WEIGHTS.regular}
            size={12}
            color={COLORS.brightGreen}>
            {order.orderStatus === orderStatus.confirmed
              ? `${data?.santa.name} | ${
                  data?.santa.areaNameInternal || data?.santa.areaNamePublic
                }`
              : '-'}
          </StyledCardText>
        </div>
      )}
      {detailsHandler && (
        <ExpandableItem
          render={(xprops: { open: boolean; setOpen: Dispatch<SetStateAction<boolean>> }) => (
            <StyledCardButton
              onClick={() => [detailsHandler(!xprops.open), xprops.setOpen(!xprops.open)]}
              selected={place === 'list' && order.id === selectedOrder?.id}>
              {t('information')}
              {xprops.open ? (
                <ExpandLessOutlinedIcon sx={iconStyle} />
              ) : (
                <ExpandMoreOutlinedIcon sx={iconStyle} />
              )}
            </StyledCardButton>
          )}
        />
      )}
    </div>
  );
};

export default ContactInfoCard;
