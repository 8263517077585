import styled from 'styled-components';
import { Organisation } from '../../../graphql/generated';
import { COLORS } from '../../../styles/colors';

interface OrganisationInfoCardProps {
  organisation: Organisation;
  setSelectedOrganisation: (organisation: Organisation) => void;
}

const OrganisationInfoWrapper = styled.div`
  border-style: solid;
  border-width: 1px;
  border-color: grey;
  border-radius: 4px;
  margin: 10px 10px 10px 0;
  padding: 10px;
  width: 100%;
  color: black;

  h4 {
    margin: 0;
    color: ${COLORS.brightGreen};
  }

  :hover {
    background-color: ${COLORS.lightGrey};
  }
`;

const OrganisationInfoCard: React.FC<OrganisationInfoCardProps> = ({
  organisation,
  setSelectedOrganisation,
}) => {
  const { name, email, websiteURL } = organisation;

  return (
    <OrganisationInfoWrapper onClick={() => setSelectedOrganisation(organisation)}>
      <h4>{name}</h4>
      <p>
        {websiteURL}
        <br />
        {email}
      </p>
    </OrganisationInfoWrapper>
  );
};

export default OrganisationInfoCard;
