import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownMenu, DropdownItem } from 'semantic-ui-react';
import styled from 'styled-components';

import { COLORS } from '../../styles/colors';
import { SvgIconProps } from '@mui/material';
import { WEIGHTS } from '../../styles/weights';
import { FONTS } from '../../styles/fonts';

export const StyledDropdownContainer = styled.div`
  position: absolute;
  margin-left: 10px;
`;

const StyledDropdown = styled(Dropdown)`
  font-family: ${FONTS.Karla} !important;
  font-size: 12px;
  font-weight: ${WEIGHTS.regular};
  color: ${COLORS.brightGreen} !important;
`;

const StyledDropdownItem = styled(DropdownItem)`
  min-width: 110px;
  font-family: ${FONTS.Karla} !important;
  font-weight: ${WEIGHTS.regular};
  font-size: 10px !important;
  color: ${COLORS.brightGreen} !important;
`;

interface Props {
  defaultValue?: string;
  defaultIcon?: React.ReactElement<SvgIconProps>;
  optionArray: Array<{ text: string; key: string }>;
  onArrangeChange: (text: string, key: string) => void;
  onOpen?: () => void;
  onClose?: () => void;
  openOnFocus?: boolean;
  openOptions?: boolean;
  santa?: boolean;
}

const DropDown: React.FC<Props> = ({
  defaultValue,
  defaultIcon,
  optionArray,
  onArrangeChange,
  openOnFocus,
  openOptions,
  santa,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'dropdownOptions' });

  return (
    <StyledDropdownContainer>
      <StyledDropdown
        text={defaultValue ? t(defaultValue) : ''}
        icon={defaultIcon || (openOptions !== undefined ? '' : 'dropdown')}
        openOnFocus={openOnFocus}>
        <DropdownMenu open={openOptions} style={santa && { right: 0, left: 'auto' }}>
          {optionArray.map(item => (
            <StyledDropdownItem
              key={item.text}
              text={t(item.text)}
              onClick={() => onArrangeChange(item.text, item.key)}
            />
          ))}
        </DropdownMenu>
      </StyledDropdown>
    </StyledDropdownContainer>
  );
};

export default DropDown;
