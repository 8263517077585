import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { COLORS } from '../../styles/colors';
import { FONTS } from '../../styles/fonts';
import { WEIGHTS } from '../../styles/weights';
import { StyledButton } from '../common/StyledButton';
import santaImg from '../../images/santa.svg';
import { NewOrderPdfDetails } from '../../types/newOrderPdfDetails';
import { saveOrderPdf } from '../utilities/saveOrderPdf';
import { useContext } from 'react';
import { OrganisationSeasonContext } from '../../App';

const OrderAcceptedContainer = styled.div`
  margin-left: calc(100% / 6);
  margin-right: calc(100% / 6);
  padding: 25px;
  margin-bottom: 60px;
  background-color: ${COLORS.white};
  color: ${COLORS.brightGreen};
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    margin-left: 0%;
    margin-right: 0%;
    flex-direction: column;
  }
`;

const OrderAcceptedTextContainer = styled.div`
  flex: 3;
  margin-right: -10px;
  z-index: 2;
`;

const OrderAcceptedHeader = styled.h2`
  font-family: ${FONTS.Lora};
  font-weight: ${WEIGHTS.medium};
  font-size: 30px;
  letter-spacing: 0.33px;
  margin-top: 50px;
  margin-bottom: 30px;
`;

const OrderAcceptedText = styled.div`
  font-family: ${FONTS.Karla};
  font-weight: ${WEIGHTS.regular};
  font-size: 15px;
  margin: 15px;
`;

const OrderAcceptedImageContainer = styled.div`
  flex: 1;
  margin-top: -155px;
  margin-right: -145px;
  margin-bottom: -100px;
  @media (max-width: 768px) {
    margin-top: -80px;
    margin-right: 0px;
    margin-bottom: 0px;
    img {
      width: 35%;
      height: auto;
      float: right;
    }
  }
`;

interface Props {
  pdfDetails: NewOrderPdfDetails;
}

const OrderAccepted: React.FC<Props> = ({ pdfDetails }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'orderAccepted' });

  const { organisation } = useContext(OrganisationSeasonContext);

  const pdfHandler = async () => {
    saveOrderPdf(
      pdfDetails.order,
      undefined,
      undefined,
      undefined,
      false,
      `pukkipalvelu_${pdfDetails.order.name.replace(' ', '')}`
    );
  };

  return (
    <OrderAcceptedContainer>
      <OrderAcceptedTextContainer>
        <OrderAcceptedHeader>{t('importantInformation')}</OrderAcceptedHeader>
        <OrderAcceptedText>{t('info1')}</OrderAcceptedText>
        <OrderAcceptedText>{t('info2', { organisationName: organisation.name })}</OrderAcceptedText>
        <OrderAcceptedText>
          {t('info3', { organisationEmail: organisation.email })}
        </OrderAcceptedText>
        <StyledButton onClick={pdfHandler}>{t('openPdf')}</StyledButton>
      </OrderAcceptedTextContainer>

      <OrderAcceptedImageContainer>
        <img src={santaImg} height={'480px'} />
      </OrderAcceptedImageContainer>
    </OrderAcceptedContainer>
  );
};

export default OrderAccepted;
