import axios from 'axios';
import fileDownload from 'js-file-download';
import i18next from 'i18next';
import moment from 'moment';
import { Order } from '../../graphql/generated';

const URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_BACKEND_API_KEY!;

export const saveSantasSchedulePdf = async (
  mainHeader: string,
  subHeader: string,
  info: string,
  orders: { time: string; order: Order }[],
  filename: string
) => {
  try {
    const form = {
      template: 'santasScheduleTemplate',
      options: {
        format: 'A4',
        orientation: 'portrait',
        border: '10mm',
        header: {
          height: '10mm',
        },
        footer: {
          height: '10mm',
        },
        locals: {
          mainHeader: mainHeader,
          subHeader: subHeader,
          time: i18next.t('dashboard.time'),
          orderDetails: i18next.t('dashboard.orderDetails'),
          info: info,
          orders: orders,
          printedAt: `${i18next.t('dashboard.printed')}: ${moment().format(
            'DD.MM.YYYY'
          )} ${i18next.t('dashboard.at')} ${moment().format('HH:mm')}`,
        },
      },
    };
    const response = await axios.post(`${URL}/pdf/generatePDF`, form, {
      responseType: 'arraybuffer',
      headers: {
        apiKey: API_KEY,
      },
    });

    fileDownload(response.data, `${filename}.pdf`);
  } catch (error) {
    console.log('Something went wrong when saving pdf', error);
  }
};
