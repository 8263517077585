/* eslint-disable react/display-name */
import React from 'react';
import { useField } from 'formik';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface MandatoryFieldProps {
  name: string;
  multiline?: boolean;
}

const MandatoryField = React.memo(
  ({ name, multiline = false }: MandatoryFieldProps): JSX.Element => {
    const [field, meta] = useField(name);
    const { t } = useTranslation('translation', { keyPrefix: 'admin' });

    return (
      <TextField
        label={t(name) + ' *'}
        {...field}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error}
        margin="normal"
        size="small"
        fullWidth
        multiline={multiline}
      />
    );
  }
);

export default MandatoryField;
