/* eslint-disable camelcase */
import { Button, Divider, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { Formik, FormikProps, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Organisation,
  useCreateOrganisationMutation,
  useUpdateOrganisationMutation,
  useRemoveOrganisationMutation,
  UpdateOrganisationInput,
  CreateOrganisationInput,
  CreateOrgAdminInput,
  UpdateOrgAdminInput,
} from '../../../graphql/generated';
import styled from 'styled-components';
import { COLORS } from '../../../styles/colors';
import * as Yup from 'yup';
import React from 'react';
import MandatoryField from './formComponents/MandatoryField';
import OptionalField from './formComponents/OptionalField';

interface OrganisationInfoFormProps {
  organisation: Organisation | null;
  setOrganisation: (organisation: Organisation | null) => void;
  refetchOrganisations: () => void;
}

const OrganisationInfoForm: React.FC<OrganisationInfoFormProps> = ({
  organisation,
  setOrganisation,
  refetchOrganisations,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'admin' });
  const [createOrganisation] = useCreateOrganisationMutation();
  const [updateOrganisation] = useUpdateOrganisationMutation();
  const [removeOrganisation] = useRemoveOrganisationMutation();

  interface Values {
    name: string;
    shortName: string;
    address: string;
    phone: string;
    email: string;
    websiteURL: string;
    frontpageTitle: string;
    frontpageSubtitle: string;
    frontpageDescription: string;
    logoFile: string;
    vatId: string;
    adminGivenName: string;
    adminEmail: string;
    adminFamilyName: string;
    adminPhoneNumber: string;
  }

  const validationSchema = Yup.object({
    name: Yup.string().required(t('required')),
    shortName: Yup.string().required(t('required')),
    address: Yup.string().required(t('required')),
    email: Yup.string().email(t('errorEmail')).required(t('required')),
    frontpageTitle: Yup.string().required(t('required')),
    frontpageSubtitle: Yup.string().required(t('required')),
    frontpageDescription: Yup.string().required(t('required')),
    adminGivenName: Yup.string().required(t('required')),
    adminEmail: Yup.string().email(t('errorEmail')).required(t('required')),
    adminFamilyName: Yup.string().required(t('required')),
    adminPhoneNumber: Yup.string().phone('FI', false, t('errorPhone')),
  });

  const onSubmit = async (values, { resetForm }) => {
    const organisationData = {
      address: values.address,
      email: values.email,
      frontpageTitle: values.frontpageTitle,
      frontpageDescription: values.frontpageDescription,
      frontpageSubtitle: values.frontpageSubtitle,
      logoFile: values.logoFile,
      name: values.name,
      phone: values.phone,
      shortName: values.shortName,
      websiteURL: values.websiteURL,
      vatId: values.vatId,
    };
    const adminData = {
      email: values.adminEmail,
      family_name: values.adminFamilyName,
      given_name: values.adminGivenName,
      phoneNumber: values.adminPhoneNumber,
    };

    try {
      if (organisation) {
        await updateOrganisation({
          variables: {
            id: organisation.id,
            updateOrg: organisationData as UpdateOrganisationInput,
            updateOrgAdmin: adminData as UpdateOrgAdminInput,
          },
        });
      } else {
        const response = await createOrganisation({
          variables: {
            input: organisationData as CreateOrganisationInput,
            adminInput: adminData as CreateOrgAdminInput,
          },
        });
        resetForm();
        alert(t('organisationCreated', { name: response.data?.createOrganisation.name }));
      }
    } catch (error) {
      console.log('error', error);
      alert(error);
    }
    setOrganisation(null);
    refetchOrganisations();
  };

  const onDelete = async (organisation: Organisation) => {
    if (confirm(t('confirmOrganisationRemove', { name: organisation.name }))) {
      try {
        await removeOrganisation({ variables: { id: organisation.id } });
        alert(t('organisationRemoved'));
        setOrganisation(null);
      } catch (error) {
        console.log('error', error);
        alert(error);
      }
      refetchOrganisations();
    }
  };

  return (
    <StyledForm>
      <Formik
        initialValues={{
          name: organisation?.name || '',
          shortName: organisation?.shortName || '',
          address: organisation?.address || '',
          phone: organisation?.phone || '',
          email: organisation?.email || '',
          websiteURL: organisation?.websiteURL || '',
          frontpageTitle: organisation?.frontpageTitle || '',
          frontpageSubtitle: organisation?.frontpageSubtitle || '',
          frontpageDescription: organisation?.frontpageDescription || '',
          logoFile: organisation?.logoFile || '',
          vatId: organisation?.vatId || '',
          adminGivenName: organisation?.adminUser?.given_name || '',
          adminEmail: organisation?.adminUser?.email || '',
          adminFamilyName: organisation?.adminUser?.family_name || '',
          adminPhoneNumber: organisation?.adminUser?.phoneNumber || '',
        }}
        onSubmit={onSubmit}
        enableReinitialize={true}
        validationSchema={validationSchema}>
        {(props: FormikProps<Values>) => (
          <Form>
            {organisation ? (
              <FormHeader>
                <HeaderRow>
                  <h1>{t('selectedOrganisation')}</h1>
                  <IconButton onClick={() => setOrganisation(null)}>
                    <Close />
                  </IconButton>
                </HeaderRow>
                <p>{organisation.name}</p>
                <Link to={`/${organisation.shortName}/admin/manage`}>
                  <Button type="button" variant="outlined" color="success">
                    {t('goToOrganisationAdminView')}
                  </Button>
                </Link>
              </FormHeader>
            ) : (
              <h1>{t('createNewOrganisation')}</h1>
            )}
            <FormSection>
              <legend>
                <SectionHeader>{t('orgInfo')}</SectionHeader>
              </legend>
              <FormRow>
                <MandatoryField name="name" />
                <Divider orientation="vertical" />
                <MandatoryField name="shortName" />
              </FormRow>
              <MandatoryField name="email" />
              <MandatoryField name="address" />
              <OptionalField name="phone" />
              <OptionalField name="vatId" />
              <OptionalField name="websiteURL" />
            </FormSection>
            <FormSection>
              <legend>
                <SectionHeader>{t('pageInfo')}</SectionHeader>
              </legend>
              <MandatoryField name="frontpageTitle" multiline />
              <MandatoryField name="frontpageSubtitle" multiline />
              <MandatoryField name="frontpageDescription" multiline />
              <OptionalField name="logoFile" />
            </FormSection>
            <FormSection>
              <legend>
                <SectionHeader>{t('adminFields')}</SectionHeader>
              </legend>
              <FormRow>
                <MandatoryField name="adminGivenName" />
                <Divider orientation="vertical" />
                <MandatoryField name="adminFamilyName" />
              </FormRow>
              <MandatoryField name="adminEmail" />
              <MandatoryField name="adminPhoneNumber" />
            </FormSection>
            <ButtonContainer>
              <Button type="submit" variant="outlined" disabled={props.isSubmitting}>
                {organisation ? t('updateOrganisation') : t('saveNewOrganisation')}
              </Button>
              {organisation && (
                <>
                  <Button type="reset" variant="outlined" onClick={() => setOrganisation(null)}>
                    {t('cancel')}
                  </Button>
                  <Button
                    type="button"
                    variant="outlined"
                    color="error"
                    onClick={() => onDelete(organisation)}>
                    {t('deleteOrganisation')}
                  </Button>
                </>
              )}
            </ButtonContainer>
          </Form>
        )}
      </Formik>
    </StyledForm>
  );
};

const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FormHeader = styled.header`
  margin-bottom: 30px;
`;

const FormSection = styled.fieldset`
  display: flex;
  flex-direction: column;
  padding: 15px;
  margin: 20px 0 20px 0;
  border: 1px solid ${COLORS.mediumGrey};
  border-radius: 4px;
`;

const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

const HeaderRow = styled(FormRow)`
  justify-content: space-between;
`;

const SectionHeader = styled.p`
  margin: 0 5px 0 5px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
`;

export default OrganisationInfoForm;
