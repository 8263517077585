import IconTextField from '@mui/material/TextField';
import { InputAdornment, MenuItem, StandardTextFieldProps } from '@mui/material';
import styled from 'styled-components';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import PinOutlinedIcon from '@mui/icons-material/PinOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';

import { COLORS } from '../../styles/colors';
import { FONTS } from '../../styles/fonts';
import { WEIGHTS } from '../../styles/weights';

const StyledTextField = styled(IconTextField)<{ error: boolean }>`
  width: 100%;
  & .MuiOutlinedInput-root {
    & fieldset: {
      borderColor: ${props => props.color} ? ${COLORS.red} : ${COLORS.green3};
    },
    &.Mui-focused fieldset {
      border-color: ${COLORS.brightGreen2};
    },
  },
}
`;

const StyledOption = styled.div<{ bold: boolean }>`
  color: ${COLORS.brightGreen};
  font-family: ${FONTS.Karla};
  font-weight: ${props => (props.bold ? WEIGHTS.extrabold : WEIGHTS.regular)};
  font-size: 15px;
`;

const StyledSantaOptionTimeInfo = styled.div`
  color: ${COLORS.brightGreen};
  font-family: ${FONTS.Karla};
  font-weight: ${WEIGHTS.regular};
  font-size: 11px;
`;

const StyledStandardLabel = styled.div<{ id: string }>`
  width: ${props => (props.id === 'wishEarliest' || props.id === 'wishLatest' ? '220px' : '140px')};
  margin-left: 5px;
  color: ${COLORS.brightGreen2};
  font-family: ${FONTS.Karla};
  font-weight: ${WEIGHTS.regular};
  font-size: 13px;
`;

export interface SelectedItem {
  value: string;
  disabled: boolean;
  bold: boolean;
  initValue?: string | null;
}

interface Props extends StandardTextFieldProps {
  id: string;
  error: boolean;
  selectedItems: SelectedItem[] | undefined;
  showTimeInfo?: boolean;
  timeInfo?: string[];
  timeData?: { itemName: string; freeTimes: number; mostSuitable: string }[];
  standardLabel?: string;
}

const SimpleTextField: React.FC<Props> = ({
  selectedItems,
  showTimeInfo,
  timeInfo,
  timeData,
  standardLabel,
  ...props
}) => {
  const iconStyle = {
    color: props.error ? COLORS.red : COLORS.brightGreen,
    fontSize: 20,
    marginBottom: 0.3,
    zIndex: 2,
  };
  const inputPropsStyle = {
    fontSize: 15,
    color: props.error ? COLORS.red : COLORS.brightGreen,
    fontFamily: FONTS.Karla,
    fontWeight: WEIGHTS.regular,
    justifyContent: 'top',
    borderBottom:
      props.disabled && props.variant === 'standard' ? `0.9px solid ${COLORS.green3}` : '',
  };
  const inputLabelPropsStyle = {
    fontSize: props.disabled
      ? 17
      : props.id === 'selectedSanta' || props.id === 'selectedTime'
      ? 16
      : 19,
    color: props.error
      ? COLORS.red
      : props.id === 'selectedSanta' || props.id === 'selectedTime'
      ? COLORS.green3
      : props.variant === 'standard'
      ? COLORS.brightGreen2
      : COLORS.brightGreen,
    backgroundColor: COLORS.white,
    fontFamily: FONTS.Karla,
    fontWeight: WEIGHTS.regular,
  };

  const iconHandler = (id: string) => {
    switch (id) {
      case 'name':
      case 'doorname':
      case 'driverName': {
        return <PersonOutlineOutlinedIcon sx={iconStyle} />;
      }
      case 'phone':
      case 'christmasEvePhone':
      case 'santaPhone':
      case 'driverPhone': {
        return <PhoneIphoneOutlinedIcon sx={iconStyle} />;
      }
      case 'email':
      case 'santaEmail':
      case 'driverEmail': {
        return <AlternateEmailOutlinedIcon sx={iconStyle} />;
      }
      case 'address':
      case 'city':
      case 'areaNameInternal':
      case 'areaNamePublic': {
        return <LocationOnOutlinedIcon sx={iconStyle} />;
      }
      case 'postcode':
      case 'doorCode':
      case 'age':
      case 'position':
      case 'year': {
        return <PinOutlinedIcon sx={iconStyle} />;
      }
      case 'wishEarliest':
      case 'wishLatest':
      case 'startTime':
      case 'endTime':
      case 'minStartTime':
      case 'maxStartTime':
      case 'interval':
      case 'duration': {
        return <AccessTimeOutlinedIcon sx={iconStyle} />;
      }
      case 'price': {
        return <SellOutlinedIcon sx={iconStyle} />;
      }
      default:
        return undefined;
    }
  };

  return (
    <StyledTextField
      InputProps={{
        ...(props.variant === 'standard' ? { disableUnderline: true } : {}),
        style: inputPropsStyle,
        startAdornment: props.id !== 'selectedSanta' &&
          props.id !== 'selectedTime' &&
          props.id !== 'message' && (
            <InputAdornment position="start">
              {iconHandler(props.id)}
              {props.variant === 'standard' && standardLabel && (
                <StyledStandardLabel id={props.id}>{standardLabel}:</StyledStandardLabel>
              )}
            </InputAdornment>
          ),
      }}
      InputLabelProps={{
        style: inputLabelPropsStyle,
      }}
      size="small"
      sx={{
        '& .MuiInputBase-input.Mui-disabled': {
          WebkitTextFillColor: COLORS.brightGreen,
        },
        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
          display: 'none',
        },
        '& input::-webkit-calendar-picker-indicator': {
          background: 'none',
        },
      }}
      {...props}>
      {selectedItems?.map(option => (
        <MenuItem
          key={option.value}
          value={option.initValue || option.value}
          disabled={option.disabled}>
          <div>
            <StyledOption bold={option.bold}>{option.value}</StyledOption>
            {props.id === 'selectedSanta' && showTimeInfo && timeInfo && timeData && (
              <div>
                <StyledSantaOptionTimeInfo>
                  {timeInfo[0]}
                  {timeData.find(item => item?.itemName === option.value)?.freeTimes}
                </StyledSantaOptionTimeInfo>
                <StyledSantaOptionTimeInfo>
                  {timeInfo[1]}
                  {timeData.find(item => item?.itemName === option.value)?.mostSuitable}
                </StyledSantaOptionTimeInfo>
              </div>
            )}
          </div>
        </MenuItem>
      ))}
    </StyledTextField>
  );
};

export default SimpleTextField;
