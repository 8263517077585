import styled from 'styled-components';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikErrors } from 'formik';
import Checkbox from '@mui/material/Checkbox';

import { COLORS } from '../../styles/colors';
import { FONTS } from '../../styles/fonts';
import SimpleTextField from '../common/SimpleTextField';
import { WEIGHTS } from '../../styles/weights';

const MAP_TOKEN = process.env.REACT_APP_MAP_TOKEN;

const StyledFieldContainer = styled.div<{ type: string }>`
  background: ${COLORS.white};
  box-shadow: 0px 2px 4px #0000001a;
  min-width: ${props =>
    props.type === 'children' || props.type === 'additionalDetails' ? '400px' : '300px'};
  min-height: ${props =>
    props.type === 'children' || props.type === 'additionalDetails' ? '500px' : '300px'};
`;

const StyledFormFieldHeader = styled.h1`
  font-family: ${FONTS.Lora};
  font-weight: ${WEIGHTS.medium};
  font-size: 18px;
  color: ${COLORS.brightGreen};
  letter-spacing: 0.51px;
  padding: 15px;
`;

const StyledFieldRow = styled.div<{ bottomMargin: boolean }>`
  display: flex;
  flex-wrap: wrap;
  row-gap: 25px;
  column-gap: 10px;
  margin: 15px;
  margin-bottom: ${props => (props.bottomMargin ? '30px' : '15px')};
`;

const StyledFieldColumn = styled.div`
  flex: 1;
  flex-wrap: wrap;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledMapContainer = styled.div`
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: center;
  padding: 15px;
`;

const StyledAddRemoveButton = styled.button`
  width: 100px;
  height: 20px;
  font-family: ${FONTS.Karla};
  font-weight: ${WEIGHTS.bold};
  color: ${COLORS.brightGreen};
  background: ${COLORS.white};
  border: ${COLORS.white};
  text-transform: uppercase;
  margin-left: 8px;
  margin-right: 8px;
`;

const CheckboxRow = styled.div`
  width: 100%;
  height: 15px;
  display: flex;
`;

const CheckboxText = styled.div`
  color: ${COLORS.brightGreen};
  font-family: ${FONTS.Karla};
  font-weight: ${WEIGHTS.regular};
  font-size: 12px;
`;

interface OnChangeErrors {
  name: string;
  phone: string;
  email: string;
  doorname: string;
  address: string;
  postcode: string;
  city: string;
  christmasEvePhone: string;
  children: {
    name: string;
    age: string;
    goodToKnow: string;
  }[];
}

interface fieldValues {
  label: string;
  formikValue: string | boolean;
  formOnChange: (
    field: string,
    value: string | boolean,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<OnChangeErrors>>;
}

interface InputErrors {
  name: string;
  phone: string;
  email: string;
  doorname: string;
  address: string;
  postcode: string;
  city: string;
  christmasEvePhone: string;
}

interface Props {
  cardValues?: fieldValues[][];
  headerText: string;
  timeWishes: string[];
  childAmount?: number;
  addChild?: () => void;
  removeChild?: () => void;
  errors: FormikErrors<InputErrors>;
  lonlat: string | undefined;
}

const FieldCard: React.FC<Props> = ({
  cardValues,
  headerText,
  timeWishes,
  childAmount,
  addChild,
  removeChild,
  errors,
  lonlat,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'form' });

  return (
    <StyledFieldContainer type={headerText}>
      <StyledFormFieldHeader>{t(headerText)}</StyledFormFieldHeader>
      {cardValues &&
        [...Array(childAmount)].map((e, i) => (
          <Fragment key={i}>
            {cardValues.map((rowValues, index) => (
              <StyledFieldRow key={index} bottomMargin={rowValues[0].label === 'goodToKnow'}>
                {rowValues.map(columnValue => (
                  <StyledFieldColumn key={columnValue.label}>
                    {columnValue.label === 'wishEarliest' || columnValue.label === 'wishLatest' ? (
                      <SimpleTextField
                        select
                        key={columnValue.label}
                        required
                        id={columnValue.label}
                        label={t(columnValue.label)}
                        onChange={event => {
                          columnValue.formOnChange(columnValue.label, event.target.value);
                        }}
                        value={columnValue.formikValue}
                        error={errors[columnValue.label] !== undefined}
                        SelectProps={{
                          MenuProps: {
                            PaperProps: {
                              sx: {
                                '& .Mui-selected': {
                                  backgroundColor: COLORS.lightGreen,
                                  '&:hover': {
                                    backgroundColor: COLORS.lightGreen,
                                  },
                                },
                              },
                            },
                          },
                        }}
                        selectedItems={
                          columnValue.label === 'wishEarliest'
                            ? timeWishes.slice(0, timeWishes.length - 1).map(time => ({
                                value: time,
                                disabled: false,
                                bold: false,
                              }))
                            : timeWishes.slice(1, timeWishes.length).map(time => ({
                                value: time,
                                disabled:
                                  time <=
                                  (rowValues.find(item => item.label === 'wishEarliest')
                                    ?.formikValue || false),
                                bold: false,
                              }))
                        }
                      />
                    ) : columnValue.label === 'meetOutside' ||
                      columnValue.label === 'annualMessages' ? (
                      <CheckboxRow>
                        <Checkbox
                          id={columnValue.label}
                          name={columnValue.label}
                          defaultChecked={
                            typeof columnValue.formikValue !== 'boolean'
                              ? false
                              : columnValue.formikValue
                          }
                          onChange={() =>
                            columnValue.formOnChange(columnValue.label, !columnValue.formikValue)
                          }
                          sx={{
                            color: COLORS.brightGreen,
                            '&.Mui-checked': {
                              color: COLORS.brightGreen,
                            },
                          }}
                        />
                        <CheckboxText>{t(columnValue.label)}</CheckboxText>
                      </CheckboxRow>
                    ) : (
                      <SimpleTextField
                        key={
                          !childAmount
                            ? columnValue.formikValue
                            : columnValue.formikValue[i][columnValue.label]
                        }
                        required={
                          !childAmount &&
                          columnValue.label !== 'giftInfo' &&
                          columnValue.label !== 'routeInfo' &&
                          columnValue.label !== 'otherInfo' &&
                          columnValue.label !== 'doorCode' &&
                          columnValue.label !== 'confirmedStartAt'
                        }
                        disabled={columnValue.label === 'confirmedStartAt'}
                        id={columnValue.label + i}
                        label={t(columnValue.label)}
                        multiline={
                          columnValue.label === 'goodToKnow' ||
                          columnValue.label === 'giftInfo' ||
                          columnValue.label === 'routeInfo' ||
                          columnValue.label === 'otherInfo'
                        }
                        rows={3}
                        onBlur={event => {
                          const key = `${!childAmount ? '' : `children[${i}].`}${
                            columnValue.label
                          }`;
                          columnValue.formOnChange(key, event.target.value);
                        }}
                        defaultValue={
                          !childAmount
                            ? columnValue.formikValue
                            : columnValue.formikValue[i][columnValue.label]
                        }
                        error={!childAmount ? errors[columnValue.label] !== undefined : false}
                        helperText={!childAmount && errors[columnValue.label]}
                        selectedItems={undefined}
                      />
                    )}
                  </StyledFieldColumn>
                ))}
              </StyledFieldRow>
            ))}
          </Fragment>
        ))}
      {childAmount && (
        <StyledButtonContainer>
          <StyledAddRemoveButton onClick={addChild}>{t('addChild')}</StyledAddRemoveButton>
          {childAmount > 2 && (
            <StyledAddRemoveButton onClick={removeChild}>{t('removeChild')}</StyledAddRemoveButton>
          )}
        </StyledButtonContainer>
      )}
      {headerText === 'mapInfo' && (
        <StyledMapContainer>
          <img
            src={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-l+306e57(${
              lonlat?.split(',')[0]
            },${lonlat?.split(',')[1]})/${lonlat?.split(',')[0]},${
              lonlat?.split(',')[1]
            },14/790x410?access_token=${MAP_TOKEN}`}
            alt="Destination on the map"
          />
        </StyledMapContainer>
      )}
    </StyledFieldContainer>
  );
};

export default FieldCard;
