import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { useMediaQuery } from 'react-responsive';

import { COLORS } from '../../styles/colors';
import { FONTS } from '../../styles/fonts';
import { FONTSIZE } from '../../styles/fontsize';
import { WEIGHTS } from '../../styles/weights';
import { OrganisationSeasonContext } from '../../App';
import DropDown from '../common/DropDown';
import { User } from '../utilities/RouteGuard';
import { verifyRoles } from '../../utils/verifyRoles';
import { client } from '../..';
import AuthService from '../../services/AuthService';
import { UserRole } from '../../graphql/generated';
import Logo from './Logo';

const StyledNavBar = styled.nav`
  position: sticky;
  top: 0;
  z-index: 3;
  height: 45px;
  background: ${COLORS.red};
  box-shadow: 0px 3px 6px #15393914;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${COLORS.lightYellow};
`;

const StyledNavLink = styled(NavLink)`
  height: 100%;
  width: 145px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${FONTS.Karla};
  font-weight: ${WEIGHTS.semibold};
  letter-spacing: 1.4px;
  font-size: ${FONTSIZE.content};
  color: ${COLORS.lightYellow};
  text-transform: uppercase;
  &:hover,
  &:focus,
  &:active {
    font-weight: ${WEIGHTS.extrabold};
    background: ${COLORS.brightRed};
    color: ${COLORS.lightYellow};
    opacity: 1;
  }
  @media (max-width: 768px) {
    justify-content: left;
    padding-left: 20px;
    height: 45px;
    width: 100%;
    background: ${COLORS.red};
    border-bottom: 1px solid ${COLORS.lightYellow};
  }
`;

const StyledNavButton = styled.button`
  width: 160px;
  height: 25px;
  font-family: ${FONTS.Karla};
  font-weight: ${WEIGHTS.semibold};
  color: ${COLORS.red};
  border: ${COLORS.red};
  font-size: 14px;
  letter-spacing: 0.51px;
  text-transform: uppercase;
  position: absolute;
  right: 100px;
  top: 10px;
  &:hover {
    font-weight: ${WEIGHTS.extrabold};
    background: ${COLORS.lightYellow} padding-box;
  }
`;

const StyledIconContainer = styled.div`
  position: absolute;
  right: 50px;
  top: 12px;
`;

const MobileLinksContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 45px;
`;

const StyledMenuIcon = styled(MenuOutlinedIcon)`
  color: ${COLORS.lightYellow2};
  display: flex;
`;

interface Props {
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  setSantaAction: Dispatch<SetStateAction<string>>;
  allowedRoles: UserRole[];
  formDirty?: boolean;
  setClicked?: Dispatch<SetStateAction<boolean>>;
}

const santaFunctions = [
  { text: 'PrintSchedule', key: 'printSchedule' },
  { text: 'PrintAll', key: 'printAll' },
];

const NavBar: React.FC<Props> = ({
  user,
  setUser,
  setSantaAction,
  allowedRoles,
  formDirty,
  setClicked,
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const { t } = useTranslation('translation', { keyPrefix: 'navbar' });
  const { openSeason } = useContext(OrganisationSeasonContext);
  const { orgName, santaID } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const [mobileLinksOpen, setMobileLinksOpen] = useState(false);

  const clearUserData = () => {
    setUser(null);
    sessionStorage.removeItem('token');
    client.resetStore();
    navigate(orgName ? `/${orgName}` : '/', { replace: true });
  };

  const openMobileLinks = () => {
    setMobileLinksOpen(prevState => !prevState);
  };

  const logoutHandler = () => {
    AuthService.logout(clearUserData);
  };

  return (
    <StyledNavBar>
      <Logo />
      {user &&
        orgName &&
        allowedRoles.length > 0 &&
        verifyRoles(user, [UserRole.ORG_ADMIN, UserRole.ORG_USER, UserRole.SUPERADMIN]) && (
          <>
            <StyledNavLink to={`/${orgName}/admin`}>{t('home')}</StyledNavLink>
            <StyledNavLink to={`/${orgName}/admin/map`}>{t('mapview')}</StyledNavLink>
          </>
        )}
      {user &&
        orgName &&
        allowedRoles.length > 0 &&
        verifyRoles(user, [UserRole.ORG_ADMIN, UserRole.SUPERADMIN]) && (
          <StyledNavLink to={`/${orgName}/admin/manage`}>{t('admin')}</StyledNavLink>
        )}

      {user && !orgName && allowedRoles.length > 0 && verifyRoles(user, [UserRole.SUPERADMIN]) && (
        <StyledNavLink to={`/admin`}>{t('admin')}</StyledNavLink>
      )}

      {user && allowedRoles.length > 0 && verifyRoles(user, [UserRole.SANTA]) && santaID && (
        <StyledIconContainer>
          <DropDown
            defaultIcon={<StyledMenuIcon />}
            optionArray={santaFunctions}
            onArrangeChange={(_text, key) => setSantaAction(key)}
            santa={true}
          />
        </StyledIconContainer>
      )}
      {allowedRoles.length === 0 && orgName && !isMobile && (
        <>
          <StyledNavLink
            to={formDirty ? {} : `/${orgName}`}
            onClick={() => setClicked !== undefined && formDirty && setClicked(true)}>
            {t('home')}
          </StyledNavLink>
          {openSeason && location.pathname !== `/${orgName}/order` && (
            <StyledNavLink
              to={`/${orgName}/order`}
              onClick={() => setClicked !== undefined && setClicked(false)}>
              {t('orderSanta')}
            </StyledNavLink>
          )}
        </>
      )}
      {allowedRoles.length === 0 && orgName && isMobile && (
        <>
          <StyledIconContainer>
            <StyledMenuIcon onClick={openMobileLinks} />
          </StyledIconContainer>
          {mobileLinksOpen && (
            <MobileLinksContainer>
              <StyledNavLink
                to={formDirty ? {} : `/${orgName}`}
                onClick={() => {
                  setClicked !== undefined && formDirty && setClicked(true);
                  setMobileLinksOpen(false);
                }}>
                {t('home')}
              </StyledNavLink>
              {openSeason && location.pathname !== `/${orgName}/order` && (
                <StyledNavLink
                  to={`/${orgName}/order`}
                  onClick={() => {
                    setClicked !== undefined && setClicked(false);
                    setMobileLinksOpen(false);
                  }}>
                  {t('orderSanta')}
                </StyledNavLink>
              )}
            </MobileLinksContainer>
          )}
        </>
      )}
      {user && <StyledNavButton onClick={logoutHandler}>{t('logout')}</StyledNavButton>}
    </StyledNavBar>
  );
};

export default NavBar;
