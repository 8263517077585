export enum COLORS {
  red = '#c93f4f',
  lightYellow = '#ffdfb9',
  brightRed = '#ea3a4f',
  lightGrey = '#f8f9f9',
  lightGrey2 = '#edf2f2',
  brightGreen = '#306E57',
  brightGreen2 = '#2A8B5A',
  white = '#FFFFFF',
  darkRed = '#982C38',
  lightRed = '#FFEDEF',
  green = '#859C9C',
  green1 = '#153939',
  green2 = '#345F5A',
  darkYellow = '#D67F19',
  mediumGrey = '#E2E7E7',
  grey = '#DCE2E2',
  lightGreen = '#EFFFF3 ',
  lightYellow2 = '#FFF2E2',
  redStatus = '#FAD2D7',
  darkRedStatus = '#C93F4F',
  greenStatus = '#C6F7D3',
  darkGreenStatus = '#306E57',
  green3 = '#5C7F7B',
  yellow = '#FFEBD0',
}
