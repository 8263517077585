import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Reaptcha from 'reaptcha';

import { OrganisationSeasonContext } from '../../../App';
import AlertSnackbar from '../../../components/alerts/AlertSnackbar';
import { StyledButton } from '../../../components/common/StyledButton';
import { EmailType, useCreateEmailMutation } from '../../../graphql/generated';
import SantaWithGift from '../../../images/santaWithGift.svg';
import {
  CommonButtonContainer as ButtonContainer,
  CommonContainer as Container,
  CommonImageContainer,
  CommonTextContainer,
} from './HomepageStyles';
import FeedBackInput from './feedback/FeedbackInput';
import FeedbackCheckbox from './feedback/FeedbackCheckbox';
import EmailInput from './feedback/EmailInput';
import { COLORS } from '../../../styles/colors';
import styled from 'styled-components';

const FourthBox: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'homepage' });
  const { organisation } = useContext(OrganisationSeasonContext);

  const [captcha, setCaptcha] = useState<Reaptcha | null>(null);
  const [createEmailMutation] = useCreateEmailMutation();

  const [verified, setVerified] = useState(false);
  const [wantsResponse, setWantsResponse] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertKey, setAlertKey] = useState('');
  const [email, setEmail] = useState('');
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [inputError, setInputError] = useState({
    emailError: '',
    messageError: '',
    captchaError: '',
  });

  const SITE_KEY = process.env.REACT_APP_SITE_KEY;

  // Remove snackbar alert
  useEffect(() => {
    const timer = setTimeout(() => {
      updateAlert('', false);
    }, 4000);
    return () => clearTimeout(timer);
  }, [showAlert]);

  const validateEmail: (email: string) => Promise<boolean> = async email => {
    const emailSchema = Yup.object({
      email: Yup.string().email(),
    });
    try {
      await emailSchema.validate({ email }, { strict: true });
      return true;
    } catch (error) {
      return false;
    }
  };

  const updateAlert = (newKey = '', show = true) => {
    setAlertKey(newKey);
    setShowAlert(show);
  };

  const clearAll = () => {
    setFeedbackMessage('');
    setEmail('');
    setInputError({ emailError: '', messageError: '', captchaError: '' });
    updateAlert();
    setWantsResponse(false);
    captcha ? captcha.reset() : null;
    setVerified(false);
  };

  const sendFeedback = async () => {
    try {
      await createEmailMutation({
        variables: {
          input: {
            type: EmailType.Feedback,
            recipient: organisation.email,
            organisationId: organisation.id,
            extraData: wantsResponse
              ? `${t('customerWantsResponse', { email })} <br/><br/>${feedbackMessage}`
              : feedbackMessage,
          },
        },
      });
    } catch (e) {
      updateAlert('messageErrorOccurred');
    }
    clearAll();
  };

  const checkInputFields: () => boolean = () => {
    if (!feedbackMessage) {
      setInputError({ ...inputError, messageError: t('emptyMessage') });
      return false;
    }
    if (!email && wantsResponse) {
      setInputError({ ...inputError, emailError: t('emptyAddress') });
      return false;
    }
    if (!verified) {
      setInputError({ ...inputError, captchaError: t('completeCaptcha') });
      return false;
    }
    return true;
  };

  return (
    <Container>
      {showAlert && (
        <AlertSnackbar
          alertType={alertKey ? 'error' : 'success'}
          alertText={alertKey ? alertKey : 'messageSent'}
          close={() => updateAlert('', false)}
        />
      )}
      <ImageContainer>
        <img src={SantaWithGift} height={'auto'} width={'87%'} />
      </ImageContainer>

      <TextContainer>
        <FeedBackInput
          orgName={organisation.name}
          input={feedbackMessage}
          onInputChange={(value: string) => {
            setFeedbackMessage(value);

            setInputError({
              ...inputError,
              messageError: value.match(/[%<>{};\\$*'"]/)
                ? `${t('forbidden')}: [%<>{};\\$*'"]`
                : '',
            });

            captcha?.state.rendered ? null : captcha?.renderExplicitly();
          }}
          error={inputError.messageError}
        />
        <FeedbackCheckbox
          feedbackWanted={wantsResponse}
          setFeedbackWanted={(value: boolean) => setWantsResponse(value)}
        />
        {wantsResponse && (
          <EmailInput
            input={email}
            setInput={async (value: string) => {
              setEmail(value);

              setInputError({
                ...inputError,
                emailError: (await validateEmail(value)) ? '' : t('giveValidEmail'),
              });
            }}
            error={inputError.emailError}
          />
        )}
        <>
          <Reaptcha
            ref={e => setCaptcha(e)}
            sitekey={SITE_KEY}
            onVerify={() => {
              setVerified(true);
              setInputError({ ...inputError, captchaError: '' });
            }}
            onError={() => updateAlert('captchaErrorOccurred')}
            size={'normal'}
            explicit
          />
          <p style={{ color: COLORS.red, fontSize: 11, marginLeft: 14 }}>
            {inputError.captchaError}
          </p>
          <ButtonContainer>
            <StyledButton
              frontPage={true}
              onClick={async () => (checkInputFields() ? await sendFeedback() : null)}>
              {t('sendFeedback')}
            </StyledButton>
          </ButtonContainer>
        </>
      </TextContainer>
    </Container>
  );
};

const TextContainer = styled(CommonTextContainer)`
  @media (min-width: 768px) {
    padding: 60px 60px 30px 0px;
  }
`;

const ImageContainer = styled(CommonImageContainer)`
  @media (min-width: 768px) {
    margin-top: -50px;
  }
`;

export default FourthBox;
