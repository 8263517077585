import styled from 'styled-components';

import { COLORS } from '../../../styles/colors';
import { FONTS } from '../../../styles/fonts';
import { WEIGHTS } from '../../../styles/weights';
import { FONTSIZE } from '../../../styles/fontsize';

export const CommonContainer = styled.div`
  display: flex;
  min-height: 360px;
  margin-top: 50px;
  margin-bottom: 60px;
  background-color: ${COLORS.white};
  color: ${COLORS.brightGreen};
  @media (max-width: 768px) {
    margin: 30px 0% 5px 0%;
    flex-direction: column;
    text-align: center;
  }
`;

export const CommonTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  @media (max-width: 768px) {
    padding: 20px 25px 20px 25px;
  }
`;

export const CommonBoxHeader = styled.h2`
  font-family: ${FONTS.Lora};
  font-weight: ${WEIGHTS.medium};
  font-size: ${FONTSIZE.heading};
`;

export const CommonBoxText = styled.p`
  font-family: ${FONTS.Karla};
  font-weight: ${WEIGHTS.regular};
  font-size: ${FONTSIZE.content};
  @media (max-width: 768px) {
    text-align: start;
  }
`;

export const CommonImageContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-around;
  z-index: 0;
  margin-top: -3%;
  @media (max-width: 768px) {
    align-items: center;
    margin: 0;
    img {
      width: 0%;
    }
  }
`;

export const CommonButtonContainer = styled.div`
  z-index: 2;
`;
