import { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import ChildCareOutlinedIcon from '@mui/icons-material/ChildCareOutlined';
import CardGiftcardOutlinedIcon from '@mui/icons-material/CardGiftcardOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { Order } from '../../graphql/generated';
import { COLORS } from '../../styles/colors';
import ExpandableItem from '../common/ExpandableItem';
import StyledCardText from '../common/StyledCardText';
import { WEIGHTS } from '../../styles/weights';
import { FONTS } from '../../styles/fonts';

const StyledDetailContainer = styled.div`
  width: 100%;
`;

const StyledDetailRow = styled.div<{ detail: string }>`
  display: flex;
  align-items: center;
  min-height: 40px;
  background: ${COLORS.white};
  border-top: ${props => (props.detail === details[0].header ? '' : `1px solid ${COLORS.grey}`)};
  border-right: 1px solid ${COLORS.grey};
  border-left: 1px solid ${COLORS.grey};
`;

const StyledIconContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
  margin: 15px;
`;

const StyledDetailHeader = styled.span`
  font-size: 13px;
  font-family: ${FONTS.Karla};
  font-weight: ${WEIGHTS.regular};
  color: ${COLORS.brightGreen};
  margin-left: 15px;
`;

const StyledDetailTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${COLORS.grey};
  margin-left: 38px;
  padding-bottom: 10px;
`;

interface Props {
  order: Order;
}

const iconStyle = { marginLeft: 2, color: COLORS.brightGreen, fontSize: 20 };

// Contains detail headers, icons, and params to search the data
const details = [
  {
    header: 'subscriber',
    icon: <PersonOutlineOutlinedIcon sx={iconStyle} />,
    dataKeys: ['customerName', 'phone', 'email'],
  },
  {
    header: 'destination',
    icon: <LocationOnOutlinedIcon sx={iconStyle} />,
    dataKeys: ['visitName', 'streetAddress', 'postalCode', 'city', 'doorCode'],
  },
  {
    header: 'children',
    icon: <ChildCareOutlinedIcon sx={iconStyle} />,
    dataKeys: ['name', 'age', 'description'],
  },
  {
    header: 'basics',
    icon: <CardGiftcardOutlinedIcon sx={iconStyle} />,
    dataKeys: ['giftInfo', 'meetOutside', 'routeInfo'],
  },
  {
    header: 'additional',
    icon: <InfoOutlinedIcon sx={iconStyle} />,
    dataKeys: ['otherInfo'],
  },
];

const OrderCardDetails: React.FC<Props> = ({ order }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'orderCardDetails' });

  const childrenDetailHandler = (dataKeys: string[]) =>
    order.orderChildren.map(child =>
      dataKeys.map((dataKey, index) => (
        <StyledCardText
          display={'flex'}
          key={index}
          font={FONTS.Karla}
          weight={WEIGHTS.regular}
          size={13}
          color={COLORS.brightGreen}
          marginBottom={dataKey === 'description' ? 13 : 0}
          multiline={true}>
          <div style={{ width: '45%' }}>{t(dataKey)}:&nbsp;</div>
          <div style={{ width: '50%' }}>{child[dataKey]}</div>
        </StyledCardText>
      ))
    );

  return (
    <StyledDetailContainer>
      {details.map((detail, index) => (
        <ExpandableItem
          key={index}
          render={(xprops: { open: boolean; setOpen: Dispatch<SetStateAction<boolean>> }) => (
            <>
              <StyledDetailRow
                key={index}
                detail={detail.header}
                onClick={() => xprops.setOpen(!xprops.open)}>
                {detail.icon}
                <StyledDetailHeader>{t(detail.header)}</StyledDetailHeader>
                <StyledIconContainer>
                  {xprops.open ? (
                    <ExpandLessOutlinedIcon sx={iconStyle} />
                  ) : (
                    <ExpandMoreOutlinedIcon sx={iconStyle} />
                  )}
                </StyledIconContainer>
              </StyledDetailRow>
              {xprops.open && (
                <StyledDetailTextContainer>
                  <>
                    {detail.header === 'children'
                      ? childrenDetailHandler(detail.dataKeys)
                      : detail.dataKeys.map((dataKey, index) => (
                          <StyledCardText
                            display={'flex'}
                            key={index}
                            font={FONTS.Karla}
                            weight={WEIGHTS.regular}
                            size={13}
                            color={COLORS.brightGreen}
                            multiline={true}>
                            <div style={{ width: '45%' }}>{t(dataKey)}:&nbsp;</div>
                            <div style={{ width: '50%' }}>
                              {typeof order[dataKey] === 'boolean'
                                ? t(order[dataKey].toString())
                                : order[dataKey]}
                            </div>
                          </StyledCardText>
                        ))}
                  </>
                </StyledDetailTextContainer>
              )}
            </>
          )}
        />
      ))}
    </StyledDetailContainer>
  );
};

export default OrderCardDetails;
